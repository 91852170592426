import React from 'react'
import parse from 'html-react-parser'

import { Container } from '../../components/Layout/styles'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Row, Section, Col } from './styles'

import Video from '../../components/Video'

function Asprova({ lang, handleLang }) {
  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <Section className='banner'>
        <img
          src='./assets/images/asprova/banner.webp'
          className='screen'
          data-aos='fade'
          data-aos-duration='1500'
          alt=''
        />
        <Container>
          <Row>
            <Col>
              <h1>Asprova</h1>
              <p>{parse(lang.asprova.text)}</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className='asprova'>
        <Container>
          <Row>
            <h2>{parse(lang.asprova.text2)}</h2>
            <h2>{parse(lang.asprova.text3)}</h2>
            <h2>{parse(lang.asprova.text4)}</h2>

            <h2 className='features'>{parse(lang.asprova.features.title)}</h2>
            <ul>
              <li>{lang.asprova.features.text}</li>
              <li>{lang.asprova.features.text2}</li>
              <li>{lang.asprova.features.text3}</li>
              <li>{lang.asprova.features.text4}</li>
              <li>{lang.asprova.features.text5}</li>
              <li>{lang.asprova.features.text6}</li>
              <li>{lang.asprova.features.text7}</li>
              <li>{lang.asprova.features.text8}</li>
              <li>{lang.asprova.features.text9}</li>
            </ul>
          </Row>
        </Container>
      </Section>

      <Video iframe='https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6740319161316073472?compact=1' />
      <Footer lang={lang} />
    </>
  )
}

export default Asprova
