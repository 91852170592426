export default {
  header: {
    menu: {
      li1: 'Services',
      li2: 'Produits',
      li3: 'Technologies',
      li4: 'La Apllos',
      li5: 'Partenaires',
      li6: 'Contact'
    },
    menuMobile: {
      services: 'Services',
      service1: 'Expérience Digitale',
      service2: 'Digital Supply Chain',
      service3: 'Données',
      products: 'Produits',
      product1: 'APO Health Check',
      product2: 'Delfos',
      technology: 'Technologies',
      technology1: 'SAP IBP',
      technology2: 'Salesforce',
      technology3: 'Mulesoft',
      technology4: 'Delmia Quintiq',
      apllos: 'La Apllos',
      about: 'Connaît',
      partners: 'Partenaires',
      careers: 'Carrières',
      talk: 'Nous contacter'
    }
  },

  footer: {
    services: {
      title: 'Services',
      service1: 'Expérience Digitale',
      service2: 'Digital Supply Chain',
      service3: 'Données',
    },
    products: {
      title: 'Produits',
      product1: 'APO Health Check',
      product2: 'Delfos'
    },
    technology: {
      title: 'Technologies'
    },
    apllos: {
      title: 'La Apllos',
      text1: 'Carrières',
      text2: 'Nous contacter'
    }
  },

  formContact: {
    nameInput: 'Nom',
    namePlaceholder: 'Son nom',
    phoneInput: 'Téléphone',
    expertiseInput: 'Spécialité',
    uploadCV: 'Upload de CV',
    removeCV: 'Suppression de fichier',
    messageInput: 'Message',
    messagePlaceholder: 'Son message',
    requiredField: 'Zone obligatoire',
    send: 'Envoyer',
    typeFile: 'Fichier dans .pdf',
    titleHome: 'Contact',
    title: 'Nous contacter',
    title2: 'Inscrivez-vous  dans notre<br /> Banque de Talents',
    fileAlert: 'Format de fichier non valable',
    fileSizeAlert: 'Taille maximale 500ko',
    success: 'Succès',
    error: 'Erreur',
    emailSent: 'Email envoyé',
    emailFail: 'Réessayez plus tard!'
  },

  home: {
    banner: {
      titleDesk: `Evolution digitale dans la Gestion de la Chaîne de Valeur`,
      titleMobile: `Evolution digitale dans la Gestion de la Chaîne de Valeur`,
      textDesk: `
                        Nous fournissons des solutions pour des sociétés intelligentes, <br />
                        en utilisant des technologies innovatrices afin de permettre de <br />
                        nouveaux modèles d’affaires résilientes et flexibles. 
                        `,
      textMobile: `Nous fournissons des<br /> solutions pour des<br /> sociétés intelligentes,<br /> 
                        en utilisant des technologies innovatrices afin de<br /> permettre de 
                        nouveaux modèles d’affaires résilientes et flexibles. `
    },

    dataBanner: {
      titleDesk: `Données`,
      titleMobile: `Données`,
      textDesk: `Apllos opère dans des activités de flux de<br />
                 données de bout en bout pour votre entreprise, <br />
                 de l’ingestion de données à la construction de modèles intelligents.`,
      textMobile: `Apllos opère dans<br />
                des activités de flux<br />
                de données de bout en<br />
                bout pour votre entreprise,<br />
                de l’ingestion de données <br />
                à la construction de modèles intelligents.`
    },

    services: {
      subtitle: 'Nos',
      title: 'Services',
      service1Title: 'Expérience Digitale',
      service1Text:
        'Nous créons l’expérience digitale afin d’optimiser les<br /> processus des affaires, de culture du travail et de relations<br /> avec les clients ',
      service2Title: 'Digital Supply Chain',
      service2Text: `
                Nous mettons en pratique toutes les solutions innovatrices<br /> pour la performance de la Chaîne de Valeur à partir d’une <br />approche stratégique, orientée vers les données et les personnes. 
            `,
      service3Title: 'Données',
      service3Text: `Apllos opère dans des activités de flux de<br />
                    données de bout en bout pour votre entreprise, <br />
                    de l’ingestion de données à la construction de modèles intelligents.`,
      button: 'Voir Plus'
    },

    apllos: {
      title: 'simple, adapté',
      text: `
                    Nous croyons aller au-delà de la transformation digitale, cherchant des solutions simples aux<br /> 
                    enjeux complexes des affaires, pour que les organisations opèrent dans la vitesse<br /> et l’échelle nécessaires. 
                `,
      button: 'Connaît'
    },

    products: {
      subtitle: 'Nos',
      title: 'Produits',
      product1Title: 'DASHBOARD MONITOR',
      product1Text: 'APO Health Check',
      product2Title: 'I.A. appliquée à la prévision de la demande',
      product2Text: 'Delfos'
    },

    technology: {
      subtitle: 'Nos',
      title: 'Technologies',
      text: ''
    },

    clients: {
      subtitle: 'Nos',
      title: 'Clients'
    },

    contactUs: {
      subtitle: 'Nous',
      title: 'Contacter',
      text: 'Av. Queiroz Filho, 1700 CJ. D-9 <br /> Vila Leopoldina | São Paulo-SP',
      text2: 'contato@apllos.com'
    }
  },

  digitalExperience: {
    banner: {
      title: 'Expérience Digitale',
      text: `
                    Nous créons l’expérience numérique afin d’optimiser les processus 
                    des affaires, de culture du travail et des relations avec les clients; 
                    en répondant aux changements du marché de l’ère digitale. 
                `,
      tag1: 'Design Thinking',
      tag2: 'Méthodologies Agiles',
      tag3: 'Métrique',
      tag4: 'Cases'
    },

    designThinking: {
      title: `
                    Nous utilisons le <strong>Design thinking</strong>, une démarche qui favorise l’innovation<br /> 
                    au moyen d’un processus structuré pour le développement de projets plus affirmés, accélérant<br /> 
                    l’adoption de la mentalité numérique dans l’entreprise.
                `,
      title2: 'L’approche comprend deux phases : Comprendre et Exploiter.'
    },

    steps: {
      step1: {
        title: '1° <span>comprendre</span>',
        imersion: 'Immersion',
        imersionText:
          'Conduire la recherche afin de<br /> développer une<br /> compréhension de l’utilisateur et<br /> de l’affaire',
        define: 'Définir',
        defineText: `
                            Analyser toute la recherche et<br /> identifier <strong>où sont les problèmes et<br /> les opportunités
                            pour les utilisateurs et les affaires</strong> 
                            `
      },

      step2: {
        title: '2° <span>exploiter</span>',
        ideation: 'Idéation',
        ideationText: `
                                Générer plusieurs<br /> <strong>idées</strong> créatives pour la<br /> solution du problème 
                                `,
        prototyping: 'Prototypage ',
        prototypingText: `
                    Construire un prototype<br /> <strong>fonctionnel</strong>, pour ses<br /> idées principales 
                `,
        tests: 'Tests',
        testsText: ` 
                    <strong>Tester avec les utilisateurs</strong><br /> 
                    afin d’obtenir leurs retours et<br /> s’améliorer 
                `
      }
    },

    about: {
      title: `
                    Nous possédons une équipe multidisciplinaire qui crée des<br /> solutions de bout à bout dans un écosystème de technologie. 
            `,
      text: `
                Dans chaque solution, nous définissons les métriques à succès, en analysant<br /> 
                les modèles de comportement des utilisateurs et les objectifs de 
                l’affaire,<br /> en dirigeant les décisions fondées sur des données et en stimulant la<br /> croissance durable. 
            `
    }
  },

  digitalSupplyChain: {
    banner: {
      title: 'Digital Supply Chain',
      text: 'Des sociétés résilientes pour un marché volatile et incertain ',
      tag1: 'Dassault Systemès',
      tag2: 'Asprova',
      tag3: 'OMP',
      tag4: 'DDMRP',
      tag5: 'SAP APO',
      tag6: 'SAP IBP',
      tag7: 'SAP S4/Hana',
      tag8: 'Sap Bussiness one',
      tag9: 'Salesforce'
    },

    section: {
      title1: `
                Afin d’augmenter la capacité de réaction de la chaîne, l’amélioration de la prévisibilité doit être<br /> 
                orchestrée avec la réponse rapide et flexible à court terme. 
            `,
      title2: `Le travail est réalisé à partir d’une équipe multidisciplinaire qui sait manier les<br /> différentes technologies et modèles d’affaire.`,
      title3: `La solution est pensée de façon systémique, mais surtout en<br /> respectant les domaines et l’expérience de la société.`,
      title4: `
                Ces interactions sont guidées par des méthodologies qui effectuent la promotion de la culture d’innovation<br /> 
                et l’engagement. Les technologies sont suggérées en se fondant sur un écosystème de plateformes<br /> et de startups.

            `,
      title5: `
                Cette culture permet la construction de solutions avec plus d’adhérence aux exigences<br /> 
                métier. De plus, le concept d’architecture cloud et open source permet<br /> 
                de créer des solutions personnalisées. 
            `,
      title6: `Les processus sont modelés pour apporter de la résilience,<br /> de la durabilité et de l’optimisation à la chaîne de valeur. `,
      title7: `
                Afin de garantir la capture de valeur, les solutions sont validées par des données et des preuves de concept<br /> 
                (POCs), en générant une plus grande échelle, de la flexibilité et la meilleure expérience pour les personnes.
            `
    }
  },

  data: {
    banner: {
      title: 'Données',
      text: `Apllos opère dans des activités de flux de données de <br />
            bout en bout pour votre entreprise, de l’ingestion de données <br />
            à la construction de modèles intelligents.`
    },
    section: {
      text1: `Nous comprenons votre entreprise pour construire un référentiel de données centralisé dans le cloud ou sur site
        qui permet l’ingestion de données semi-structurées, structurées et non structurées provenant de différentes sources en utilisant la 
        technologie batch (par lots) ou real time (en temps réel). Ce processus est mis en œuvre de manière sécurisée, fiable et automatisée. 
        La construction de ce référentiel permet d’analyser et de traiter les données en générant des couches pour leurs utilisation dans des 
        modèles de science des données et d’analyse de données.`,
      text2: `Avec nos scientifiques et analystes de données, il est possible de comprendre des comportements, en plus de la surveillance, 
        des insights, des prévisions et des prédictions pour soutenir vos décisions commerciales.   `,
      text3: `Nous travaillons avec différents outils Big Data, tels que l’écosystème hadoop et des solutions dans le cloud - AWS, GCP, 
        Databricks et Azure - avec services de stockage à faibles coûts et de fonctionnalités pour traiter de gros volumes de données.`
    }
  },

  apllos: {
    banner: {
      words: ['La Apllos', 'La Απλος', 'simple', 'adapté'],
      title: `
                Nous sommes une équipe multidisciplinaire composée de plus de 50 collaborateurs, <br />
                parmi des designers, des ingénieurs, des scientifiques des données et des développeurs. <br />
                Nous livrons des solutions innovatrices pour les sociétés de différents secteurs. 
            `
    },
    about: {
      professionals: 'Professionnels',
      clients: 'Clients',
      projects: 'Projets',
      text: `
                Nos projets ont toujours la participation active<br /> 
                d’une équipe expérimentée, dans une structure horizontale. <br />
                Ainsi, la cocréation avec les clients et les partenaires<br /> 
                fournit la transparence et la sécurité nécessaires pour chaque défi. 
            `
    },
    info: {
      organization: 'Organisation',
      orgTitle: `Structure<br /> horizontale`,
      orgText: `
                Alignement constant entre<br /> 
                toutes les personnes concernées,<br /> 
                avec une culture ouverte aux nouvelles<br /> 
                idées et solutions. 
            `,
      team: 'Equipe',
      teamTitle: 'Spécialistes<br /> engagés',
      teamText: `
                Des spécialistes engagés <br />
                sur la qualité de la solution <br />
                et avec les délais de livraison. 
            `,
      focus: 'Ciblé',
      focusTitle: 'Solutions<br /> innovantes',
      focusText: `
                Application des solutions les<br /> 
                plus avancées et appropriées pour <br />
                l’évolution de l’affaire. 
            `
    }
  },

  hire: {
    title: 'Nous<br /> Recrutions',
    text: `
            Enjeux et espaces pour différents profils<br /> 
            dans un environnement d’innovation et de technologie. 
        `,
    link1: 'Consultez les postes vacants dans notre',
    link2: 'ou abonnez-vous à notre',
    talents: 'Banque de talents'
  },

  apoHealthCheck: {
    banner: {
      title: 'APO <br /> Health Check',
      text: `
                Comme tous les systèmes, le système de<br /> 
                planification APS - SAP APO a besoin d’une<br /> 
                surveillance de routine pour éviter les<br /> 
                problèmes et une action en cas de panne. 
            `
    },
    section: {
      title: 'Début',
      text: `
                Le produit d’Apllos – APO Health Check vise à cartographier les activités 
                critiques<br /> qui ont un impact sur l’affaire et à alerter préalablement 
                les actions aux responsables.<br /> Les activités surveillées sont personnalisées et
                les alertes sont déclenchées par e-mail<br /> avec des messages qui aident à identifier l’action. 
            `,
      text2: `Ce que le produit d’Apllos surveille:`
    },
    monitor: {
      title: 'Moniteur',
      collapse1Title: 'CIF (Core interface)',
      collapse1Text: `<p>Surveille la quantité d’objets incohérents dans l’interface (SAP APO et SAP ECC) en plus 
                d’enregistrer le relevé de ces données. Les alertes sont déclenchées lorsque l’environnement a un nombre 
                critique d’objets présentant des problèmes.</p>`,
      collapse2Title: 'Stockage de Log',
      collapse2Text: `<p>Alerte lorsque l’utilisation réservée au stockage de Log est pleine.</p>`,
      collapse3Title: 'Live Cache',
      collapse3Text: `<p>Surveille la disponibilité de la mémoire « live cache » et en cas d’inactivité il alerte le responsable.</p>`,
      collapse4Title: 'Chaîne de processus',
      collapse4Text: `<p>Surveille lorsque les chaînes de processus ou « Process Chains » ont un statut d’annulation ou dépassent la durée prévue, avec des alertes envoyées par e-mail. </p>`,
      collapse5Title: 'Dumps',
      collapse5Text: `<p>Surveille les échecs des algorithmes développés dans le système (SAP APO). Les alertes de panne sont envoyées par e-mail pour correction.</p>`,
      collapse6Title: 'Utilisation de la mémoire',
      collapse6Text: `<p>s’affiche lorsqu’une exécution utilise trop de mémoire ou effectue un traitement excessif de l’environnement. 
                    Avec cela, les défauts des filtres en cours d’exécution et les défauts dans les performances d’un programme seront 
                    identifiés. Cela évitera la lenteur dans l’environnement. </p>`
    },
    form: {
      title: 'Parcourez notre programme APO Health Check',
      paragraph: "Remplissez le formulaire et recevez le lien d'accès",
      message: 'I want to receive the access link the APO Health Check'
    }
  },

  aiForecast: {
    banner: {
      title: `Delfos`,
      subtitle: 'I.A. appliquée à la prévision de la demande',
      text: `
                Delfos est une solution de prévision de la demande<br /> 
                de haut niveau fondée sur divers algorithmes de<br /> 
                régression et de modèles d’apprentissage<br /> automatique (machine learning). 
            `
    },
    delfos: {
      text: `
                Delfos est la solution pour ceux qui souhaitent utiliser des outils avancés<br /> 
                d’intelligence artificielle pour prévoir des séries chronologiques, sans<br /> 
                avoir besoin d’une expérience en apprentissage automatique ou en<br /> programmation. 
            `
    },

    resources: {
      title: 'Ressources',
      colTitle1: 'Ajustement automatique de la série chronologique',
      colText1: `
                Application automatique de méthodes statistiques pour ajuster et<br /> 
                transformer les composants de la série chronologique, tels que la tendance,<br /> 
                la saisonnalité, le bruit et l’autocorrélation. Ces méthodes impliquent<br /> 
                une amélioration du résultat des algorithmes. 
            `,
      colTitle2: 'Meilleur ajustement',
      colText2: `
                Plus de 20 algorithmes, régressifs et d’apprentissage automatique,<br /> exécutés simultanément. <br />
                Delfos s’occupe de déterminer quel modèle est approprié pour<br /> chaque niveau de prévision. 

            `,
      colTitle3: 'Prévisions hiérarchiques',
      colText3: `
                Exécutions à n’importe quel niveau de la hiérarchie temporelle et<br /> 
                structurelle. Par exemple, il est possible de faire une prévision par<br /> 
                région et ensuite par client, en utilisant des fréquences horaires <br />
                mensuelles et hebdomadaires. 

            `,
      colTitle4: 'Comment ça marche',
      colText4: `
                Delfos fonctionne comme un service géré, se servant de la base de<br /> 
                données historique. <br />
                Le système identifie la corrélation entre les variables et développe<br /> 
                des modèles de prévision personnalisés et appropriés à chaque scénario<br /> 
                rencontré. 
            `,
      colText5: `
                Apllos fournit toute l’infrastructure nécessaire pour les exécutions,<br /> 
                sans que la société ait à débourser d’autres coûts. 
            `,
      colText6: `
                Il suffit d’entrer vos données pour obtenir de meilleurs résultats<br /> 
                dans votre processus de prévision de la demande, en utilisant des <br />
                techniques avancées d’intelligence artificielle. 
            `
    }
  },

  contact: {
    title: 'Contact',
    text: 'Ce sera un honneur de construire quelque chose ensemble'
  },

  talents: {
    title: 'Banque de Talents',
    text: `
            Enjeux et espaces pour différents profils<br /> 
            dans un environnement d’innovation <br />
            et de technologie. 
        `,
    check: `Consultez les postes vacants dans notre`
  },

  sapIbp: {
    title: 'SAP Integrated<br /> Business Planning',
    text: `
            SAP IBP est une solution complète pour la planification intégrée<br /> de la chaîne de valeur. La plateforme a 
            été construite sur une<br /> base de données en cloud et mémoire via SAP HANA, qui permet le <br />
            traitement des données en temps réel. 
            `,
    text2: `Apllos se démarque grâce à une expérience complète dans la mise en œuvre de processus de planification et de systèmes APS, ainsi qu’à une équipe multidisciplinaire dédiée au développement d’interfaces sur plusieurs plateformes, à l’analyse de données et à la définition d’un processus pour co-créer une solution qui apporte de la valeur aux clients. `,
    text3: `SAP IBP est une solution complète pour la planification intégrée de la chaîne de valeur. Il combine les fonctionnalités de vente et d’exploitation, la demande, la planification de l’approvisionnement, la tour de contrôle de la chaîne et l’optimisation des stocks. La plateforme est intégrée via SAP HANA Cloud Integration avec les architectures technologiques les plus variées générant des données toujours mises à jour pour la planification.  
        `,
    text4: `Son interface a été développée pour fonctionner avec les plateformes Web, Fiori et Excel, ce qui facilite son utilisation et son itération entre les processus métier.  
        `,

    services1Title: 'Supply Chain Control Tower',
    services1Text: `
            Il s’agit d’une plateforme de visibilité de la chaîne d’approvisionnement en temps réel pour soutenir des décisions et des actions correctives rapides. <br />
            <strong>Principaux avantages: </strong>
        `,
    services1List: {
      text1: `Visibilité de bout en bout sur toute la chaîne d’approvisionnement, y compris des alertes, de l’aide à la décision et des actions correctives rapides; 
            `,
      text2: `Diminution des niveaux de stocks mondiaux, réduction des risques; `,
      text3: `Meilleure performance concernant le délai de livraison pour le client;  `,
      text4: `Collaboration, communication et suivi de la chaîne d’approvisionnement;  `,
      text5: `Agilité d’approvisionnement accrue et réduction des coûts de la chaîne d’approvisionnement.`
    },

    services2Title: 'IBP For Sales and Operations',
    services2Text: `IBP S&OP est une plateforme collaborative de planification des demandes et de calcul des prévisions statistiques utilisant des modèles mathématiques simples. L’outil utilise des données flexibles et unifiées de la chaîne d’approvisionnement pour soutenir la planification tactique et stratégique à tous les niveaux de granularité et de dimension. 
        `,
    services2Text2: `Dans le cas d’une planification des demandes plus avancée, l’utilisation d’IBP Demand est recommandée.<br />
            <strong>Principales fonctionnalités</strong>
        `,
    services2List: {
      text1: `Planification collaborative des demandes et prévisions statistiques simples 
            `,
      text2: `Planification infinie à plusieurs niveaux 
            `,
      text3: `Réglage manuel de la planification des opérations et de ses variables pour des analyses et des simulations 
            `,
      text4: `Utilisation heuristique prenant en compte: Lead time de transport, stock actuel, stock de sécurité, stock cible, lot minimum, valeur d’arrondi, entre autres paramètres 
            `,
      text5: `Les deux processus fonctionnent avec des informations intégrées d’ERP et permettent l’analyse et la simulation du plan complet et delta;`,
      text6: `Pour prendre en compte la capacité finie des ressources, les restrictions de stock et d’autres modèles plus avancés, l’utilisation d’IBP Responde and Supply est recommandée`
    },

    services3Title: 'IBP For Demand',
    services3Text: `Planification de la demande statistique à court, moyen et long terme dans une solution unique. La plateforme utilise le traitement des données, la classification des comportements, l’agrégation et la désagrégation, en plus de modèles mathématiques avancés tels que l’intelligence artificielle et la régression pour la prévision de la demande.
        `,
    services3Text2: `La plateforme prend en charge la génération d’une demande consensuelle grâce à une planification collaborative et fournit des informations pertinentes pour l’analyse du plan. 
        <br />
            <strong>Principales fonctionnalités</strong>
        `,
    services3List: {
      text1: `Demand-sensing et prévision à moyen/long terme dans une solution unique de planification des demandes;`,
      text2: `Processus de planification automatisé et basé sur des exceptions, ainsi que des fonctionnalités de planification manuelle;`,
      text3: `Analyse intégrée et dynamique de la demande;`,
      text4: `Effacement de l’historique et correction des outliers;`,
      text5: `Prévisions statistiques avec des modèles avancés disponibles;`,
      text6: `Agrégation/désagrégation des données Time Series;`,
      text7: `Planification collaborative des demandes;`,
      text8: `Génération d’alertes;`,
      text9: `Utilisation de Dashboards pour soutenir la planification.`
    },

    services4Title: 'IBP For Inventory',
    services4Text: `SAP IBP inventory dispose d’un algorithme d’optimisation sophistiqué pour définir la meilleure politique d’inventaire en tenant compte de la chaîne d’approvisionnement en plusieurs étapes, du coût, des variabilités et du niveau de service.`,
    services4Text2: `Certains paramètres utilisés dans l’algorithme sont:`,
    services4List: {
      text1: `Variabilité de la demande`,
      text2: `Erreur de prévision`,
      text3: `Détournement d’approvisionnements`,
      text4: `Coût;`,
      text5: `Leadtimes`,
      text6: `Maillons de la chaîne d’approvisionnement`,
      text7: `Positionnement des inventaires tout au long de la chaîne`
    },

    services5Title: 'IBP For Resp N Supply',
    services5Text: `SOP Response and Supply planning est la solution pour la planification des approvisionnements et l’allocation de la chaîne d’approvisionnement. L’outil dispose de différents modèles heuristiques et d’optimisation pour la création de scénarios et de commandes pour la planification de la production, de la distribution et du réapprovisionnement. 
        `,
    services5Text2: `Principales fonctionnalités:`,
    services5List: {
      text1: `Optimisation avec fonction objectif pour maximiser le profit ou le niveau de service`,
      text2: `Heuristique`,
      text3: `Planification finie à plusieurs niveaux`,
      text4: `Priorités gérées par les coûts`,
      text5: `Stock de sécurité et stock maximum`,
      text6: `Coûts de retard de livraison, violation de stock de sécurité et stock par unité, coûts de production, transport, achats à l’unité ou fixes, coût de production, entre autres`,
      text7: `Génération de commandes d’approvisionnement (production, achats, distribution) et intégration avec un nouveau modèle d’intégration proche du temps réel avec ERP `
    }
  },

  salesforce: {
    title: 'Salesforce',
    text: `
        CRM Salesforce est une plate-forme qui dispose d’un logiciel<br /> 
        leader dans la gestion et la relation client, étant le<br /> 
        plus vendu au monde.
        `,
    text2: `Cet outil gère les problèmes fondamentaux de l’entreprise, tels que l’entonnoir de conversion, les leads et le suivi des tâches.`,
    text3: `L’ensemble de l’infrastructure de Salesforce est situé dans un cloud, ce qui permet au vendeur d’accéder aux informations de son client à partir de n’importe quel dispositif connecté à Internet.`,
    text4: `Salesforce a été conçu afin de faciliter le développement en apportant rapidité et faibles coûts à la mise en œuvre. Ses licences permettent une utilisation par de petites, moyennes et grandes entreprises.`,
    text5: `Salesforce dispose de plusieurs solutions pour votre entreprise; les principales sont: Sales Cloud, Service Cloud, marketing cloud, entre autres.`,
    services1Title: 'Sales Cloud',
    services1Text: `C’est une solution complète avec plusieurs fonctionnalités pour rendre le travail des équipes marketing et commerciales plus compétitif.`,
    services1Text2: `Avantages
        `,
    services1List: {
      text1: `Possibilité d’avoir une vue à 360° du client, en suscitant sa confiance, garantissant les ventes et améliorant les relations après-vente;`,
      text2: `Améliorer l’engagement des vendeurs et des partenaires, en créant des expériences qui améliorent le processus de vente avec des informations claires et pertinentes;`,
      text3: `Il est possible de suivre en temps réel les moments de décision d’achat d’un éventuel client au sein de l’organisation;`,
      text4: `Faisabilité d’automatiser des actions et des processus;`,
      text5: `Cet outil vous permet de contrôler les informations sur les concurrents ainsi que des contrats et des négociations.`
    },

    services2Title: 'Service Cloud',
    services2Text: `C’est une solution conçue pour répondre aux demandes de vos clients concernant votre produit ou service. Cet outil permet à votre entreprise de fidéliser ses clients, générant de la valeur pour votre marque.`,
    services2Text2: `Avantages
        `,
    services2List: {
      text1: `Augmenter la satisfaction des clients;`,
      text2: `Aider les entreprises à optimiser leurs processus et leurs appels, à affiner la résolution de vos problèmes et à améliorer considérablement la satisfaction des clients;`,
      text3: `Gestion des cas, accès client via les canaux, intégration avec des systèmes hérités, demande de support, base de connaissances, routage et échelonnement, et gestion des queues d’attente de service;`,
      text4: `Il vous permet de parler aux clients via le canal de votre choix, soit par téléphone, SMS (service de messagerie), WhatsApp ou Facebook Messenger;`,
      text5: `Il augmente la réputation de l’entreprise et multiplie les opportunités de vente;`,
      text6: `Il vous permet de fixer des objectifs et de suivre les résultats.`
    },

    services3Title: 'Marketing Cloud',
    services3Text: `C’est une plateforme pour créer des parcours pertinents et personnalisés pour votre public cible sur les bons canaux et appareils.`,
    services3Text2: `Avantages`,
    services3List: {
      text1: `Il est possible de connecter des données provenant de différentes sources et appareils à cette plateforme, afin d’obtenir une vue unique du client. Vous pouvez également capturer et activer des données provenant de parties principales, secondaires et tierces;`,
      text2: `Il fournit des données pour l’outil d’intelligence artificielle de Salesforce - Einstein. Les analyses effectuées permettent des interactions organisées et personnalisées avec le client;`,
      text3: `Il génère une attention et une prise de conscience tout au long du processus (un engagement bidirectionnel en temps réel), ce qui fournit également des informations pour offrir à chaque client la meilleure action pour lui.`
    }
  },

  mulesoft: {
    title: 'MuleSoft',
    text: `
        Mule est un moteur d’exécution léger et évolutif pour des API,<br /> des intégrations 
        et des microservices. Il est utilisé avec une<br /> architecture de plate-forme 
        hybride - locale, dans le cloud<br /> ou les deux.
        `,
    text2: `En tant que leader mondial des intégrations et des solutions de gestion 
        d’API, MuleSoft vise, avec Anypoint Platform, à connecter vos applications et à 
        résoudre les problèmes de connectivité les plus complexes en SOA, SaaS et APIs.<br /> 
        Mulesoft est une plate-forme simple pour créer des APIs et gérer les intégrations, qu’il 
        s’agisse d’ERP, de MES et d’applications backend.`,
    text3: `Ensemble, Salesforce et MuleSoft accélèrent votre transformation numérique, 
        vous permettant d’ouvrir les données sur des systèmes hérités, des applications cloud 
        et des appareils pour une prise de décision plus rapide et plus intelligente, ainsi que 
        pour créer des expériences hautement différenciées et connectées pour les consommateurs.
        `,
    text4: `Anypoint Platform accélère la vitesse de développement en réutilisant des APIs, 
        des connecteurs, des modèles, des exemples et d’autres actifs d’intégration déjà créés et 
        publiés sur Exchange de Mulesoft.
        `
  },

  delmiaQuintiq: {
    title: 'Delmia Quintiq',
    text: `Le logiciel de planification de la chaîne d’approvisionnement de<br /> DELMIA
						Quintiq planifie et optimise votre chaîne d'approvisionnement<br /> de bout
						en bout, vous offrant le niveau de contrôle et les informations<br /> nécessaires 
						pour transformer la planification de la chaîne<br /> d'approvisionnement d'un 
						centre de coûts en un<br /> générateur de revenus.`,
    text2: `Définissez clairement les KPI à l'échelle de l'organisation et alignez<br /> 
						chaque fonction commerciale sur ces objectifs. Optimisez chaque<br /> étape 
						de votre chaîne d'approvisionnement - des plans stratégiques à<br /> long terme 
						aux détails critiques au point d'exécution - pour atteindre<br /> des niveaux 
						élevés de service, de qualité et d'efficacité cohérents et durables.`,
    text3: `DELMIA Quintiq est le logiciel de planification et d'optimisation de la chaîne d'approvisionnement (SCP&O) qui offre la puissance de l'optimisation.`,
    text4: `<strong>Sales and operations planning</strong> – Planification intégrée des ventes et des opérations. Des ressources avancées de modélisation et d'optimisation pour simuler un certain nombre de scénarios hypothétiques, pour ensuite recommander le meilleur pour répondre aux indicateurs de performance clés de l'entreprise et soutenir une prise de décision efficace afin d’offrir le maximum en termes de valeur.`,
    text5: `<strong>Master planning</strong> – Planification principale. Pilote l'aide à la décision mondiale avec une visibilité en temps réel et des analyses prédictives pour la planification principale et la programmation et la planification des besoins en matériaux.`,
    text6: `<strong>Planification détaillée de la production</strong> – Réduisez les délais et les stocks en optimisant la production au sein et entre les lignes de production, les cellules de travail et les opérations d'assemblage, tout en améliorant l'utilisation des actifs et en prenant en charge les dates d'expiration du client.`,
    text7: `<strong>Planification et optimisation des opérations logistiques</strong> – Les gestionnaires, les planificateurs et les transitaires bénéficient d'une visibilité et d'un contrôle complets sur l'ensemble du processus de planification et d'optimisation – de la planification stratégique à long terme du réseau à la planification quotidienne de la main-d'œuvre et à la planification des itinéraires.`,
    text8: `<strong>Planejamento e otimização das operações de trânsito</strong> – uma solução para lidar com todos os desafios - desde a programação da tripulação 
					e da frota até a conquista de novas licitações por meio de licitações abertas. A solução funciona para operações grandes e pequenas e se 
					integra perfeitamente a uma ampla gama de sistemas de terceiros.`,
    text9: `<strong>Planification et optimisation des opérations de la main-d'œuvre</strong> – Construit sur un modèle commercial 100% adapté qui inclut toutes les contraintes uniques de votre organisation, telles que les compétences et les qualifications des employés, les exigences contractuelles spécifiques, les réglementations du travail et la disponibilité des ressources. Vous fixez vos objectifs d'efficacité, de satisfaction et de rentabilité; nous donnons à vos planificateurs les outils et les informations dont ils ont besoin pour améliorer les résultats importants.`,
    text10: `Le logiciel DELMIA Quintiq comprend une application Web développée avec une nouvelle interface moderne qui se concentre sur l’expérience de l’utilisateur. Cela donne aux utilisateurs la liberté et la flexibilité d'accéder immédiatement à DELMIA Quintiq à partir de n’importe quel navigateur Web.`,
    text11: `https://www.3ds.com/products-services/delmia/products/delmia-quintiq/`
  },

  partners: {
    main: {
      title: `Nos Partenaires`,
      text: `Nous travaillons avec un écosystème de partenaires<br /> dans le but de chercher des solutions innovatrices<br /> pour votre affaire.`
    },
    dassault: {
      text: `Dassault Systèmes, une société 3DEXPERIENCE, fournit aux sociétés et aux personnes des univers virtuels pour la création d’innovations durables et qui contribuent à l’amélioration de procédures et des pratiques d’affaires. Ses solutions connues mondialement transforment la façon comment les produits sont développés, produits et gérés. Les solutions collaboratives de Dassault Systèmes stimulent l’innovation sociale, élargissant les possibilités d’amélioration du monde réel par le biais du monde virtuel. La société apporte de la valeur à plus de 210.000 clients de toutes tailles et industries, dans plus de 140 pays.`
    },
    sap: {
      text: `SAP est l’un des leaders mondiaux dans le développement de logiciels pour la gestion de procédures d’affaires, comme ECC, S4 Hana, IBP, SAC, en créant des solutions qui facilitent le traitement effectif des données et le flux d’informations entre les organisations.<br /><br /> 
			Aujourd’hui, SAP compte plus de 230 millions d’utilisateurs en nuage, plus de 100 solutions qui comprennent toutes les fonctions d’affaires et le plus grand portefeuille de nuage de n’importe quel serveur.
			`
    },
    poweron: {
      text: `PowerOn permet que votre PowerBI réalise l’opération d’actualisation et écriture en intégrant ses tableaux de bord avec plus de 200 bases de données. Cela permettra la création de plans collaboratifs, des modèles dynamiques avec des modifications de paramètres et une meilleure utilisation pour votre plateforme.`
    },
    aimms: {
      text: `AIMMS fournit une plateforme de modelage et d’optimisation utilisée dans plusieurs fonctions pour pratiquement tout type d’affaires. Il s’agit d’une excellente option pour les équipes prêtes à s’éloigner des feuilles de calcul, ou des outils anciens de conception du réseau, de la prévision de la demande et des outils S&OP / IBP. L’AIMMS SC Navigator fournit des applications prêtes à l'emploi en nuage pour obtenir des avantages d'analyses sophistiquées sans avoir besoin d'une expérience en analyse, y compris des solveurs de classe mondiale (et des liens de solveur) pour la programmation linéaire, mixte d'entiers et non linéaires tels que baron, cplex, conopt, gurobi, knitro, path, snopt et xa. De plus, des concepts tels que la programmation stochastique et l'optimisation robuste sont disponibles pour inclure l'incertitude des données dans les modèles. 
      `
    },
    asprova: {
      text: `Asprova est un Système d’APS (Advanced Planning & Scheduling) pour la programmation de production à grande vitesse pour plusieurs articles et processus avec l'intégration des ventes, de la fabrication, de l'inventaire et de l'achat. Asprova a été développé à l'origine au Japon, où il est l'outil de planification de production le plus utilisé, avec une part de marché de 52,4% (selon le rapport Techno Systems Research Co., Ltd 2011).`
    }
  },

  asprova: {
    text: `Asprova fonctionne comme un tableur Excel facile, même avec<br /> de grandes quantités de données, et une maintenance simple.`,
    text2: `Asprova a été développé à l’origine au Japon, où il est l’outil de programmation de production le plus utilisé, avec une part de marché de 52,4% (selon le rapport Techno Systems Research Co., Ltd 2011).`,
    text3: `Asprova est un système APS (Advanced Planning & Scheduling) pour la programmation de la production à grande vitesse pour plusieurs articles et processus avec l’intégration des ventes, de la manufacture, des stocks et des achats. L’outil possède de multiples fonctionnalités pour la programmation de production, facile à personnaliser et sans la complexité de nouveaux encodages.`,
    text4: `L’application fonctionne avec deux types de visualisation des données: un tableur facile à modifier et une vue graphique. Il est possible de visualiser les ressources, les ordres de production, les stocks, les achats et les ventes. La programmation est effectuée dans différentes agrégations temporelles telles que le jour, la semaine et le mois. De plus, elle peut être facilement personnalisée par l’utilisateur. L’application s’intègre à différents ERPs, tels que SAP et Oracle.`,
    features: {
      title: `<strong>Quelques fonctionnalités</strong>`,
      text: `Ventes: reliez les informations de vente à la planification de la production de l’usine.`,
      text2: `Achats: reliez la planification de la production aux informations d’achat.`,
      text3: `KPI: évaluez plusieurs KPI sur chaque projet.`,
      text4: `Restriction de temps MAX: définissez préalablement le temps entre les processus.`,
      text5: `Verrouillage des ressources: verrouillez temporairement une ressource après la production.`,
      text6: `Evénement: définissez des conditions et générez des événements.`,
      text7: `Optimisation: faites le séquencement des travaux pour toutes les ressources.`,
      text8: `Scénarios: support pour la création de plusieurs scénarios.`,
      text9: `Restrictions de ressources: choisissez instantanément les surcharges de ligne et les livraisons tardives à l’aide de tableaux et de graphiques.`
    }
  }
}
