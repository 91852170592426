import styled from 'styled-components'

import { Row as RowColumn } from '../../components/Layout/styles'

export const Row = styled(RowColumn)``

export const Section = styled.section`
  display: flex;
  margin: 40px 0 0;
  &.banner {
    margin: 0 auto;
    height: 737px;
    align-items: center;
    background: url('./assets/images/sapIbp/banner.jpg') no-repeat top center;
    background-size: cover;
    overflow-x: hidden;
    h1 {
      color: var(--colorPrimary);
      font-size: 42px;
      font-weight: 500;
      margin-bottom: 55px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: -26px;
        left: 0;
        width: 990px;
        border-bottom: 2px solid var(--colorPrimary);
        z-index: 1;
      }
    }
    p {
      line-height: 1.6;
    }

    /* .screen {
            position: absolute;
            right: 0;
            top: 25%;
            width: 50%;
            z-index: 2;
        } */

    .screen {
      position: absolute;
      right: 0;
      top: 25%;
      width: 50%;
      z-index: 2;
    }

    @media screen and (min-width: 1556px) {
      .screen {
        width: auto !important;
        top: 17%;
      }
    }
  }
  &.ibp {
    h2 {
      color: var(--textPags);
      font-size: 17px;
      font-weight: normal;
      margin-bottom: 25px;
    }
    .services {
      .type {
        border: 2px solid var(--colorPrimary);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        margin-bottom: 15px;
        h2 {
          color: var(--colorPrimary);
          margin: 0;
          text-transform: uppercase;
          text-align: center;
          font-weight: 500;
          font-size: 15px;
          span {
            width: 42px;
            height: 30px;
            display: inline-block;
            vertical-align: middle;
            background-size: contain !important;
            margin-right: 20px;
            &.supply {
              background: url('./assets/images/sapIbp/supplyWhite.png')
                no-repeat center;
            }
            &.ibpSales {
              background: url('./assets/images/sapIbp/ibpSales.png') no-repeat
                center;
            }
            &.ibpDemand {
              background: url('./assets/images/sapIbp/ibpDemand.png') no-repeat
                center;
            }
            &.ibpResponse {
              background: url('./assets/images/sapIbp/ibpResponse.png')
                no-repeat center;
            }
            &.ibpInventory {
              background: url('./assets/images/sapIbp/ibpInventory.png')
                no-repeat center;
            }
          }
        }
        &:first-child {
          background: var(--colorPrimary);
          h2 {
            color: #fff;
          }
        }
        &:last-child {
          display: grid;
          border: none;
          grid-template-columns: 1fr 1fr 1fr;
          padding: 0;
          margin-bottom: 40px;
          /* column-gap: 20px; */
          div {
            margin-right: 20px;
            border: 2px solid var(--colorPrimary);
            padding: 3px;
            &:last-child {
              margin: 0;
            }
          }
          h2 {
          }
        }
      }
    }
    .collapseDivs {
      h1 {
        font-size: 20px;
        font-weight: 600;
        span {
          width: 48px;
          height: 41px;
          display: inline-block;
          vertical-align: middle;
          background-size: contain !important;
          margin-right: 20px;
          &.supply {
            background: url('./assets/images/sapIbp/supplyPurple.png') no-repeat
              center;
          }
          &.ibpSales {
            background: url('./assets/images/sapIbp/ibpSales.png') no-repeat
              center;
          }
          &.ibpDemand {
            background: url('./assets/images/sapIbp/ibpDemand.png') no-repeat
              center;
          }
          &.ibpResponse {
            background: url('./assets/images/sapIbp/ibpResponse.png') no-repeat
              center;
          }
          &.ibpInventory {
            background: url('./assets/images/sapIbp/ibpInventory.png') no-repeat
              center;
          }
        }
      }

      strong {
        font-size: 16px;
        color: var(--textPags);
        line-height: 1.6;
        margin: 21px 0;
        display: block;
      }

      p {
        margin-bottom: 20px;
        display: block;
        strong {
          margin: 18px 0 21px;
        }
      }

      ul {
        li {
          font-size: 16px;
          color: var(--textPags);
          /* line-height: 2.8; */
          margin-bottom: 26px;
        }
      }

      .app__toggle {
        padding: 20px 20px 0 0;
      }
    }
  }
  p {
    font-size: 16px;
    color: var(--textPags);
    line-height: 1.6;
  }

  @media screen and (max-width: 1024px) {
    &.banner {
      .screen {
        width: 596px;
      }

      p {
        br {
          display: block;
        }
      }
    }

    p {
      br {
        display: none;
      }
    }

    .col-monitor {
      h2 {
        padding-top: 35px;
      }
    }
  }

  @media screen and (max-width: 990px) {
    &.banner {
      .screen {
        width: 555px;
      }
    }
    &.ibp {
      .type {
        padding: 8px !important;
        position: relative;
        h2 {
          span {
            /* position: absolute;
                        left: 25%;
                        top: 7%; */
          }
        }
        &:last-child {
          display: block !important;
          padding: 0 !important;
          div {
            margin-right: 0 !important;
            margin-bottom: 15px;
            position: relative;
            padding: 8px !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &.banner {
      flex-direction: column;
      height: auto;
      padding: 131px 0px 67px;
      align-items: flex-end;
      .screen {
        position: relative;
        width: 96%;
        margin-bottom: 40px;
      }
      h1 {
        font-size: 30px;
        &:before {
          width: 100%;
        }
      }
    }
    h2 {
      font-size: 20px;
    }

    p {
      font-size: 15px;
      br {
        display: none !important;
      }
    }
  }

  @media screen and (max-width: 576px) {
    &.banner {
      h1 {
        font-size: 25px;
      }
    }
    &.ibp {
      h2 {
        font-size: 15px;
        line-height: 1.5;
      }
      .collapseDivs {
        h1 {
          font-size: 15px;
          span {
            width: 38px;
            height: 31px;
          }
        }

        .app__content {
          padding: 20px;
          p,
          strong {
            font-size: 14px;
          }
          ul {
            li {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    &.ibp {
      .services {
        .type {
          h2 {
            font-size: 13px;
            span {
              width: 34px;
              height: 30px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 360px) {
    &.ibp {
      .collapseDivs {
        h1 {
          font-size: 14px;
          span {
            width: 33px;
            height: 28px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 330px) {
    &.ibp {
      .services {
        .type {
          h2 {
            font-size: 12px;
            span {
              width: 33px;
              height: 29px;
            }
          }
        }
      }
      .collapseDivs {
        h1 {
          font-size: 12px;
          span {
            width: 27px;
            height: 22px;
          }
        }

        p,
        strong {
          font-size: 12px !important;
        }
        ul {
          li {
            font-size: 12px !important;
          }
        }
      }
    }
  }
`

export const Col = styled.div`
  /* display: flex;
    flex-direction: column; */
  p {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
