export default {
  header: {
    menu: {
      li1: 'Leistungen',
      li2: 'Produkte',
      li3: 'Technologien',
      li4: 'Apllos',
      li5: 'Geschäftspartner',
      li6: 'Kontakt'
    },
    menuMobile: {
      services: 'Leistungen',
      service1: 'Digitales Erlebnis',
      service2: 'Digital Supply Chain',
      service3: 'Daten',
      products: 'Produkte',
      product1: 'APO Health Check',
      product2: 'Delfos',
      technology: 'Technologien',
      technology1: 'SAP IBP',
      technology2: 'Salesforce',
      technology3: 'Mulesoft',
      technology4: 'Delmia Quintiq',
      apllos: 'Apllos',
      about: 'Kennt',
      partners: 'Geschäftspartner',
      careers: 'Karriere',
      talk: 'Mit uns reden'
    }
  },

  footer: {
    services: {
      title: 'Leistungen',
      service1: 'Digitales Erlebnis',
      service2: 'Digital Supply Chain',
      service3: 'Daten'
    },
    products: {
      title: 'Produkte',
      product1: 'APO Health Check',
      product2: 'Delfos'
    },
    technology: {
      title: 'Technologien'
    },
    apllos: {
      title: 'Apllos',
      text1: 'Karriere',
      text2: 'Mit uns reden'
    }
  },

  formContact: {
    nameInput: 'Name',
    namePlaceholder: 'Your name',
    phoneInput: 'Phone',
    expertiseInput: 'Expertise',
    uploadCV: 'Upload CV',
    removeCV: 'Remove file',
    messageInput: 'Message',
    messagePlaceholder: 'Your message',
    requiredField: 'Required Field',
    send: 'Send',
    typeFile: 'File on .pdf',
    titleHome: 'Contact',
    title: 'Contact',
    title2: 'Contact Us',
    fileAlert: 'File type invalid',
    fileSizeAlert: 'Maximale Größe 500kb',
    success: 'Success',
    error: 'Error',
    emailSent: 'Email Sent',
    emailFail: 'Try again later'
  },

  home: {
    banner: {
      titleDesk: `Digitale Evolution im<br /> Management der Wertschöpfungskette`,
      titleMobile: `Digitale Evolution im<br /> Management der Wertschöpfungskette`,
      textDesk: `
                        Wir liefern Lösungen für intelligente Unternehmen unter <br />
                        Anwendung innovierender Technologien, um neue<br /> 
                        belastbare und flexible Geschäftsmodelle zu ermöglichen.
                        `,
      textMobile: `Wir liefern Lösungen für<br />
                   intelligente Unternehmen unter<br />
                  Anwendung innovierender <br />
                  Technologien, um neue<br /> 
                  belastbare und flexible <br />
                  Geschäftsmodelle zu ermöglichen.
      `,
    },

    dataBanner: {
      titleDesk: `Daten`,
      titleMobile: `Daten`,
      textDesk: `Apllos bietet einen durchgängigen Datenfluss<br />
                 für Ihr Unternehmen, von der Datenaufnahme<br />
                 bis zur Erstellung intelligenter Modelle.`,
      textMobile: `Apllos bietet einen<br />
                   durchgängigen Datenfluss<br /> 
                   für Ihr Unternehmen, von der<br />
                   Datenaufnahme bis zur Erstellung<br />
                   intelligenter Modelle.`
    },

    services: {
      subtitle: 'Unsere',
      title: 'Leistungen',
      service1Title: 'Digitales Erlebnis',
      service1Text:
        'Wir schaffen ein digitales Erlebnis zur Optimierung<br /> der Geschäftsprozesse, der Arbeitskultur und <br />der Kundenbeziehungen. ',
      service2Title: 'Digital Supply Chain',
      service2Text: `Basierend auf einen strategischen Ansatz, der auf Daten und<br /> Menschen ausgerichtet ist, wenden wir alle innovierenden<br /> Lösungen für die Leistung der Wertschöpfungskette an.`,
      service3Title: 'Daten',
      service3Text: `Apllos bietet einen durchgängigen Datenfluss<br />
                    für Ihr Unternehmen, von der Datenaufnahme<br />
                    bis zur Erstellung intelligenter Modelle.`,
      button: 'Mehr Sehen'
    },

    apllos: {
      title: 'simpel, adäquat',
      text: `
                    Wir glauben daran, über die digitale Transformation hinauszugehen, indem wir einfache <br />Lösungen für 
                    komplexe Herausforderungen der Businesswelt schaffen, damit Organisationen in der <br />erforderlichen 
                    Geschwindigkeit und Größe arbeiten können.
                `,
      button: 'Kennt'
    },

    products: {
      subtitle: 'Unsere',
      title: 'Produkte',
      product1Title: 'DASHBOARD MONITOR',
      product1Text: 'APO Health Check',
      product2Title: 'K.I. angewendet auf Nachfrageprognosen',
      product2Text: 'Delfos'
    },

    technology: {
      subtitle: 'Unsere',
      title: 'Technologien',
      text: ``
    },

    clients: {
      subtitle: 'Unsere',
      title: 'Kunden'
    },

    contactUs: {
      subtitle: 'Mituns',
      title: 'Reden',
      text: 'Av. Queiroz Filho, 1700 CJ. D-9 <br /> Vila Leopoldina | São Paulo-SP',
      text2: 'contato@apllos.com'
    }
  },

  digitalExperience: {
    banner: {
      title: 'Digitales Erlebnis',
      text: `
                    Wir schaffen ein digitales Erlebnis zur Optimierung der 
                    Geschäftsprozesse, der Arbeitskultur und der Kundenbeziehungen 
                    und gehen auf diese Weise auf die Marktveränderungen im 
                    digitalen Zeitalter ein. 
                `,
      tag1: 'Design Thinking',
      tag2: 'Agile Methode',
      tag3: 'Metriken',
      tag4: 'Cases'
    },

    designThinking: {
      title: `
                    Wir wenden das <strong>Design Thinking</strong> an, ein Ansatz, der die Innovation durch<br /> 
                    einen strukturierten Prozess zur Entwicklung aussagekräftigerer Projekte fördert und <br />
                    die Etablierung einer digitalen Mentalität im Unternehmen beschleunigt.
                    `,
      title2: 'Der Ansatz sieht zwei Phasen vor: Verstehen und Erkunden'
    },

    steps: {
      step1: {
        title: '1° <span>verstehen</span>',
        imersion: 'Immersion',
        imersionText:
          'Durchführung der Forschung,<br /> um ein Verständnis<br /> über den Benutzer und das Geschäft zu entwickeln',
        define: 'Definirien',
        defineText: `
                            Analyse der gesamten Forschung<br /> 
                            und Feststellung der Probleme und<br /> der Möglichkeiten für 
                            die Benutzer <br />und das Geschäft  
                        `
      },

      step2: {
        title: '2° <span>erkunden</span>',
        ideation: 'Ideen generieren',
        ideationText: `
                                Mehrere kreative<br /> 
                                <strong>Ideen</strong> zur 
                                Problemlösung<br /> generieren 
                            `,
        prototyping: 'Prototypen erstellen ',
        prototypingText: `
                    Erstellung eines<br /> 
                    <strong>funktionellen</strong> Prototyps<br /> 
                    für die wichtigsten Ideen 
                `,
        tests: 'Testen',
        testsText: `
                    <strong>Mit den Benutzern testen</strong>,<br /> 
                    um Rückmeldungen zu erhalten<br /> 
                    und Verbesserungen einzuleiten
                `
      }
    },

    about: {
      title: `Wir verfügen über ein multidisziplinäres Team,<br /> das innerhalb eines technologischen Ökosystems<br /> End-to-End-Lösungen schafft. 
            `,
      text: `
                In jeder Lösung definieren wir Erfolgsmetriken. 
                Diese entstehen<br /> aus der Analyse der Verhaltensmuster 
                von Benutzern und der<br /> Geschäftsziele sowie aus der Steuerung 
                auf Daten<br /> basierende Entscheidungen, was zum Antrieb eines <br />
                nachhaltigen Wachstums führt. 
            `
    }
  },

  digitalSupplyChain: {
    banner: {
      title: 'Digital Supply Chain',
      text: 'Resiliente Unternehmen für einen volatilen und unsicheren Markt ',
      tag1: 'Dassault Systemès',
      tag2: 'Asprova',
      tag3: 'OMP',
      tag4: 'DDMRP',
      tag5: 'SAP APO',
      tag6: 'SAP IBP',
      tag7: 'SAP S4/Hana',
      tag8: 'Sap Bussiness one',
      tag9: 'Salesforce'
    },

    section: {
      title1: `
                Um die Reaktionsfähigkeit der Wertschöpfungskette zu erhöhen, muss 
                die Verbesserung der<br /> Vorhersagbarkeit auf eine kurzfristige, 
                rapide und flexible Antwort abgestimmt sein. 
            `,
      title2: `Die Arbeit wird durch ein multidisziplinäres Team durchgeführt, das Kenntnisse über<br /> verschiedene Technologien und Geschäftsmodelle hat.`,
      title3: `Die Lösung wird systemisch ausgedacht, wobei vor allem die<br /> Bereiche und die Erfahrung des Unternehmens berücksichtigt werden. 
            `,
      title4: `
                Diesen Interaktionen liegen Methoden zugrunde, welche die 
                Innovationskultur und<br /> das Engagement fördern. Die Technologien 
                werden auf der Grundlage eines Ökosystems<br /> von Plattformen und Startups vorgeschlagen.
            `,
      title5: `
                Durch diese Kultur können Lösungen geschaffen werden, die sich den 
                Anforderungen des<br /> Geschäfts besser anpassen. Auch das 
                Konzept der Cloud-Architektur und Open Source ermöglichen<br /> 
                die Schaffung von personalisierten Lösungen.

            `,
      title6: `Die Prozesse sind so ausgerichtet, dass sie der 
                        Wertschöpfungskette <br />Resilienz, Nachhaltigkeit und Optimierung verleihen. 
            `,
      title7: `
                Um die Werterfassung sicherzustellen, werden die Lösungen durch Daten 
                und Machbarkeitsnachweise<br /> (POCs) validiert, was zur Skaleneffekte, 
                Flexibilität und einem besseren Erlebnis für die Menschen führt. 
            `
    }
  },

  data: {
    banner: {
      title: 'Daten',
      text: `Apllos bietet einen durchgängigen Datenfluss für Ihr <br />
            Unternehmen, von der Datenaufnahme bis zur <br />
            Erstellung intelligenter Modelle.`
    },
    section: {
      text1: `Wir verstehen Ihr Unternehmen und bauen ein zentrales Datenlager in der Cloud oder vor Ort auf, 
              dass die Aufnahme von halbstrukturierten, strukturierten und unstrukturierten Daten aus verschiedenen Quellen 
              mit Batch- oder Echtzeittechnologie ermöglicht. Dieser Prozess wird auf sichere, zuverlässige und automatisierte 
              Weise durchgeführt. Durch den Aufbau dieses Speicherortes können die Daten analysiert und behandelt werden,
              wobei Schichten für die Verwendung in Datenwissenschaft und Analysemodellen erzeugt werden.`,
      text2: `Mit unseren Datenwissenschaftlern und Analysten ist es möglich, Verhalten zu verstehen,zu überwachen, 
              Einblicke zu gewinnen, Prognosen und Vorhersagen zu erstellen, um Geschäftsentscheidungen zu unterstützen.  `,
      text3: `Wir arbeiten mit verschiedenen Big Data-Tools wie dem Hadoop-Ökosystem und Cloud-Lösungen - AWS, GCP, Databricks und Azure -,
              die Dienstleistungen mit niedrigen Speicherkosten und Funktionalitäten für die Verarbeitung großer Datenmengen bieten.`
    }
  },

  apllos: {
    banner: {
      words: ['Apllos', 'Απλος', 'simpel', 'adäquat'],
      title: `
                Wir sind ein multidisziplinäres Team bestehend aus über 50 Mitarbeitern, <br />
                darunter Designers, Ingenieuren, Datenwissenschaftler und Entwickler. <br />
                Wir liefern innovierende Lösungen für Unternehmen aus <br />
								verschiedenen Geschäftsbereichen. 
            `
    },
    about: {
      professionals: 'Mitarbeiter',
      clients: 'Kunden',
      projects: 'Projekte',
      text: `
                Unsere Projekte haben immer die aktive Teilnahme<br /> 
                eines erfahrenen Teams in einer horizontalen Struktur. <br />
                Auf diese Weise bietet die Co-Creation mit Kunden und <br />
                Partnern die notwendige Transparenz und Sicherheit bei <br />
                jeder Herausforderung. 
                `
    },
    info: {
      organization: 'Organisation',
      orgTitle: `Horizontale<br /> Struktur`,
      orgText: `
                Ständige Abstimmung zwischen<br /> 
                allen Teilnehmern mit einer für<br /> 
                neue Ideen und Lösungen offenen Kultur. 
            `,
      team: 'Team',
      teamTitle: 'Engagierte<br /> Spezialisten',
      teamText: `
                Spezialisten, die sich <br />
                für die Qualität der Lösung und <br />
                die Lieferfristen einsetzen.
            `,
      focus: 'Fokus',
      focusTitle: 'Innovierende<br /> Lösungen',
      focusText: `
                Anwendung der fortschrittlichsten<br /> 
                und geeigneten Lösungen für das <br />
                Geschäftswachstum. 
            `
    }
  },

  hire: {
    title: 'Arbeiten <br />Sie mit uns',
    text: `
            Herausforderungen und Raum 
            für unterschiedliche<br /> Profile 
            in einem innovativen und <br />technologischen Umfeld.  
        `,
    link1: 'Check the vacancies in our',
    link2: 'or subscribe on our',
    talents: 'Talent Pool'
  },

  apoHealthCheck: {
    banner: {
      title: 'APO <br /> Health Check',
      text: `
                Das Planungssystem APS - SAP APO<br /> 
                wie alle anderen Systeme bedarf einer <br />
                Routineüberwachung zur Problemprävention <br />
                und zur Festlegung von Maßnahmen bei Störungen. 
            `
    },
    section: {
      title: 'Start',
      text: `
                Das Apllos-Produkt – APO Health Check zeichnet die kritischen<br /> 
                Aktivitäten auf, die sich auf das Geschäft auswirken, und weist<br /> 
                auf die Verantwortlichen auf präventive Maßnahmen hin. Die <br />
                überwachten Aktivitäten sind personalisiert und die Warnmeldungen<br /> 
                werden per E-Mail mit Nachrichten, die bei der Identifikation <br />
                der zu treffenden Maßnahme helfen, ausgelöst. 
            `,
      text2: `Was das Apllos-Produkt überwacht:`
    },
    monitor: {
      title: 'Monitor',
      collapse1Title: 'CIF (Core interface)',
      collapse1Text: `<p>Es überwacht die Anzahl inkonsistenter Objekte auf dem Interface (SAP APO und SAP ECC) 
                    und zeichnet den Verlauf dieser Daten auf. Warnmeldungen werden ausgelöst, wenn das Umfeld eine 
                    kritische Anzahl an Objekten mit Problemen hat.</p>`,
      collapse2Title: 'Protokollspeicher',
      collapse2Text: `<p>Es warnt, wenn der für den Protokollspeicher reservierte Platz voll ist.</p>`,
      collapse3Title: 'Live Cache',
      collapse3Text: `<p>Es überwacht die Verfügbarkeit des Speicherplatzes „live cache“ und, im Falle einer Inaktivität, 
                    schaltet es den Verantwortlichen ein. </p>`,
      collapse4Title: 'Prozesskette',
      collapse4Text: `<p>Es überwacht, wenn die Prozessketten oder „Process Chains“ einen Abbruchstatus aufweisen 
                    oder die vorgesehene Dauer überschreiten, Warnmeldungen werden per E-Mail gesendet.</p>`,
      collapse5Title: 'Dumps',
      collapse5Text: `<p>Es überwacht die Fehler in Algorithmen, die im System entwickelt werden (SAP APO). 
                    Fehlerwarnungen werden per E-Mail zur Korrektion gesendet.</p>`,
      collapse6Title: 'Speichernutzung',
      collapse6Text: `<p>Es zeigt an, wenn eine Ausführung den Speicher oder die Verarbeitungsumgebung übermäßig 
                        in Anspruch nimmt. Dadurch können Filterfehler während der Ausführung und Leistungsfehler eines 
                        Programms erkannt werden. Dies verhindert Langsamkeit in der Umgebung.</p>`
    },
    form: {
      title: 'Durchsuchen Sie unser APO Health Check-Programm',
      paragraph: 'Füllen Sie das Formular aus und erhalten Sie den Zugangslink',
      message: 'I want to receive the access link the APO Health Check'
    }
  },

  aiForecast: {
    banner: {
      title: `Delfos`,
      subtitle: 'K.I. angewendet auf Nachfrageprognosen',
      text: `
                Delfos ist eine Lösung für Nachfrageprognosen<br /> auf hohem Niveau, die 
                auf verschiedenen<br /> Regressionsalgorithmen und<br /> Machine-Learning-Modellen 
                basiert ist. 
            `
    },
    delfos: {
      text: `
                Delfos ist die Lösung für denjenigen, der fortschrittliche KI-Tools für<br /> 
                Zeitreihenprognose anwenden will, ohne unbedingt Erfahrung in <br />
                Machine Learning oder Programmierung zu haben. 
            `
    },

    resources: {
      title: 'Ressourcen',
      colTitle1: 'Automatische Anpassung der Zeitreihe',
      colText1: `
                Automatische Anwendung statistischer Methoden zur Anpassung und<br /> 
                zur Transformation der Zeitreihenkomponenten, wie zum Beispiel, <br />
                Trend, Saisonalität, Rauschen und Autokorrelation. Diese Methoden<br /> 
                führen zu einem verbesserten Ergebnis der Algorithmen.
            `,
      colTitle2: 'Best Fit',
      colText2: `
                Mehr als 20 Algorithmen, regressive und Machine-Learning-Algorithmen,<br /> 
                die gleichzeitig ausgeführt werden. <br />
                Delfos bestimmt das geeignete Modell für jede Prognosestufe.  
            `,
      colTitle3: 'Hierarchische Prognose',
      colText3: `
                Ausführungen auf jeder Ebene der zeitlichen und strukturellen Hierarchie. <br />
                Sie können, zum Beispiel, eine Prognose nach Regionen und danach nach<br /> 
                Kunden erstellen, indem Sie monatliche und wöchentliche Zeitfrequenzen anwenden.
            `,
      colTitle4: 'Wie es funktioniert',
      colText4: `
                Delfos funktioniert als managed service unter Verwendung der<br /> 
                historischen Datenbank. <br />
                Das System identifiziert die Korrelation zwischen den Variablen und<br /> 
                entwickelt personalisierte und geeignete Prognosemodelle für jedes<br /> 
                festgestellte Szenarium
            `,
      colText5: `
                Apllos stellt die gesamte notwendige Infrastruktur für die Ausführungen<br /> 
                zur Verfügung, ohne dass weitere Kosten für das Unternehmen entstehen.
            `,
      colText6: `
                Sie müssen nur ihre Daten hochladen, um bessere Ergebnisse beim<br /> 
                Prozess der Nachfrageprognosen zu erhalten und die fortschrittlichen<br /> 
                Techniken künstlicher Intelligenz anwenden.
            `
    }
  },

  contact: {
    title: 'Kontakt',
    text: 'Es wird eine Ehre sein, gemeinsam etwas aufzubauen'
  },

  talents: {
    title: 'Talent Pool',
    text: `
            Herausforderungen und Raum 
            für unterschiedliche<br /> Profile 
            in einem innovativen und <br />technologischen Umfeld.
        `,
    check: `Check the vacancies in our`
  },

  sapIbp: {
    title: 'SAP integriert<br /> Geschäftsplanung ',
    text: `
            SAP IBP ist eine komplette Lösung für die integrierte Planung<br /> der Wertschöpfungskette. 
            Die Plattform wurde auf einer<br /> Cloud- und Speicherdatenbank mit SAP HANA 
            aufgebaut, welche<br /> eine Datenverarbeitung in Echtzeit ermöglicht. 
            `,
    text2: `Im Unterschied dazu verfügt Apllos über umfassende Erfahrung in der Implementierung von Planungsprozessen und APS-Systemen sowie über ein multidisziplinäres Team zur Entwicklung von Schnittstellen auf mehreren Plattformen, die Datenanalyse und die Prozessdefinition, um gemeinsam eine Lösung zu schaffen, die dem Kunden einen Mehrwert bringt. `,
    text3: `SAP IBP ist eine komplette Lösung für die integrierte Planung der Wertschöpfungskette. Sie kombiniert Vertriebs- und Betriebsfunktionen, Nachfrage, Angebotsplanung, Kontrollturm der Wertschöpfungskette und Bestandsoptimierung. Die Plattform ist durch die SAP HANA Cloud Integration in die unterschiedlichsten Technologiearchitekturen integriert, sodass die Daten für die Planung immer aktualisiert werden.  
        `,
    text4: `Die Schnittstelle wurde entwickelt, um mit der Plattform Web, Fiori und Excel zu arbeiten entwickelt; dies erleichtert die Gebrauchsfähigkeit und Iteration zwischen den Geschäftsprozessen.  
        `,

    services1Title: 'Supply Chain Control Tower',
    services1Text: `Sichtbarkeitsplattform der Lieferkette in Echtzeit zur Unterstützung von Entscheidungen und schnellen Korrekturmaßnahmen. <br />
            <strong>Hauptvorteile:</strong>
        `,
    services1List: {
      text1: `End-to-End-Transparenz in der gesamten Lieferkette, einschließlich Warnungen, Entscheidungsunterstützung und schnellen Korrekturmaßnahmen; 
            `,
      text2: `Rückgang der globalen Lagerbestände, Risikominderung; `,
      text3: `Leistungssteigerung der Lieferzeit an den Kunden;`,
      text4: `Zusammenarbeit, Kommunikation und Überwachung der Lieferkette;`,
      text5: `Erhöhte Lieferagilität und reduzierte Kosten in der Versorgungskette.`
    },

    services2Title: 'IBP For Sales and Operations',
    services2Text: `IBP S&OP ist eine Plattform für die kollaborative Bedarfsplanung und statistische Voraussichtsberechnung mittels einfacher mathematischer Modelle. Das Tool verwendet flexible und einheitliche Daten aus der Lieferkette, um die taktische und strategische Planung auf jeder Ebene der Granularität und Dimension zu unterstützen.
        `,
    services2Text2: `Im Falle einer erweiterten Bedarfsplanung wird die Verwendung von IBP Demand empfohlen.<br />
            <strong>Schlüsselfunktion</strong>
        `,
    services2List: {
      text1: `Kollaborative Bedarfsplanung und einfache statistische Voraussicht;
            `,
      text2: `Mehrstufige unendliche Planung;`,
      text3: `Manuelle Anpassung der Betriebsplanung und ihrer Variablen zur Analyse und Simulation;`,
      text4: `Verwendung von Heuristik, die Folgendes berücksichtigt: Transportvorlaufzeit, aktueller Bestand, Sicherheitsbestand, Zielbestand, Mindestmenge, Abrundungswert, unter anderen Parameter;`,
      text5: `Beide Prozesse arbeiten mit integrierten ERP-Informationen und ermöglichen die Analyse und Simulation des ganzen Plans und des Delta-Plans;`,
      text6: `Um die finite Ressourcenkapazität, Bestandsbeschränkungen und andere erweiterte Modellierungen zu berücksichtigen, wird die Verwendung von IBP Responde and Supply empfohlen.`
    },

    services3Title: 'IBP For Demand',
    services3Text: `Kurz-, mittel- und langfristige Planung des statistischen Bedarfs in einer einzigen Lösung. Die Plattform verwendet Datenbehandlung, Klassifizierung des Verhaltens, Aggregation und Disaggregation sowie fortschrittliche mathematische Modelle wie künstliche Intelligenz und Regression zur Bedarfsvoraussicht. 
        `,
    services3Text2: `Die Plattform unterstützt die Schaffung eines Konsensbedarfs durch kollaborative Planung und liefert relevante Informationen für die Plananalyse. 
        <br />
            <strong>Schlüsselfunktion</strong>
        `,
    services3List: {
      text1: `Demand-Sensing und mittel-/langfristige Voraussicht in einer einzigen Lösung zur Bedarfsplanung; 
            `,
      text2: `Automatisierter Planungsprozess basierend auf Ausnahmen sowie manuelle Planungsfunktionen;`,
      text3: `Integrierte und dynamische Bedarfsanalyse;`,
      text4: `Verlaufslöschung und Ausreißerkorrektur;`,
      text5: `Statistische Voraussicht mit verfügbaren erweiterten Modellen;`,
      text6: `Aggregation/Disaggregation von Zeitreihendaten;`,
      text7: `Kollaborative Bedarfsplanung;`,
      text8: `Generierung von Warnungen;`,
      text9: `Benutzung von Dashboards zur Unterstützung der Planung.`
    },

    services4Title: 'IBP For Inventory',
    services4Text: `Das SAP IBP-Inventar verfügt über einen hochentwickelten Algorithmus zur Optimierung, mit dem die beste Inventarrichtlinie unter Berücksichtigung der Lieferkette als mehrstufig, kosten-, variabel- und serviceorientiert definiert werden kann.  `,
    services4Text2: `Einige im Algorithmus verwendete Parameter:`,
    services4List: {
      text1: `Variabilität der Nachfrage`,
      text2: `Voraussichtsfehler`,
      text3: `Umleitung von Lieferungen`,
      text4: `Kosten`,
      text5: `Vorlaufzeiten [lead times]`,
      text6: `Glieder der Lieferkette`,
      text7: `Positionierung der Vorräte entlang der Lieferkette`
    },

    services5Title: 'IBP For Resp N Supply',
    services5Text: `SOP Response and Supply Planning ist die Lösung für die Lieferungsplanung und Allokation der Lieferkette. Das Tool verfügt über verschiedene heuristische Modelle und Optimierungen, diese dienen zum Erstellen von Szenarien und Aufträgen für die Planung der Produktion, Verteilung und Nachschub. 
        `,
    services5Text2: `Schlüsselfunktionen:`,
    services5List: {
      text1: `Optimierung mit Zielfunktion zur Maximierung des Gewinns oder des Serviceniveaus`,
      text2: `Heuristik`,
      text3: `Mehrstufige unendliche Planung`,
      text4: `Kostengesteuerte Prioritäten 
            `,
      text5: `Sicherheitsbestand und maximaler Bestand 
            `,
      text6: `Kosten für verspätete Lieferung, Verletzung des Sicherheitsbestands und Bestand pro Einheit, Produktionskosten, Transport, Einkäufe pro Einheit oder fest, Produktionskosten, u.a. 
            `,
      text7: `Generierung von Lieferaufträgen (Produktion, Einkauf, Vertrieb) und Integration mit einem nahezu in Echtzeit neuen Integrationsmodell mit ERP`
    }
  },

  salesforce: {
    title: 'Salesforce',
    text: `
        CRM Salesforce ist eine Plattform mit einer führenden <br />
        weltweit meistverkauften Software für das<br /> 
        Kundenbeziehungsmanagement. 
        `,
    text2: `Das Tool verwaltet grundlegende Fragen für den Erfolg des Geschäfts wie Verkaufstrichter, Leads und Aufgabenverfolgung.`,
    text3: `Salesforce bietet eine cloudbasierte Infrastruktur, das heißt, der Verkäufer kann die Informationen seines Kunden von allen Geräten abrufen, die eine Internetverbindung haben.`,
    text4: `Salesforce wurde konzipiert, um die Entwicklung zu erleichtern, denn seine Implementierung ist schnell und mit niedrigen Kosten verbunden. Die Lizenzen können von kleinen, mittelgroßen und großen Unternehmen verwendet werden.`,
    text5: `Salesforce bietet verschiedene Lösungen für ihr Geschäft und zu den wichtigsten zählen: Sales Cloud, Service Cloud, Marketing Cloud, unter anderen.`,
    services1Title: 'Sales Cloud',
    services1Text: `Es ist eine Komplettlösung mit umfangreichen Funktionen, mit der die Arbeit der Marketing- und Verkaufsteams wettbewerbsfähiger wird.
        `,
    services1Text2: `Vorteile
        `,
    services1List: {
      text1: `Sie erhalten eine 360-Grad-Kundensicht und gewinnen dadurch das Vertrauen für einen sicheren Verkauf und einen besseren Kundendienst;
            `,
      text2: `Es steigert den Einsatz von Verkäufern und Partnern, indem Erfahrungen geschaffen werden, die den Verkaufsprozess mit deutlichen und relevanten Informationen verbessern;`,
      text3: `Die Momente der Kaufentscheidung eines möglichen Kunden können in Echtzeit in der Organisation verfolgt werden;`,
      text4: `Handlungen und Prozesse können automatisiert werden;`,
      text5: `Informationen über Konkurrenten sowie Verträge und Verhandlungen können kontrolliert werden.`
    },

    services2Title: 'Service Cloud',
    services2Text: `
        Es ist eine Lösung, um die Anforderungen ihrer Kunden, ihres Produkts oder Dienstleistung zu erfüllen. Mit diesem Tool behalten Sie ihre Kunden und schaffen Mehrwert für ihre Marke.
        `,
    services2Text2: `Vorteile
        `,
    services2List: {
      text1: `Steigerung der Kundenzufriedenheit;
            `,
      text2: `Optimierung der Prozesse und Anrufe und Reduzierung der Problembehebungen sowie drastische Verbesserung der Kundenzufriedenheit;`,
      text3: `Fallmanagement, Zugangskanäle für Kunden, Integration mit Legacy-Systemen, Support-Tickets, Wissensbasis, Routing und Eskalation sowie Verwaltung der Kunden-Support-Warteschlangen;`,
      text4: `Kundenkontakt durch den Kanal ihrer Präferenz, sei es per Telefon, SMS, (Textnachrichten), WhatsApp oder Facebook Messenger;`,
      text5: `Verbessert den Ruf des Unternehmens und erhöht die Verkaufschancen;`,
      text6: `Festlegung von Zielsetzungen und Verfolgung der Ergebnisse.`
    },

    services3Title: 'Marketing Cloud',
    services3Text: `Es ist eine Plattform für die Schaffung von relevanten und personalisierten Kundenreisen für ihre Zielgruppe über die richtigen Kanäle und Geräte.
        `,
    services3Text2: `Vorteile`,
    services3List: {
      text1: `Sie können Daten von unterschiedlichen Quellen und Geräten mit dieser Plattform verbinden und auf diese Weise eine einheitliche Ansicht des Kunden erhalten. Sie können auch Daten von Primär-, Sekundär- und Drittanbietern erfassen und aktivieren;`,
      text2: `Datenbereitstellung an das Tool für künstliche Intelligenz der Salesforce mit dem Namen Einstein. Die durchgeführten Analysen ermöglichen organisierte und personalisierte Interaktionen mit dem Kunden;`,
      text3: `Es erzeugt Aufmerksamkeit und Bewusstsein während des gesamten Prozesses (ein bidirektionales Echtzeit-Engagement) und liefert dadurch Insights, um jedem Kunden die beste Aktion anzubieten.`
    }
  },

  mulesoft: {
    title: 'MuleSoft',
    text: `
        Mule ist ein leichter und skalierbarer Laufzeitmechanismus<br /> für APIs, 
        Integrationen und Microservices. Er wird mit einer<br /> hybriden Plattformarchitektur 
        – lokal, cloudbasiert oder<br /> beides – verwendet.
        `,
    text2: `Als ein globales führendes Unternehmen in Integrationen und API-Verwaltungslösungen 
        ist es das Ziel von MuleSoft, mit der Anypoint Platform ihre Applikationen zu verbinden und 
        die herausforderndsten Verbindungsprobleme in SOA, SaaS und APIs zu lösen.<br />
        Mulesoft ist eine einfache Plattform für die Erstellung von APIs und das Integrationsmanagement, 
        unabhängig davon, ob es um ERPs, MES oder Backend-Anwendungen geht.
        `,
    text3: `Gemeinsam beschleunigen Salesforce und MuleSoft ihre digitale Transformation, 
        indem Sie Daten an Legacy-Systemen, Cloud-Anwendungen und Entscheidungs-Tools öffnen können, 
        um rapide und intelligente Entscheidungen zu treffen sowie den Verbrauchern höchst besondere 
        Erfahrungen zu schaffen.
        `,
    text4: `Die Anypoint Platform beschleunigt die Entwicklungsgeschwindigkeit, indem APIs, 
        Anschlüsse, Modelle, Beispiele und andere Integrationsressourcen, die bereits in Mulesofts 
        Exchange erstellt und veröffentlicht sind, wiederverwendet werden.
        `
  },

  delmiaQuintiq: {
    title: 'Delmia Quintiq',
    text: `Die DELMIA Quintiq Supply Chain Planning Software plant und<br /> 
						optimiert Ihre gesamte Lieferkette und gibt Ihnen das Maß<br /> 
						an Kontrolle und Einblick, das Sie benötigen, um die<br /> 
						Lieferkettenplanung von einer Kostenstelle zu einem<br /> 
						Umsatzbringer zu machen.`,
    text2: `KPIs für das gesamte Unternehmen klar definieren und<br /> 
						jede Geschäftsfunktion auf diese Ziele ausrichten. Jede<br /> 
						Etappe von Ihrer Lieferkette optimieren – von den<br /> 
						langfristigen strategischen Plänen bis zu den<br /> 
						kritischen Details des Umsetzungspunktes – um ein<br /> 
						hohes Niveau an Dienstleistungen, Qualität und<br /> 
						Effizienz zu erreichen, das beständig und nachhaltig<br /> 
						ist.`,
    text3: `DELMIA Quintiq ist eine Planungs- und Optimierungssoftware für die Lieferkette (SCP&O), die die Kraft der Optimierung bietet.`,
    text4: `<strong>Sales and operations planning</strong> – integrierte Planung von Vertrieb und Betrieb. Fortgeschrittene Modellierungs- und Optimierungsfunktionen, um eine beliebige Anzahl von Was-wäre-wenn-Szenarien zu simulieren und dann das beste Szenario zu empfehlen, um die Geschäfts-KPIs zu erfüllen und eine effektive Entscheidungsfindung zu unterstützen, um einen maximalen Wert zu erzielen.`,
    text5: `<strong>Master planning</strong> – unterstützt die globale Entscheidungsfindung durch Echtzeittransparenz und prädiktive Analysen für die Masterplanung und -terminierung sowie die Materialbedarfsplanung.`,
    text6: `<strong>Detaillierte Produktionsplanung</strong> – Verkürzung der Durchlaufzeiten und Reduzierung der Lagerbestände durch Optimierung der Produktion innerhalb und zwischen Produktionslinien, Arbeitszellen und Montagevorgängen bei gleichzeitiger Verbesserung der Anlagennutzung und Einhaltung der Kundenfristen.`,
    text7: `<strong>Planung und Optimierung der Logistikaktivitäten</strong> – Manager, Planer und Spediteure erhalten volle Transparenz und Kontrolle über den gesamten Planungs- und Optimierungsprozess - von der strategischen, langfristigen Netzwerkplanung bis hin zur täglichen Personaleinsatz- und Routenplanung.`,
    text8: `<strong>Planejamento e otimização das operações de trânsito</strong> – uma solução para lidar com todos os desafios - desde a programação da tripulação 
					e da frota até a conquista de novas licitações por meio de licitações abertas. A solução funciona para operações grandes e pequenas e se 
					integra perfeitamente a uma ampla gama de sistemas de terceiros.`,
    text9: `<strong>Planung und Optimierung der Tätigkeiten des Personals</strong> – Auf der Grundlage eines 100%ig zweckmäßigen Geschäftsmodells, das alle einzigartigen Einschränkungen Ihres Unternehmens berücksichtigt, wie z. B. die Fähigkeiten und Qualifikationen der Mitarbeiter, spezifische Vertragsanforderungen, arbeitsrechtliche Bestimmungen und die Verfügbarkeit von Ressourcen. Sie definieren Ihre Effizienz-, Zufriedenheits- und Rentabilitätsziele; wir geben Ihren Planern die Werkzeuge und Erkenntnisse an die Hand, die sie benötigen, um die wichtigen Ergebnisse zu verbessern.`,
    text10: `Die DELMIA Quintiq-Software umfasst eine Webanwendung, die mit einer neuen, modernen Schnittstelle entwickelt wurde, bei der die Benutzerfreundlichkeit im Vordergrund steht. Dies gibt den Anwendern die Freiheit und Flexibilität, von jedem Webbrowser aus sofort auf DELMIA Quintiq zuzugreifen.`,
    text11: `https://www.3ds.com/products-services/delmia/products/delmia-quintiq/`
  },

  partners: {
    main: {
      title: `Unsere Geschäftspartner`,
      text: `Wir arbeiten mit einem Ökosystem von<br /> Geschäftspartnern zusammen, um innovative<br /> Lösungen für Ihr Unternehmen zu finden.`
    },
    dassault: {
      text: `Dassault Systèmes, ein 3DEXPERIENCE-Unternehmen, stellt Unternehmen und Einzelpersonen virtuelle Universen zur Verfügung, um nachhaltige Innovationen zu schaffen, die zur Verbesserung von Geschäftsprozessen und -praktiken beitragen. Dessen weltweit bekannte Lösungen verändert die Form wie Produkte entwickelt, hergestellt und verwaltet werden. Die kollaborativen Lösungen der Dassault Systèmes fördern die soziale Innovation, indem die Möglichkeiten zur Verbesserung der realen Welt durch die virtuelle Welt erweitert werden. Das Unternehmen bietet mehr als 210.000 Kunden aller Größen und Branchen in über 140 Ländern einen Mehrwert.`
    },
    sap: {
      text: `SAP ist einer der weltweit führenden Entwickler von Software für das Management von Geschäftsprozessen, wie ECC, S4 Hana, IBP, SAC, und schafft Lösungen, die eine effektive Datenverarbeitung und den Informationsfluss zwischen Unternehmen erleichtern.<br /><br /> Zurzeit hat SAP mehr als 230 Millionen Nutzer in der Cloud, mehr als 100 Lösungen für alle Geschäftsfunktionen und das größte Cloud-Portfolio aller Anbieter.`
    },
    poweron: {
      text: `PowerOn ermöglicht es Ihrem PowerBI, Aktualisierungs- und Schreibvorgänge durchzuführen, indem es Ihre Dashboards mit über 200 Datenbanken integriert. Dies ermöglicht die Erstellung kollaborativer Pläne, dynamischer Modelle mit Parameteränderungen und eine bessere Benutzerfreundlichkeit für Ihre Plattform.`
    },
    aimms: {
      text: `AIMMS bietet eine Modellierungs- und Optimierungsplattform, die in verschiedenen Funktionen in praktisch allen Unternehmen eingesetzt wird. Es handelt sich um eine großartige Alternative für Teams, die bereit sind, sich von alten Tabellenkalkulationen oder Werkzeuge für Netzwerkdesign, Bedarfsprognosen und S&OP/IBP zu verabschieden. AIMMS SC Navigator bietet Cloud-fähige Anwendungen, mit denen Sie die Vorteile anspruchsvoller Analysen nutzen können, ohne analytische Fachkenntnisse zu benötigen. Dazu gehören erstklassige Solver (und Solver-Links) für lineare, gemischt-ganzzahlige und nicht-lineare Programmierung wie baron, cplex, conopt, gurobi, knitro, path, snopt und xa. Darüber hinaus stehen Konzepte wie stochastische Programmierung und robuste Optimierung zur Verfügung, um die Datenunsicherheit in die Modelle einzubeziehen. 
      `
    },
    asprova: {
      text: `Asprova ist ein APS-System (Advanced Planning & Scheduling) für die Hochgeschwindigkeits-Produktionsplanung für mehrere Artikel und Prozesse mit der Integration von Vertrieb, Fertigung, Lager und Einkauf. Asprova wurde ursprünglich in Japan entwickelt, wo es das am meisten eingesetzte Programmierwerkzeug ist, mit einer Marktbeteiligung von 52,4% (laut Techno Systems Research Co.,Ltd Report 2011)`
    }
  },

  asprova: {
    text: `Asprova funktioniert wie eine einfache Excel-Tabelle,<br /> selbst bei großen Datenmengen, einfache Wartung.`,
    text2: `Asprova wurde ursprünglich in Japan entwickelt, wo es mit einem Marktanteil von 52,4% das am häufigsten verwendete Produktionsplanungstool ist. (Laut Techno Systems Research Co., Ltd Report 2011)`,
    text3: `Asprova ist ein APS (Advanced Planning & Scheduling)-System zur schnellen Produktionsplanung für verschiedene Artikel und Prozesse mit Integration von Vertrieb, Fertigung, Bestand und Einkauf. Das Tool verfügt über verschiedene Funktionen zur Produktionsplanung und kann einfach ohne die Komplexität neuer Codierungen angepasst werden.`,
    text4: `Die Anwendung arbeitet mit zwei Arten der Datenvisualisierung: einer leicht zu bearbeitenden Tabellenkalkulation und einer grafischen Ansicht. Ressourcen, Produktionsaufträge, Bestände, Einkäufe und Verkäufe können somit angezeigt werden. Die Planung erfolgt in verschiedenen zeitlichen Bündelungen wie Tag, Woche und Monat und ist außerdem flexibel für Benutzeranpassung. Die Anwendung lässt sich in verschiedene ERPs wie SAP und Oracle integrieren.`,
    features: {
      title: `<strong>Einige Funktionen</strong>`,
      text: `Vertrieb: Verknüpftung von Verkaufsinformationen mit der Produktionsplanung des Werks.`,
      text2: `Einkauf: Verknüpfung der Produktionsplanung mit den Einkaufsinformationen.`,
      text3: `KPI: Bewertung mehrerer KPIs in jedem Projekt.`,
      text4: `Max. Zeitbeschränkung: Anpassung der Standardzeit zwischen Prozessen.`,
      text5: `Ressourcenblockierung: Vorübergehende Blockierung einer Ressource nach der Produktion.`,
      text6: `Ereignis: Festlegung der Bedingungen und Erstellung von Ereignissen.`,
      text7: `Optimierung: Work Sequencing für alle Ressourcen.`,
      text8: `Szenarien: Unterstützung für die Erstellung von verschiedenen Szenarien.`,
      text9: `Ressourcenbeschränkungen: Wählen Sie mithilfe von Tabellen und Diagrammen unmittelbar Überlastungen und verzögerte Lieferungen aus.`
    }
  }
}
