export default {
  header: {
    menu: {
      li1: 'Services',
      li2: 'Products',
      li3: 'Technologies',
      li4: 'Apllos',
      li5: 'Partners',
      li6: 'Contact'
    },
    menuMobile: {
      services: 'Services',
      service1: 'Digital Experience',
      service2: 'Digital Supply Chain',
      service3: 'Data',
      products: 'Products',
      product1: 'APO Health Check',
      product2: 'Delfos',
      technology: 'Technologies',
      technology1: 'SAP IBP',
      technology2: 'Salesforce',
      technology3: 'Mulesoft',
      technology4: 'Delmia Quintiq',
      apllos: 'Apllos',
      about: 'About',
      partners: 'Partners',
      careers: 'Careers',
      talk: 'Contact Us'
    }
  },

  footer: {
    services: {
      title: 'Services',
      service1: 'Digital Experience',
      service2: 'Digital Supply Chain',
      service3: 'Data',
    },
    products: {
      title: 'Products',
      product1: 'APO Health Check',
      product2: 'Delfos'
    },
    technology: {
      title: 'Technologies'
    },
    apllos: {
      title: 'Apllos',
      text1: 'Careers',
      text2: 'Contact Us'
    }
  },

  formContact: {
    nameInput: 'Name',
    namePlaceholder: 'Your name',
    phoneInput: 'Phone',
    expertiseInput: 'Expertise',
    uploadCV: 'Upload CV',
    removeCV: 'Remove file',
    messageInput: 'Message',
    messagePlaceholder: 'Your message',
    requiredField: 'Required Field',
    send: 'Send',
    typeFile: 'File on .pdf',
    titleHome: 'Contact',
    title: 'Contact',
    title2: 'Contact Us',
    fileAlert: 'File type invalid',
    fileSizeAlert: 'Maximum size 500kb',
    success: 'Success',
    error: 'Error',
    emailSent: 'Email Sent',
    emailFail: 'Try again later'
  },

  home: {
    banner: {
      titleDesk: `Digital Evolution in Value<br /> Chain Management`,
      titleMobile: `Digital Evolution in<br /> Value Chain Management`,
      textDesk: `We deliver solutions to smart companies, <br />
                        using innovative technologies to enable <br />
                        new resilient and flexible business models. `,
      textMobile: `We deliver solutions to<br /> smart companies, 
                            using<br /> innovative technologies<br /> to enable 
                            new resilient <br />and flexiblebusiness models. `
    },

    dataBanner: {
      titleDesk: `Data`,
      titleMobile: `Data`,
      textDesk: `Apllos works with the end-to-end data <br />
                flow for your business, from ingestion <br />
                to construction of intelligent models.`,
      textMobile: `Apllos works with the<br />
                   end-to-end data flow <br />
                   for your business, <br />
                   from ingestion to construction <br />
                   of intelligent models.`
    },

    services: {
      subtitle: 'Our',
      title: 'Services',
      service1Title: 'Digital Experience',
      service1Text:
        'We create the digital experience to optimize<br />  business processes, work culture and customer relations. ',
      service2Title: 'Digital Supply Chain',
      service2Text: `We apply all innovative solutions for Value Chain <br />performance based on a strategic approach driven to data and people. `,
      service3Title: 'Data',
      service3Text: `Apllos works with the end-to-end data <br />
                    flow for your business, from ingestion <br />
                    to construction of intelligent models.`,
      button: 'See more'
    },

    apllos: {
      title: 'simple, apropriate',
      text: `
                    We believe in going beyond digital transformation, seeking simple solutions for<br /> complex
                    business challenges so that organizations operate at the speed and<br /> scale required. 
                `,
      button: 'Explore'
    },

    products: {
      subtitle: 'Our',
      title: 'Products',
      product1Title: 'DASHBOARD MONITOR',
      product1Text: 'APO Health Check',
      product2Title: 'A.I. applied to demand forecast',
      product2Text: 'Delfos'
    },

    technology: {
      subtitle: 'Our',
      title: 'Technologies',
      text: `We work with a ecosystem of technology<br /> 
                    Below the main technologies of our  partners
            `
    },

    clients: {
      subtitle: 'Our',
      title: 'Clients'
    },

    contactUs: {
      subtitle: 'Contact',
      title: 'Us',
      text: 'Av. Queiroz Filho, 1700 CJ. D-9 <br /> Vila Leopoldina | São Paulo-SP',
      text2: 'contato@apllos.com'
    }
  },

  digitalExperience: {
    banner: {
      title: 'Digital Experience',
      text: `
                    We create the digital experience to optimize business processes, 
                    work culture and customer relations, <br />responding to market 
                    changes in the digital era. 
                `,
      tag1: 'Design Thinking',
      tag2: 'Agile Methodology',
      tag3: 'Metrics',
      tag4: 'Cases'
    },

    designThinking: {
      title: `
                    We use <strong>Design Thinking</strong>, an approach that fosters innovation through<br /> 
                    a structured process for development of more assertive projects, speeding up<br /> 
                    adoption of the digital mindset at the company. 
                `,
      title2:
        'This approach comprises two stages: Understanding and Exploring. '
    },

    steps: {
      step1: {
        title: '1° <span>Understanding</span>',
        imersion: 'Imersion',
        imersionText:
          'Conducting research to<br /> develop and understanding<br /> of the user and the business',
        define: 'Define',
        defineText: `Analyzing all research work and<br /> <strong>identifying where problems and opportunities<br /> exist</strong> for your users and business`
      },

      step2: {
        title: '2° <span>Exploring</span>',
        ideation: 'Ideation',
        ideationText: `Generating various <br />creative <strong>ideas</strong> for <br />solving the problem.`,
        prototyping: 'Prototyping',
        prototypingText: `Constructing a <strong>functional</strong><br /> prototype for your<br /> key ideas `,
        tests: 'Tests',
        testsText: `<strong>Testing with users</strong><br /> to collect feedback and<br /> improve`
      }
    },

    about: {
      title: `We count on a multidisciplinary team that creates<br /> end-to-end solutions within a technology ecosystem. `,
      text: `For each solution, we define success metrics, analyzing<br /> users’ behavior patterns and business objectives, 
                    directing<br /> data-based decisions and driving sustainable growth. `
    }
  },

  digitalSupplyChain: {
    banner: {
      title: 'Digital Supply Chain',
      text: 'Resilient companies for a volatile and uncertain market',
      tag1: 'Dassault Systemès',
      tag2: 'Asprova',
      tag3: 'OMP',
      tag4: 'DDMRP',
      tag5: 'SAP APO',
      tag6: 'SAP IBP',
      tag7: 'SAP S4/Hana',
      tag8: 'Sap Bussiness one',
      tag9: 'Salesforce'
    },

    section: {
      title1: `
                To increase the chain’s ability to react, predictability improvement <br />must be orchestrated with a quick and flexible response in the short term. 
            `,
      title2: `This work is performed by a multidisciplinary team with knowledge<br /> of different technologies and business models.`,
      title3: `Solution is designed in a systemic way, but above all<br /> respecting the company’s areas and experience. `,
      title4: `These interactions are guided by methodologies that promote engagement<br />  and a culture of innovation.
                    Technologies are suggested based on an ecosystem of<br /> platforms and startups. `,
      title5: `This culture enables construction of solutions with increased adherence to the<br /> business requirements. 
                    Additionally, the concept of cloud architecture and open source<br /> code enables creation of customized solutions.`,
      title6: `Processes are modeled to provide resilience,<br /> sustainability and optimization to the value chain. `,
      title7: `To guarantee value capture, solutions are validated by data and proof of concept (POC),<br /> 
                generating scale, flexibility and the best experience for people. `
    }
  },

  data: {
    banner: {
      title: 'Data',
      text: `Apllos works with the end-to-end data flow <br />
            for your business, from ingestion to construction <br />
            of intelligent models.`
    },
    section: {
      text1: `We understand your business to build a centralized repository of data in the cloud or on-premise
            that allows the ingestion of semi-structured, structured, and unstructured data from various sources using batch 
            or real-time technology. This process is conducted in a safe, dependable, and automated way. The construction of 
            this repository allows the data to be analyzed and treated, generating layers for use in science models and data analysis.`,
      text2: `With our scientists and data analysts, it is possible to understand behaviors, monitoring, insights, forecasts, 
            and predictions to support business decisions.`,
      text3: `We work with different big data tools such as the Hadoop ecosystem and cloud solutions - AWS, GCP, Databricks, 
            and Azure - which have low-cost storage services and functionalities for processing high volumes of data.`
    }
  },

  apllos: {
    banner: {
      words: ['The Apllos', 'The Απλος', 'simple', 'apropriate'],
      title: `We are a multidisciplinary team comprising more than 50 employees<br />
                including designers, engineers, data scientists and developers. <br/>
                We deliver innovative solutions for companies across various industries. `
    },
    about: {
      professionals: 'Professionals',
      clients: 'Customers',
      projects: 'Projects',
      text: `Our projects always entail the active participation<br /> 
                    of an experienced team, in a horizontal structure.<br />
                    As such, co-creation with customers and partners<br /> provides transparency and security at each challenge. `
    },
    info: {
      organization: 'Organization',
      orgTitle: `Horizontal <br/>structure`,
      orgText: `Constant alignment among<br /> all those involved, with a<br /> culture that is open to new<br /> ideas and solutions. `,
      team: 'Team',
      teamTitle: 'Committed <br />specialists ',
      teamText: `Specialists committed <br />to quality of the solution <br />and deadlines for delivery. `,
      focus: 'Focus',
      focusTitle: 'Innovative <br />solutions',
      focusText: `Application of the most advanced<br /> and appropriate solutions for <br />evolution of the business. `
    }
  },

  hire: {
    title: 'We are<br /> Hiring',
    text: `Challenges and space for different profiles <br />in an environment of innovation and technology. `,
    link1: 'Check the vacancies in our',
    link2: 'or subscribe on our',
    talents: 'Talent Pool'
  },

  apoHealthCheck: {
    banner: {
      title: 'APO <br /> Health Check',
      text: `The APS - SAP APO planning system,<br /> like every system, requires routine<br /> 
                    monitoring to prevent problems and<br /> action in case of failure. `
    },
    section: {
      title: 'Initiation',
      text: `Apllos  APO Health Check is designed to map the critical activities that 
                    impact the<br /> business and preventively alert actions for those in charge.
                     Monitored activities<br /> are customized and alerts are triggered by email 
                    with messages that help<br /> identify the action.  `,
      text2: `What Apllos product monitors:`
    },
    monitor: {
      title: 'Monitor',
      collapse1Title: 'CIF (Core interface)',
      collapse1Text: `<p>Monitors the quantity of inconsistent objects in the interface (SAP APO and SAP ECC), 
                        in addition to registering the history of these data. Alerts are triggered when the environment has a critical number
                         of objects with problems.</p>`,

      collapse2Title: 'Log storage',
      collapse2Text: `<p>Alerts when usage reserved for log storage is full. </p>`,

      collapse3Title: 'Live Cache',
      collapse3Text: `<p> Monitors “live cache” memory availability and in case of inactivity, contacts the person in charge. </p>`,

      collapse4Title: 'Process Chain',
      collapse4Text: `<p>Monitors when process chains’ status is “cancel” or exceed the expected duration. Alerts are sent by email. </p>`,

      collapse5Title: 'Dumps',
      collapse5Text: `<p>Monitors failures in algorithms developed on the system (SAP APO). Failure alerts are sent by email for correction.  </p>`,

      collapse6Title: 'Memory usage',
      collapse6Text: `<p>Shows when a certain run overly uses the environment’s memory or processing. As such, failure in running filters and performance failures in a program will be identified. This will avoid environment slowness.</p>`
    },
    form: {
      title: 'Browse our APO Health Check program',
      paragraph: 'Fill out the form and receive the access link',
      message: 'I want to receive the access link the APO Health Check'
    }
  },

  aiForecast: {
    banner: {
      title: `Delfos`,
      subtitle: 'A.I. applied to demand forecast',
      text: `Delfos is a high-level demand forecast solution<br /> based on several regression algorithms<br /> and machine learning models. `
    },
    delfos: {
      text: `Delfos is the solution for anyone who wants to use advanced<br /> 
                    artificial intelligence tools to forecast time series, without the<br /> 
                    need to have machine learning or programming experience. 
            `
    },

    resources: {
      title: 'Resources',
      colTitle1: 'Automatic adjustment of time series',
      colText1: `Automatic application of statistic methods for adjustment and<br /> 
                    transformation of time series components, such as trend,<br /> 
                    seasonality, noise and autocorellation. These methods imply<br /> improvement in the results of the algorithms.  
            `,
      colTitle2: 'Best Fit',
      colText2: `20+ regression and machine learning algorithms executed simultaneously. <br />
                    Delfos determines which model is appropriate for each forecast level.`,
      colTitle3: 'Hierarchical Forecasting',
      colText3: `Executions at any level of the temporal and structural hierarchy. It is<br /> 
                        possible, for instance, to prepare a forecast by region and then by client<br /> 
                        using the monthly and weekly temporal frequencies.`,
      colTitle4: 'How it Works',
      colText4: `Delfos operates as a managed service, using the historical database. <br />
                        The system identifies the correlation between the variables and develops<br /> 
                        customized forecast models that are appropriate for each scenario found.  
            `,
      colText5: `Apllos provides all the infrastructure required for<br /> 
                        executions, without the need for the company to incur other costs. `,
      colText6: `All you have to do is upload your data to obtain better results in your<br /> 
                        demand forecast process, using advanced artificial intelligence techniques.`
    }
  },

  contact: {
    title: 'Contact',
    text: 'it will be an honor to build something together'
  },

  talents: {
    title: 'Talent Pool',
    text: `Challenges and space for different profiles <br />in an environment of innovation and technology.`,
    check: `Check the vacancies in our`
  },

  sapIbp: {
    title: 'SAP Integrated<br /> Business Planning',
    text: `
            SAP IBP is a complete solution for integrated planning of<br /> the value chain. This platform was 
            built on a cloud database<br /> and memory using SAP HANA, which enables <br />real-time data processing.  
            `,
    text2: `
            Apllos stands out for its wide experience in the implementation of planning processes and APS systems, 
            in addition to a multidisciplinary team for development of interfaces on multiple platforms, data 
            analyses and definition of processes for co-creation of solutions that add value for customers.  
            `,
    text3: `SAP IBP is a complete solution for integrated planning of the value chain. It combines 
            sales and operational resources, demand, supplies planning, chain control tower and inventory optimization. 
            The platform is integrated through SAP HANA Cloud Integration with a variety of architectures and technologies, 
            enabling continuously updated data for use in planning activities.  
        `,
    text4: `
            Its interface was developed to operate on a web platform, Fiori and excel, which facilitates its usability 
            and iteration in business processes.  
        `,

    services1Title: 'Supply Chain Control Tower',
    services1Text: `
            A real-time supply chain visibility platform that supports decision making processes and quick corrective actions. <br /> 
            <strong>Key Benefits: </strong>
        `,
    services1List: {
      text1: `
                End-to-end visibility throughout the supply chain, including alerts, support to decision-making and quick corrective actions;  
            `,
      text2: `Reduction in global inventory levels; reduction in risks; `,
      text3: `Improved delivery time for customers;`,
      text4: `Collaboration, communication and monitoring of the supply chain;`,
      text5: `Improved agility in supply, and lower supply chain costs `
    },

    services2Title: 'IBP For Sales and Operations',
    services2Text: `
            IBP S&OP is a platform that enables a collaborative planning of demand, and the calculation of statistic forecasts, 
            using simple mathematical models. This tool uses flexible and unified data of the supply chain to support 
            tactical and strategic planning at any level of granularity and dimension.  
        `,
    services2Text2: `
            The use of IBP Demand is recommended for more advanced demand planning levels.  <br />
            <strong>Key Features</strong>
        `,
    services2List: {
      text1: `Collaborative planning of demand and simple statistic forecasts 
            `,
      text2: `Multi-level infinite planning             `,
      text3: `Manual adjustment of operations planning and its variables for analysis and simulation           `,
      text4: `Use of heuristics, taking into account: Transport lead time, current inventory, safety inventory, target inventory, 
                minimum batch, and rounding values, among other parameters  
            `,
      text5: `Both processes use ERP integrated information, allowing analysis and simulation of the full plan and delta.  
            `,
      text6: `The use of IBP Response and Supply is recommended to take into account the finite capacity of resources, 
                inventory restrictions, and other more advanced modeling.`
    },

    services3Title: 'IBP For Demand',
    services3Text: `Planning of short-, medium- and long-term statistic demand in a single solution. The platform uses data 
            processing, behavior classification, aggregation and disaggregation, as well as advanced mathematical models, 
            such as artificial intelligence and regression, to estimate the demand.  
        `,
    services3Text2: `
            The platform supports the creation of condensed demand through collaborative planning, making important 
            information available for analysis of the plan. <br />
            <strong>Key Features</strong>
        `,
    services3List: {
      text1: `Demand-sensing and medium-/long-term forecasts in a single demand planning solution;`,
      text2: `Automated, exceptions-based planning processes, and manual planning functions;`,
      text3: `Integrated and dynamic demand analysis;`,
      text4: `History cleanup and correction of outliers; `,
      text5: `Statistic forecasts based on advanced models available; `,
      text6: `Data aggregation/disaggregation – Time Series;`,
      text7: `Collaborative planning of demand; `,
      text8: `Alert generation;`,
      text9: `Use of Dashboards to support planning.`
    },

    services4Title: 'IBP For Inventory',
    services4Text: `
            The SAP IBP inventory uses a sophisticated optimization algorithm to define the best inventory policy by considering 
            the supply chain as multi-stage, cost, variabilities and service level.  
        `,
    services4Text2: `Below are some parameters used in the algorithm: 
        `,
    services4List: {
      text1: `Demand variability`,
      text2: `Forecast error`,
      text3: `Supply deviation `,
      text4: `Costs`,
      text5: `Lead times`,
      text6: `Supply chain links`,
      text7: `Positioning of inventories along the chain `
    },

    services5Title: 'IBP For Resp N Supply',
    services5Text: `
            SOP Response and Supply planning is the solution for planning the chain’s supply and allocation. 
            This tool includes different heuristic models, and optimizes the creation of scenarios and orders for 
            production, distribution and restocking planning.  
        `,
    services5Text2: `Key Features: `,
    services5List: {
      text1: `Optimization with objective-function for maximization of earnings or service level `,
      text2: `Heuristics`,
      text3: `Multi-level finite planning`,
      text4: `Cost-managed priorities`,
      text5: `Safety inventory and maximum inventory`,
      text6: `Late delivery costs, violation of safety inventory, inventory per unit, production costs, transport, per unit or fixed purchases, and others 
            `,
      text7: `Generation of supply orders (production, procurement, distribution), and integration to a new model of near real-time integration with ERP  `
    }
  },

  salesforce: {
    title: 'Salesforce',
    text: `
        The CRM Salesforce platform includes a customer management<br /> 
        and relationship software that is a worldwide leader in sales. 
        `,
    text2: `This tool manages issues that are crucial for business, such as the sales funnel, leads and the monitoring of tasks`,
    text3: `Salesforce whole infrastructure is cloud-based, which enables sellers to access information about their customers through any device connected to the Internet.`,
    text4: `Salesforce was created to facilitate development and provide speed and lower cost upon implementation. Its licenses allow its use by small, medium and large business.
        `,
    text5: `Salesforce includes several solutions for your business, the main ones being: Sales Cloud, Service Cloud, marketing cloud, among others.
        `,
    services1Title: 'Sales Cloud',
    services1Text: `This complete solution provides broad functionalities that enhance the competitiveness of the work performed by marketing and sales teams.
        `,
    services1Text2: `Benefits
        `,
    services1List: {
      text1: `360° view of the customer, generating trust, guaranteeing sales, and improving post-sale relationships;`,
      text2: `Enhances the engagement of sellers and partners by creating experiences that improve the sales process with clear and important information;`,
      text3: `Enables real-time monitoring of purchase decision moments of potential customers inside the organization;`,
      text4: `Makes the automation of actions and processes feasible;`,
      text5: `The tool allows controlling information about competitors, as well as agreements and deals.`
    },

    services2Title: 'Service Cloud',
    services2Text: `This solution was developed to fulfill the requests of your customers, products or services. This tool enables your company to retain customers and generate value for its brand. `,
    services2Text2: `Benefits
        `,
    services2List: {
      text1: `Improves the satisfaction of customers;`,
      text2: `Helps companies to optimize their processes and calls, by reducing the resolution of problems and drastically improving the satisfaction of customers;`,
      text3: `Case management, access to customers through channels, integration to legated systems, support ticket, knowledge base, routing and escalation, and management of customer assistance queues;`,
      text4: `Enables talking to customers through the channel of their preference, that is, by phone, SMS (text messages), WhatsApp or Facebook Messenger;`,
      text5: `Improves the company’s reputation and increases sales opportunities;`,
      text6: `Allows the definition of targets and follow-up of results.`
    },

    services3Title: 'Marketing Cloud',
    services3Text: `Platform for creation of significant and customized journeys for you target audience through the right channels and devices. `,
    services3Text2: `Benefits`,
    services3List: {
      text1: `Enables connecting data from different sources and devices to the platform, so as to get a unique vision of the customer. Additionally, you will be able to capture and activate primary, secondary and third-party data;`,
      text2: `Supplies data to the Salesforce artificial intelligence tool, called “Einstein”. The analyses conducted enable organized and customized interactions with customers;`,
      text3: `Generates attention and awareness during the whole process (a two-way engagement in real time), which also provides insights that enable offering the best action for each customer.`
    }
  },

  mulesoft: {
    title: 'MuleSoft',
    text: `
        Mule is a light and scalable execution time mechanism for APIs,<br /> 
        integrations and microservices. It is used with a<br /> hybrid platform 
        architecture – local, in the cloud or in both. 
        `,
    text2: `
        As a global leader in API integration and management solutions, the objective of MuleSoft’s 
        Anypoint Platform is to connect its applications and solve the most challenging connectivity 
        problems in SOA, SaaS and APIs.<br />Mulesoft is a simple platform intended for building APIs 
        and managing integration, whether in ERPs, MES or back-end applications. 
        `,
    text3: `Together, Salesforce and MuleSoft accelerate their digital transformation by 
        enabling them to open data in legated systems, cloud applications and devices, resulting in 
        faster and more intelligent decision-making processes, and creating experiences that are 
        highly differentiated and connected to consumers. 
        `,
    text4: `Anypoint Platform accelerates development speed by reusing APIs, connectors, templates, 
        examples and other integration assets previously built and published in Mulesoft’s Exchange. 
        `
  },

  delmiaQuintiq: {
    title: 'Delmia Quintiq',
    text: `DELMIA Quintiq’s supply chain planning software plans and optimizes<br /> 
						your supply chain from end to end, providing the control level and<br /> 
						the vision that you need for transforming the planning of your cost<br /> 
						center’ supply chain into a generator of revenues.`,
    text2: `Clearly define the KPIs of your whole organization and align each<br /> 
						business function to these objectives. Optimize each stage of your<br /> 
						supply chain – from long-term strategic planning to the details<br /> 
						that are critical at the execution point – in order to achieve<br /> 
						consistent, sustainable and high-level services, quality and efficiency. `,
    text3: `DELMIA Quintiq is the supply chain planning and optimization software (SCP & O) that provides the power of optimization. `,
    text4: `<strong>Integrated sales and operations planning</strong> - Advanced modeling and optimization resources that simulate any quantity of hypothetical scenarios, and recommend the best scenario to meet business KPIs and support effective decision making for delivering the maximum value.`,
    text5: `<strong>Master planning</strong> – Master planning – support global decisions with real-time viewing and predictive analyses for master planning, and programming and planning of materials requirements.`,
    text6: `<strong>Detailed production programming</strong> – reduce waiting times and inventories by optimizing production inside and between production lines, work cells and assembly operations, while enhancing the use of assets and providing support to the customers’ deadlines.`,
    text7: `<strong>Planning and optimization of logistic operations</strong> – managers, planners and forwarders gain total visibility and control over the whole planning and optimization process – from far-reaching network strategic planning to daily scheduling of the workforce and route planning.`,
    text8: `<strong>Planejamento e otimização das operações de trânsito</strong> – uma solução para lidar com todos os desafios - desde a programação da tripulação 
					e da frota até a conquista de novas licitações por meio de licitações abertas. A solução funciona para operações grandes e pequenas e se 
					integra perfeitamente a uma ampla gama de sistemas de terceiros.`,
    text9: `<strong>Planning and optimization of workforce operations</strong> – Built within a 100% appropriate business model that includes all exclusive restrictions of your organization, such as employees’ skills and qualifications, specific requirements of agreements, labor regulations and availability of funds. You define your efficiency, satisfaction and profitability targets; we qualify your planners with the tools and perceptions they need in order to boost important results.`,
    text10: `The DELMIA Quintiq software includes a web application that was developed with a new and modern interface focused on the user’s experience. This gives freedom and flexibility to users, who are able to access DELMIA Quintiq immediately in any web browser.`,
    text11: `https://www.3ds.com/products-services/delmia/products/delmia-quintiq/`
  },

  partners: {
    main: {
      title: `Our Partners`,
      text: `We work with an ecosystem of partners<br /> in order to seek innovative solutions<br /> for your business.`
    },
    dassault: {
      text: `Dassault Systèmes, a 3DEXPERIENCE company, supplies virtual universes to business and people for creation of sustainable innovations that contribute to improving processes and business practices. Its globally known solutions transform the way that products are developed, produced and managed. The collaborative solutions of Dassault Systèmes stimulate social innovation, and expand the possibilities of improvement of the real world through the virtual world. The company delivers value to over 210,000 customers of all sizes and sectors in more than 140 countries.`
    },
    sap: {
      text: `SAP is a world leader in the development of software for management of business processes, such as ECC, S4 Hana, IBP, SAC, creating solutions that streamline data processing and information flows among the organizations.<br /><br /> 
			Currently, SAP has over 230 million users in the cloud, more than 100 solutions that involve all business functions, and the largest cloud portfolio considering all providers. `
    },
    poweron: {
      text: `PowerOn allows your PowerBI to perform update and bookkeeping by integrating your dashboards to over 200 data bases. This enables the creation of collaborative plans, dynamic models with changes in parameters, and improves the usability of your platform. `
    },
    aimms: {
      text: `AIMMS supplies a modeling and optimization platform that is being used in different functions of basically all types of business. It is an excellent option for teams that are ready to leave behind worksheets or old network project, demand forecasts and S&OP/IBP tools. The AIMMS SC Navigator offers ready-for-use cloud applications that provide the benefits of sophisticated analyses that do not require analytical experience, including world-class solvers (and solver links) for linear, full, mixed and non-linear programming such as baron, cplex, conopt, gurobi, knitro, path, snopt and xa. Additionally, concepts such as “stochastic programming” and “robust optimization” are available in order to include the uncertainty of data in the models. 
      `
    },
    asprova: {
      text: `This is an APS (Advanced Planning & Scheduling) System used in programming high-speed production for multiple items and processes with the integration of sales, manufacturing, inventory and procurement areas. Asprova was originally developed in Japan, where it is the most used production programming tool, with a 52.4% market share. (According to the 2011 Report of Techno Systems Research Co.,Ltd.)`
    }
  },

  asprova: {
    text: `Asprova works as an easy Excel worksheet, even with large<br /> amounts of data, it provides facilitated maintenance.`,
    text2: `Asprova was originally developed in Japan, where it is the most used production scheduling tool, with a 52.4% market share (according to “Techno Systems Research Co., Ltd. Report 2011”).`,
    text3: `Asprova is an APS System (Advanced Planning & Scheduling) for high-speed production scheduling involving multiple items and processes, and integration with sales, manufacturing, inventory and procurement. This tool has several functionalities for production scheduling, and it facilitates customization without the complexity of new codes.`,
    text4: `This application works with two types of data viewing: a worksheet that can be easily edited and graphic viewing. It makes possible to view resources, production orders, inventory, procurement and sales. Schedules can be organized in different periods of time, such as daily, weekly and monthly, and they are also flexible for customization by users. This application can be integrated to different ERPs, such as SAP and Oracle.`,
    features: {
      title: `<strong>Some functionalities</strong>`,
      text: `Sales: Link sales information to the plant’s production planning. `,
      text2: `Procurement: Link production planning to procurement information. `,
      text3: `KPIs: Assess several KPIs in each project.`,
      text4: `MAX time restriction: Adjust pre-defined times between processes.`,
      text5: `Blocking of resources: Block a resource temporarily after production.`,
      text6: `Event: Define the conditions and generate events. `,
      text7: `Optimization: sequencing of work for all resources.`,
      text8: `Scenarios: support for creation of multiple scenarios.`,
      text9: `Resource restrictions: choose line overloading and delayed deliveries instantly by using tables and charts.`
    }
  }
}
