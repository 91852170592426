import React from 'react'
import parse from 'html-react-parser'

import { Row, Section } from './styles'
import { Container } from '../../components/Layout/styles'
import Header from '../../components/Header'
import { Title } from '../../components/Title/styles.js'
import Footer from '../../components/Footer'
import FormContact from '../../components/FormContact'

function BancoDeTalentos({ lang, handleLang }) {
  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <Section className='contato'>
        <img
          src='./assets/images/digitalSupplyChain/graphSupplyChainPage.svg'
          className='graph'
          alt=''
          // data-aos="fade-down-left"
          // data-aos-duration="1500"
        />
        <Container>
          <Row className='row'>
            <div className='col'>
              <div className='text'>
                <Title>{lang.talents.title}</Title>
                <p>{parse(lang.talents.text)}</p>
                <div className='links'>
                  <h3>{parse(lang.talents.check)}</h3>
                  <a
                    href='https://www.linkedin.com/company/apllos/'
                    target='.blank'
                    rel='noopener'
                  >
                    <img
                      src={'./assets/images/apllos/linkedin.svg'}
                      alt='LinkedIn'
                    />
                    LinkedIn
                  </a>
                </div>
              </div>
            </div>
            <div className='col col-form'>
              <div className='form'>
                <FormContact
                  type='bancoDeTalentos'
                  lang={lang}
                  title={lang.formContact.title2}
                />
              </div>
            </div>
          </Row>
        </Container>
      </Section>
      <Section className='mobileForm'>
        <Container>
          <Row>
            <FormContact
              type='bancoDeTalentos'
              lang={lang}
              title={lang.formContact.title2}
            />
          </Row>
        </Container>
      </Section>

      <Footer lang={lang} />
    </>
  )
}

export default BancoDeTalentos
