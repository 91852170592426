import React, { useState } from 'react'
import parse from 'html-react-parser'
import { Container } from '../../components/Layout/styles'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Row, Section, Col } from './styles'
import FormContact from '../../components/FormContact'
import CollapseComponent from '../../components/Collapse'
import Video from '../../components/Video'

function APOHealthCheck({ lang, handleLang }) {
  const [collapse, setCollapse] = useState({
    col1: false,
    col2: false,
    col3: false,
    col4: false,
    col5: false,
    col6: false
  })

  const handleClick = (type) => {
    let newCollapse = { ...collapse }

    newCollapse.col1 = type === 'col1' && !newCollapse.col1 ? true : false
    newCollapse.col2 = type === 'col2' && !newCollapse.col2 ? true : false
    newCollapse.col3 = type === 'col3' && !newCollapse.col3 ? true : false
    newCollapse.col4 = type === 'col4' && !newCollapse.col4 ? true : false
    newCollapse.col5 = type === 'col5' && !newCollapse.col5 ? true : false
    newCollapse.col6 = type === 'col6' && !newCollapse.col6 ? true : false

    setCollapse(newCollapse)
  }

  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <Section className='banner'>
        {/* data-aos="fade-left" data-aos-duration="1500" */}
        <img
          src='./assets/images/apoHealthCheck/apo.png'
          className='screen'
          data-aos='fade'
          data-aos-duration='1500'
          alt=''
        />
        <Container>
          <Row>
            <Col>
              <h1>{parse(lang.apoHealthCheck.banner.title)}</h1>
              <p>{parse(lang.apoHealthCheck.banner.text)}</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className='col inicio'>
            <Col>
              <h2>{lang.apoHealthCheck.section.title}</h2>
            </Col>
            <Col>
              <p>{parse(lang.apoHealthCheck.section.text)}</p>
              <p>{lang.apoHealthCheck.section.text2}</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className='col col-monitor'>
            <Col>
              <h2>{lang.apoHealthCheck.monitor.title}</h2>
            </Col>
            <Col>
              <CollapseComponent
                collapsed={collapse.col1}
                collapseName={'col1'}
                title={lang.apoHealthCheck.monitor.collapse1Title}
                handleClick={handleClick}
                content={parse(lang.apoHealthCheck.monitor.collapse1Text)}
              />

              <CollapseComponent
                collapsed={collapse.col2}
                collapseName={'col2'}
                title={lang.apoHealthCheck.monitor.collapse2Title}
                handleClick={handleClick}
                content={parse(lang.apoHealthCheck.monitor.collapse2Text)}
              />

              <CollapseComponent
                collapsed={collapse.col3}
                collapseName={'col3'}
                title={lang.apoHealthCheck.monitor.collapse3Title}
                handleClick={handleClick}
                content={parse(lang.apoHealthCheck.monitor.collapse3Text)}
              />

              <CollapseComponent
                collapsed={collapse.col4}
                collapseName={'col4'}
                title={lang.apoHealthCheck.monitor.collapse4Title}
                handleClick={handleClick}
                content={parse(lang.apoHealthCheck.monitor.collapse4Text)}
              />

              <CollapseComponent
                collapsed={collapse.col5}
                collapseName={'col5'}
                title={lang.apoHealthCheck.monitor.collapse5Title}
                handleClick={handleClick}
                content={parse(lang.apoHealthCheck.monitor.collapse5Text)}
              />

              <CollapseComponent
                collapsed={collapse.col6}
                collapseName={'col6'}
                title={lang.apoHealthCheck.monitor.collapse6Title}
                handleClick={handleClick}
                content={parse(lang.apoHealthCheck.monitor.collapse6Text)}
              />
            </Col>
          </Row>
        </Container>
      </Section>

      <Video iframe='https://www.youtube.com/embed/zj97Gqyq-WA' />

      <Section className='form'>
        <Container>
          <Row className=''>
            <Col>
              <div className='title'>
                <h1>{lang.apoHealthCheck.form.title}</h1>
                <p>{lang.apoHealthCheck.form.paragraph}</p>
              </div>
            </Col>
            <Col className='formContact'>
              <FormContact
                type='apoHealthCheck'
                lang={lang}
              />
            </Col>
          </Row>
        </Container>
      </Section>
      <Footer lang={lang} />
    </>
  )
}

export default APOHealthCheck
