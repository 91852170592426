import React from 'react'

import { Loader as LoaderSpin } from './styles'

export default function Loader({ visible }) {
    return (
        <LoaderSpin visible={visible}>
            <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
        </LoaderSpin>
    )
}