import styled from 'styled-components'

export const Container = styled.div`
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    justify-content: center;
`

export const Row = styled.div`
    width: 90%;
`