import React from 'react'
import parse from 'html-react-parser'

import { Row, Section } from './styles'
import { Container } from '../../components/Layout/styles'
import Header from '../../components/Header'
import { Title } from '../../components/Title/styles.js'
import Footer from '../../components/Footer'
import FormContact from '../../components/FormContact'

import Hire from '../../components/Hire'

function Contato({ lang, handleLang }) {
  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <Section className='contato'>
        <img
          src='./assets/images/digitalSupplyChain/graphSupplyChainPage.svg'
          className='graph'
          alt=''
          // data-aos="fade-down-left"
          // data-aos-duration="1500"
        />
        <img
          src={'./assets/images/home/polygon.png'}
          className='polygon animate__animated animate__fadeInRight'
          alt=''
          data-aos='flip-up'
          data-aos-duration='1500'
        />
        <div className='oval-div'>
          <img
            src={'./assets/images/digitalSupplyChain/ovalDark.svg'}
            className='oval'
            alt=''
            data-aos='fade-up'
            data-aos-duration='1500'
          />
          <img
            src={'./assets/images/digitalSupplyChain/ovalMedium.svg'}
            className='oval'
            alt=''
            data-aos='fade-up'
            data-aos-duration='2000'
          />
          <img
            src={'./assets/images/digitalSupplyChain/oval.svg'}
            className='oval'
            alt=''
            data-aos='fade-up'
            data-aos-duration='2500'
          />
        </div>
        <Container>
          <Row className='row'>
            <div className='col'>
              <div className='text'>
                <Title>{parse(lang.contact.title)}</Title>
                <h3>{parse(lang.contact.text)}</h3>
                <p>
                  Av. Queiroz Filho, 1700 CJ. D-9
                  <br /> Vila Leopoldina | São Paulo-SP
                </p>
                <p>contato@apllos.com</p>
              </div>
            </div>
            <div className='col col-form'>
              <div className='form'>
                <FormContact
                  type='contact'
                  lang={lang}
                />
              </div>
            </div>
          </Row>
        </Container>
      </Section>

      <Section className='mobileForm'>
        <Container>
          <Row>
            <FormContact
              type='contact'
              lang={lang}
            />
          </Row>
        </Container>
      </Section>

      <Hire lang={lang} />

      <Footer lang={lang} />
    </>
  )
}

export default Contato
