import styled from 'styled-components'

import { Row as RowColumn } from '../../components/Layout/styles'

export const Row = styled(RowColumn)`
  /* display: flex; */
  /* align-items: center; */
  &.col {
    display: flex;
    &.inicio {
      h2 {
        margin-right: 170px;
      }
    }
    h2 {
      margin-right: 100px;
    }
  }

  @media screen and (max-width: 576px) {
    &.col {
      flex-direction: column;
      h2 {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
`

export const Section = styled.section`
  display: flex;
  margin: 90px 0 0;
  &.banner {
    margin: 0 auto;
    height: 737px;
    align-items: center;
    background: url('./assets/images/apoHealthCheck/banner.jpg') no-repeat top
      center;
    background-size: cover;
    overflow-x: hidden;
    h1 {
      color: var(--colorPrimary);
      font-size: 42px;
      font-weight: 500;
      margin-bottom: 55px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: -26px;
        left: 0;
        width: 990px;
        border-bottom: 2px solid var(--colorPrimary);
        z-index: 1;
      }
    }
    p {
      line-height: 1.6;
    }

    .screen {
      position: absolute;
      right: 0;
      top: 17%;
      /* width: 60%; */
      z-index: 2;
    }
  }
  p {
    font-size: 16px;
    color: var(--textPags);
    line-height: 1.6;
  }
  &.form {
    background: url('./assets/images/contato/bgContato.jpg') no-repeat top
      center;
    height: 737px;
    background-size: cover;
    /* padding: 86px ​0 !important; */
    padding: 86px 0;
    .title {
      margin-bottom: 50px;
      h1 {
        font-size: 40px;
        color: var(--colorPrimary);
        font-weight: 600;
      }
    }
    .formContact {
      width: 54%;
      .row-form {
        padding: 38px 103px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &.banner {
      .screen {
        width: 596px;
        top: 25%;
      }

      p {
        br {
          display: block;
        }
      }
    }

    p {
      br {
        display: none;
      }
    }

    .col-monitor {
      h2 {
        padding-top: 35px;
      }
    }
  }

  @media screen and (max-width: 990px) {
    &.banner {
      .screen {
        width: 555px;
        top: 25%;
      }
    }

    &.form {
      .title {
        text-align: center;
      }
      .formContact {
        margin: 0 auto;
        .row-form {
          padding: 38px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &.banner {
      flex-direction: column;
      height: auto;
      padding: 131px 0px 67px;
      align-items: flex-end;
      .screen {
        position: relative;
        width: 96%;
        margin-bottom: 40px;
      }
      h1 {
        font-size: 30px;
        &:before {
          width: 100%;
        }
      }
    }
    h2 {
      font-size: 20px;
    }

    p {
      font-size: 15px;
      br {
        display: none !important;
      }
    }

    &.form {
      .formContact {
        width: 90%;
      }
    }
  }

  @media screen and (max-width: 576px) {
    &.form {
      .title {
        h1 {
          font-size: 24px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
`

export const Col = styled.div`
  /* display: flex;
    flex-direction: column; */
  p {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
