import React from 'react'
import parse from 'html-react-parser'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Tags } from '../../components/Tags/styles'
import { Title, Text, H2Text } from '../../components/Title/styles.js'
import { Container } from '../../components/Layout/styles'
import { Row, Section, Col } from './styles'
import Video from '../../components/Video'

function DigitalSupplyChain({ lang, handleLang }) {
  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <Section className='banner'>
        <img
          src={'./assets/images/digitalSupplyChain/graphSupplyChainPage.svg'}
          className='graph'
          data-aos='fade-down-left'
          data-aos-duration='1500'
          alt=''
        />
        <img
          src={'./assets/images/digitalSupplyChain/points.svg'}
          className='points'
          alt=''
          data-aos='flip-left'
          data-aos-easing='ease-out-cubic'
          data-aos-duration='1500'
        />
        <div className='oval-div'>
          <img
            src={'./assets/images/digitalSupplyChain/oval.svg'}
            className='oval'
            data-aos='fade-right'
            data-aos-duration='2000'
            alt=''
          />
          <img
            src={'./assets/images/digitalSupplyChain/ovalMedium.svg'}
            className='oval'
            data-aos='fade-right'
            data-aos-duration='2500'
            alt=''
          />
          <img
            src={'./assets/images/digitalSupplyChain/ovalDark.svg'}
            className='oval'
            data-aos='fade-right'
            data-aos-duration='3000'
            alt=''
          />
        </div>
        <Container>
          <Row>
            <Col>
              <Title>{parse(lang.digitalSupplyChain.banner.title)}</Title>
              <Text>{parse(lang.digitalSupplyChain.banner.text)}</Text>
              <Tags>
                <span>{parse(lang.digitalSupplyChain.banner.tag1)}</span>
                <span>{parse(lang.digitalSupplyChain.banner.tag2)}</span>
                <span>{parse(lang.digitalSupplyChain.banner.tag3)}</span>
                <span>{parse(lang.digitalSupplyChain.banner.tag4)}</span>
                <span>{parse(lang.digitalSupplyChain.banner.tag5)}</span>
                <span>{parse(lang.digitalSupplyChain.banner.tag6)}</span>
                <span>{parse(lang.digitalSupplyChain.banner.tag7)}</span>
                <span>{parse(lang.digitalSupplyChain.banner.tag8)}</span>
                <br />
                <span>{parse(lang.digitalSupplyChain.banner.tag9)}</span>
              </Tags>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <H2Text>{parse(lang.digitalSupplyChain.section.title1)}</H2Text>
            <H2Text>{parse(lang.digitalSupplyChain.section.title2)}</H2Text>

            <H2Text
              className='purple'
              data-aos='fade-up'
              data-aos-duration='1200'
            >
              {parse(lang.digitalSupplyChain.section.title3)}
            </H2Text>

            <H2Text data-aos='fade-up' data-aos-duration='1200'>
              {parse(lang.digitalSupplyChain.section.title4)}
            </H2Text>
            <H2Text data-aos='fade-up' data-aos-duration='1200'>
              {parse(lang.digitalSupplyChain.section.title5)}
            </H2Text>

            <H2Text
              className='purple'
              data-aos='fade-up'
              data-aos-duration='1200'
            >
              {parse(lang.digitalSupplyChain.section.title6)}
            </H2Text>
            <H2Text
              className='gray'
              data-aos='fade-up'
              data-aos-duration='1200'
            >
              {parse(lang.digitalSupplyChain.section.title7)}
            </H2Text>
          </Row>
        </Container>
      </Section>
      <Video iframe='https://www.youtube.com/embed/MyhfxeRF7PY' />
      <Footer lang={lang} />
    </>
  )
}

export default DigitalSupplyChain
