import styled from 'styled-components'
import { Row as RowColumn } from '../../components/Layout/styles'
import { Container } from '../../components/Layout/styles'

export const Row = styled(RowColumn)`
  &.col {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-weight: 600;
      font-size: 42px;
      margin-bottom: 16px;
      color: var(--colorPrimary);
    }
    p {
      color: var(--colorText);
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }

  &.row {
    flex-direction: row;
  }

  @media screen and (max-width: 576px) {
    &:first-child {
      margin-top: 88px;
    }
    &:last-child {
      margin-bottom: 48px;
    }
  }
`
export const Section = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 10%;

  @media screen and (max-width: 990) {}

  @media screen and (max-width: 768px) {}
`

export const Col = styled.div`
  &.content-image {
    margin-top: 32px;
    width: 488px;
    & > img {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      margin-top: 64px;
    }
  }
`

export const DataImagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 32px;

  & > img {
    &.aws {
      width: 92px;
    }
    &.google-cloud {
      width: 310px;
    }
    &.azure {
      width: 195px;
    }
    &.databricks {
      width: 100px;
    }
  }

  @media screen and (max-width: 990px) {
    & > img {
      &.aws {
        width: 69px;
      }
      &.google-cloud {
        width: 233px;
      }
      &.azure {
        width: 147px;
      }
      &.databricks {
        width: 75px;
      }
    } 
  }

  @media screen and (max-width: 680px) {
    flex-direction: column;
    margin: 0;
    & > img {
      &.aws {
        width: 92px;
      }
      &.google-cloud {
        width: 310px;
        position: relative;
        bottom: 44px;
      }
      &.azure {
        width: 195px;
        position: relative;
        bottom: 94px;
      }
      &.databricks {
        width: 150px;
        position: relative;
        bottom: 60px;
      }
    } 
  }

`;
