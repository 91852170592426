import styled from 'styled-components'

export const FooterTag = styled.footer`
  border-top: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 0 85px;
  margin-top: 65px;
  height: min(80vh, 500px);

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    .apllos {
      display: flex;
      justify-content: center;
      margin-right: 85px;
      .logoApllos {
        background: url('./assets/images/logo.svg') no-repeat;
        width: 78px;
        height: 78px;
      }
    }
    .social-media {
      display: flex;
    }
  }

  .institucional {
    /* display: flex;
        align-items: center;
        flex-direction: column; */
    &.social {
      display: none;
    }
    .seloGptw {
      background: url('./assets/images/footer/gptw2023.png') no-repeat;
      width: 191px;
      height: 222px;
      background-size: contain;
      background-position: center;
    }

    &.technology {
      padding-top: 108px;
    }
  }

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    li {
      line-height: 2.3;
      a {
        font-size: 14px;
        text-decoration: none;
        color: #000;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: 990px) {
    .institucional {
      &.services,
      &.products,
      &.apllos,
      &.technology {
        display: none;
      }

      &.social {
        display: block;
      }
    }

    .logo {
      .apllos {
        margin-right: 0;
      }
      .social-media {
        display: none;
      }
    }
  }
`
