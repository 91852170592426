import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import parse from 'html-react-parser'

import Routes from '../../routes'
import Header from '../../components/Header'
import { Button } from '../../components/Button/styles'
import Footer from '../../components/Footer'
import FormContact from '../../components/FormContact'
import { Row, Container, Section, SectionAboutUs } from './styles'
import { anchorScroll } from '../../utils/anchorScroll'
import Slider from 'react-slick'
import Banner from './Banner'

function Home({ lang, handleLang }) {
  const location = useLocation()

  useEffect(() => {
    if (location && location.state) {
      const section = location.state.section
      if (section) {
        anchorScroll(section)
      }
    }
  })

  const Arrow = ({ arrow, onClick, className }) => (
    <button className={`buttonArrow ${arrow} ${className}`} onClick={onClick}>
      <span></span>
    </button>
  )

  const SliderTech = () => {
    const settings = {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2.2,
      slidesToScroll: 1,
      nextArrow: <Arrow arrow='arrowRight' />,
      prevArrow: <Arrow arrow='arrowLeft' />
    }

    return (
      <Slider {...settings}>
        <div className='col'>
          <Link to={Routes.delmiaQuintiq}>
            <span>APS</span>
            <h1>Delmia Quintiq</h1>
            <img src='./assets/images/home/delmia.png' alt='Delmia Quintiq' />
          </Link>
        </div>

        <div className='col'>
          <Link to={Routes.sapIbp}>
            <span>APS</span>
            <h1>SAP IBP</h1>
            <img src='./assets/images/home/sapIbp.png' alt='SAP IBP' />
          </Link>
        </div>
        <div className='col'>
          <Link to={Routes.salesforce}>
            <span>CRM</span>
            <h1>Salesforce</h1>
            <img src='./assets/images/home/salesforce.png' alt='Salesforce' />
          </Link>
        </div>
        <div className='col'>
          <Link to={Routes.mulesoft}>
            <span>API</span>
            <h1>Mulesoft</h1>
            <img src='./assets/images/home/mulesoft.png' alt='Mulesoft' />
          </Link>
        </div>
        <div className='col'>
          <Link to={Routes.asprova}>
            <span>Schedule</span>
            <h1>Asprova</h1>
            <img src='./assets/images/home/asprova.png' alt='Asprova' />
          </Link>
        </div>
      </Slider>
    )
  }

  const SliderTechMobile = () => (
    <div className='sliderTechMobile'>
      <div className='col'>
        <Link to={Routes.delmiaQuintiq}>
          <span>APS</span>
          <h1>Delmia Quintiq</h1>
          <img src='./assets/images/home/delmia.png' alt='Delmia Quintiq' />
        </Link>
      </div>
      <div className='col'>
        <Link to={Routes.sapIbp}>
          <span>APS</span>
          <h1>SAP IBP</h1>
          <img src='./assets/images/home/sapIbp.png' alt='SAP IBP' />
        </Link>
      </div>
      <div className='col'>
        <Link to={Routes.salesforce}>
          <span>CRM</span>
          <h1>Salesforce</h1>
          <img src='./assets/images/home/salesforce.png' alt='Salesforce' />
        </Link>
      </div>
      <div className='col'>
        <Link to={Routes.mulesoft}>
          <span>API</span>
          <h1>Mulesoft</h1>
          <img src='./assets/images/home/mulesoft.png' alt='Mulesoft' />
        </Link>
      </div>
      <div className='col'>
        <Link to={Routes.asprova}>
          <span>Schedule</span>
          <h1>Asprova</h1>
          <img src='./assets/images/home/asprova.png' alt='Asprova' />
        </Link>
      </div>
    </div>
  )

  return (
    <>
      <Header id='header' home={true} lang={lang} handleLang={handleLang} />

      <Banner lang={lang} />

      <Section height='90vh' id='services'>
        <Container>
          <Row>
            <div className='title'>
              <span>{parse(lang.home.services.subtitle)}</span>
              <h1>{parse(lang.home.services.title)}</h1>
            </div>
            <div className='services'>
              <div className='col image'>
                <div className='servicesGraph' data-aos='fade-right'></div>
              </div>
              <div className="services-container">
                <div className="services-line">
                  <div className='col experience'>
                    <div className='logo'>
                      <div className='laptopIcon'></div>
                    </div>
                    <div className='text'>
                      <h2>{parse(lang.home.services.service1Title)}</h2>
                      <p>{parse(lang.home.services.service1Text)}</p>
                      <Link to={Routes.experienciaDigital}>
                        <Button>
                          <span>{parse(lang.home.services.button)}</span>
                          <span className='arrow'></span>
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className='col experience'>
                    <div className='logo'>
                      <div className='supplyIcon'></div>
                    </div>
                    <div className='text'>
                      <h2>{parse(lang.home.services.service2Title)}</h2>
                      <p>{parse(lang.home.services.service2Text)}</p>
                      <Link to={Routes.digitalSupplyChain}>
                        <Button>
                          <span>{parse(lang.home.services.button)}</span>
                          <span className='arrow'></span>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="services-line">
                <div className='col experience'>
                  <div className='logo'>
                      <div className='dataIcon'></div>
                    </div>
                    <div className='text'>
                    <h2>{parse(lang.home.services.service3Title)}</h2>
                      <p>{parse(lang.home.services.service3Text)}</p>
                      <Link to={Routes.dados}>
                        <Button>
                          <span>{parse(lang.home.services.button)}</span>
                          <span className='arrow'></span>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </Section>

      <SectionAboutUs data-aos='fade-up' data-aos-duration='1200'>
        <Container>
          <Row>
            <div className='text'>
              <div className='symbolApllos'></div>
              <h1>
                <strong>
                  <img
                    className='aspas'
                    src='./assets/images/home/aspas.svg'
                    alt=''
                  />
                  Απλος
                </strong>
                (Apllos) - {parse(lang.home.apllos.title)}
              </h1>
              <p>{parse(lang.home.apllos.text)}</p>
              <Link to='/apllos'>
                <Button margin='0 auto'>
                  <span>{parse(lang.home.apllos.button)}</span>
                  <span className='arrow'></span>
                </Button>
              </Link>
            </div>
          </Row>
        </Container>
      </SectionAboutUs>

      <Section
        height='100vh'
        id='products'
        data-aos='fade-up'
        data-aos-duration='1200'
      >
        <Container>
          <Row>
            <div className='title'>
              <span>{parse(lang.home.products.subtitle)}</span>
              <h1>{parse(lang.home.products.title)}</h1>
            </div>
            <div className='product'>
              <div className='col'>
                <Link to={Routes.apoHealthCheck}>
                  <span>{parse(lang.home.products.product1Title)}</span>
                  <h1>{parse(lang.home.products.product1Text)}</h1>
                  <img
                    src='./assets/images/apoHealthCheck/apo2.jpg'
                    alt={parse(lang.home.products.product2Text)}
                  />
                </Link>
              </div>
              <div className='col'>
                <Link to={Routes.delfos}>
                  <span>{parse(lang.home.products.product2Title)}</span>
                  <h1>{parse(lang.home.products.product2Text)}</h1>
                  <img
                    src='./assets/images/home/portal.png'
                    alt={parse(lang.home.products.product2Text)}
                  />
                </Link>
              </div>
            </div>
          </Row>
        </Container>
      </Section>

      <Section
        height='100vh'
        id='technology'
        className='section technology'
        data-aos='fade-up'
        data-aos-duration='1200'
      >
        <Container>
          <Row>
            <div className='title'>
              <span>{parse(lang.home.technology.subtitle)}</span>
              <h1>{parse(lang.home.technology.title)}</h1>
              <h4>{parse(lang.home.technology.text)}</h4>
            </div>

            <div className='product'>
              <SliderTech />
            </div>
          </Row>
        </Container>
        <SliderTechMobile />
      </Section>

      <Section
        height='100vh'
        className='clientsSection'
        data-aos='fade-up'
        data-aos-duration='1200'
      >
        <Container>
          <Row className='clients'>
            <div className='title'>
              <span>{parse(lang.home.clients.subtitle)}</span>
              <h1>{parse(lang.home.clients.title)}</h1>
            </div>
            <div className='clients'>
              <div className='row'>
                <div className='client'>
                  <div className='clientDiv fadeInOut gerdau'></div>
                  <div className='clientDiv fadeIn takeda'></div>
                </div>
                <div className='client'>
                  <div className='clientDiv fadeInOut abInbev'></div>
                  <div className='clientDiv fadeIn mars'></div>
                </div>
                <div className='client'>
                  <div className='clientDiv fadeInOut suzano'></div>
                  <div className='clientDiv fadeIn cba'></div>
                </div>
                <div className='client'>
                  <div className='clientDiv fadeInOut braskem'></div>
                  <div className='clientDiv fadeIn jde'></div>
                </div>
                <div className='client'>
                  <div className='clientDiv fadeInOut ameDigital'></div>
                  <div className='clientDiv fadeIn grupoPetropolis'></div>
                </div>
              </div>
              <div className='row'>
                <div className='client'>
                  <div className='clientDiv fadeInOut heineken'></div>
                  <div className='clientDiv fadeIn boticario'></div>
                </div>
                <div className='client'>
                  <div className='clientDiv fadeInOut natura'></div>
                  <div className='clientDiv fadeIn duratex'></div>
                </div>
                <div className='client'>
                  <div className='clientDiv fadeInOut johnsonJohnson'></div>
                  <div className='clientDiv fadeIn abBrasil'></div>
                </div>
                <div className='client'>
                  <div className='clientDiv fadeInOut mondelez'></div>
                  <div className='clientDiv fadeIn vallourec'></div>
                </div>
                <div className='client'>
                  <div className='clientDiv fadeInOut nufarm'></div>
                  <div className='clientDiv fadeIn vifor'></div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </Section>

      <Section className='contactUs' height='100vh'>
        <Container>
          <Row className='flex'>
            <div className='col'>
              <div className='title contact'>
                <span>{parse(lang.home.contactUs.subtitle)}</span>
                <h1>{parse(lang.home.contactUs.title)}</h1>
              </div>
              <div className='text contact'>
                <p>{parse(lang.home.contactUs.text)}</p>
                <p>{parse(lang.home.contactUs.text2)}</p>
              </div>
            </div>
            <div className='col'>
              <FormContact
                type='contact'
                lang={lang}
                title={lang.formContact.titleHome}
              />
            </div>
          </Row>
        </Container>
      </Section>

      <Footer lang={lang} />
    </>
  )
}

export default Home
