import React from 'react'

import Collapse from "@kunukn/react-collapse"
import { Collapse as CollapseDiv } from './styles'

function CollapseComponent({ collapsed, handleClick, collapseName, content, title }){
    return (
        <CollapseDiv
            className={collapsed ? 'collapsed' : ''}
        >
            <button
                className={`app__toggle  ${collapsed ? "app__toggle--active" : ""}`}
                onClick={() => handleClick(collapseName)}
            >
                <span className="app__toggle-text">{ title }</span>
                <div className="rotate90">
                    <svg
                        className={`icon  ${collapsed ? "icon--expanded" : ""}`}
                        viewBox="6 0 12 24"
                        fill="#5c1f75"
                    >
                        <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                    </svg>
                </div>
            </button>
            <Collapse
                isOpen={collapsed}
                className={`app__collapse app__collapse--gradient ${collapsed ? "app__collapse--active" : ""}`}
                >
                <div className="app__content">
                    {content}
                </div>
            </Collapse>
        </CollapseDiv>
    )
}

export default CollapseComponent