import styled from 'styled-components'

import { Row as RowColumn } from '../../components/Layout/styles'

export const Row = styled(RowColumn)`
  display: flex;
  &.column {
    flex-direction: column;
  }
  &.main-row {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 992px) {
    &.main-row {
      flex-direction: column;
    }

    &.stepsRow {
      flex-direction: column;
      align-items: center;
    }
  }
`

export const Section = styled.section`
  display: flex;
  align-items: center;
  &.main {
    height: 100vh;
  }

  &.design-thinking {
    margin-bottom: 15vh;
    margin-top: 27px;
  }

  &.steps {
    margin-bottom: 15vh;
  }

  @media screen and (max-width: 1216px) {
    &.main {
      p {
        br {
          display: none;
        }
      }
    }
  }

  @media (max-width: 992px) {
    &.main {
      height: auto;
    }

    &.design-thinking {
      h2 {
        font-size: 14px;
        br {
          display: none;
        }
      }
    }

    &.steps {
      flex-direction: column;
      .steps {
        flex-direction: column;
        width: 85%;
        h2 {
          font-size: 20px;
        }
        &:not(:last-child) {
          margin-bottom: 46px;
        }
        p {
          &:last-child {
            margin-bottom: 0;
          }

          br {
            /* display: none; */
          }
        }

        .step-info {
          padding: 0 0 0 37px;
          &:before {
            left: 27px;
            height: 100%;
          }
          .steps-name {
            &:not(:last-child) {
              margin-bottom: 40px;
            }
          }
        }

        .step-number {
          margin-bottom: 25px;
          h2 {
            span {
              margin-left: 0;
              display: block;
            }
          }
        }
      }
    }
  }
`

export const Col = styled.div`
  width: 50%;
  &.main-column {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.col-particle {
      background: #fdfdfd;
      position: relative;
      .wrapColumn {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        #tsparticles {
          width: 100%;
          height: 100%;
        }
      }
    }

    .text {
      width: 74%;
    }
  }
  &.steps {
    display: flex;
    h2 {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 11px;
    }
    p {
      font-size: 14px;
      line-height: 1.5;
      color: var(--textPags);
      margin-bottom: 24px;
    }
    .step-info {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: -14px;
        top: -5px;
        height: 77%;
        border-left: 1px solid #000;
      }
      &.step2 {
        &:before {
          height: 100%;
        }
      }
      .steps-name {
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: -54px;
        }
      }
    }
    .step-number {
      margin-right: 74px;
      h2 {
        font-size: 40px;
        span {
          font-size: 16px;
          font-weight: 400;
          margin-left: -16px;
        }
      }
    }
  }

  /* @media screen and (max-width: 992px) {
        &.main-column {
            padding: 78px 0 0;
        }
    } */

  @media (max-width: 992px) {
    &.main-column {
      padding: 26px 0;

      &.col-particle {
        order: -1;
        padding: 0;
      }

      .text {
        width: 90%;
      }
    }
  }
`
