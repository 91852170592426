import styled from 'styled-components'

import { Row as RowColumn } from '../../components/Layout/styles'

export const Row = styled(RowColumn)``

export const Section = styled.section`
  display: flex;
  margin: 40px 0 0;
  &.banner {
    margin: 0 auto;
    height: 737px;
    align-items: center;
    background: url('./assets/images/salesforce/banner.png') no-repeat top
      center;
    background-size: cover;
    overflow-x: hidden;
    h1 {
      color: var(--colorPrimary);
      font-size: 42px;
      font-weight: 500;
      margin-bottom: 55px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: -26px;
        left: 0;
        width: 990px;
        border-bottom: 2px solid var(--colorPrimary);
        z-index: 1;
      }
    }
    p {
      line-height: 1.6;
    }

    /* .screen {
            position: absolute;
            right: 0;
            top: 25%;
            width: 50%;
            z-index: 2;
        } */

    .screen {
      position: absolute;
      right: 0;
      top: 25%;
      width: 50%;
      z-index: 2;
      border-top-left-radius: 21px;
      border-bottom-left-radius: 21px;
      box-shadow: 1px 4px 4px 0px #9090905e;
    }

    @media screen and (min-width: 1556px) {
      .screen {
        width: auto !important;
        top: 17%;
      }
    }
  }
  &.delmia {
    h2 {
      color: var(--textPags);
      font-size: 17px;
      font-weight: normal;
      margin-bottom: 51px;

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        color: var(--colorPrimary);
      }
    }

    a {
      color: var(--colorPrimary);
    }

    .fluxoImage {
      margin: -16px auto 50px;
      display: block;
      width: 438px;
    }

    .delmiaLogo {
      width: 200px;
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 16px;
    color: var(--textPags);
    line-height: 1.6;
  }

  @media screen and (max-width: 1024px) {
    &.banner {
      .screen {
        width: 596px;
      }

      p {
        br {
          display: block;
        }
      }
    }

    p {
      br {
        display: none;
      }
    }
  }

  @media screen and (max-width: 990px) {
    &.banner {
      .screen {
        width: 555px;
      }
    }
    &.delmia {
    }
  }

  @media screen and (max-width: 768px) {
    &.banner {
      flex-direction: column;
      height: auto;
      padding: 131px 0px 67px;
      align-items: flex-end;
      .screen {
        position: relative;
        width: 96%;
        margin-bottom: 40px;
      }
      h1 {
        font-size: 30px;
        &:before {
          width: 100%;
        }
      }
    }

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 15px;
      br {
        display: none !important;
      }
    }
  }

  @media screen and (max-width: 576px) {
    &.banner {
      h1 {
        font-size: 25px;
      }
      br {
        display: none;
      }
    }
    &.delmia {
      h2 {
        font-size: 15px;
        line-height: 1.5;
      }

      .fluxoImage {
        width: 100%;
      }
    }
  }
`

export const Col = styled.div`
  /* display: flex;
    flex-direction: column; */
  p {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
