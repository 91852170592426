export default {
  home: '/',
  experienciaDigital: '/experiencia-digital',
  digitalSupplyChain: '/digital-supply-chain',
  apllos: '/apllos',
  contato: '/contato',
  apoHealthCheck: '/apo-health-check',
  delfos: '/delfos',
  inteligenciaArtificialAplicada: '/inteligencia-artificial-aplicada',
  bancoDeTalentos: '/banco-de-talentos',
  sapIbp: '/sap-ibp',
  salesforce: '/salesforce',
  mulesoft: '/mulesoft',
  partners: '/parceiros',
  asprova: '/asprova',
  delmiaQuintiq: '/delmia-quintiq',
  dados: '/dados'
}
