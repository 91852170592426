import styled from 'styled-components'

export const HeaderTag = styled.header`
  color: #000;
  position: ${(props) => (props.headerFixed ? 'fixed' : 'relative')};
  z-index: 9;
  background: ${(props) => (props.headerFixed ? '#fff' : 'transparent')};
  width: 100%;
  top: 0;
  box-shadow: ${(props) =>
    props.headerFixed ? '0px 0px 19px 0 #e6e6e696' : 'none'};
  backface-visibility: hidden;
  &.pageIntern {
    position: fixed;
  }

  @media screen and (max-width: 990px) {
    /* background: #fff !important;
        position: fixed !important; */
    position: fixed;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: ${(props) => (props.headerFixed ? '14px 0' : '19px 0 0')};
  align-items: center;

  .logo {
    width: 20%;
    height: ${(props) => (props.headerFixed ? '65px' : '82px')};
    display: flex;
    align-items: center;
    z-index: 10;
    a {
      height: 100%;
      img {
        height: 100%;
      }
    }
  }

  .flags {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 20px;
    cursor: pointer;
    &.mobile {
      margin: 0;
      grid-template-columns: 30px 30px;
    }
    &:hover {
      ul {
        visibility: visible;
      }
    }
    img {
      width: 30px;
      height: 30px;
      justify-self: center;
      &.arrow {
        width: 10px;
        height: 10px;
        margin-left: 10px;
      }
    }
    ul {
      flex-direction: column;
      border-radius: 3px;
      list-style: none;
      /* border: 1px solid; */
      box-shadow: 0 0 5px 0px #d8d8d8;
      visibility: hidden;
      width: auto;
      background: #fff;
      position: absolute;
      top: 32px;
      left: -3px;
      width: auto !important;
      li {
        background: #fff;
        margin: 0 !important;
        span {
          font-size: 13px;
          padding: 0 3px;
          display: block;
          padding: 5px 11px;
          white-space: nowrap;
          &:hover {
            background: #f1f1f1;
          }
        }
      }
    }
    .langsSpan {
      font-size: 13px;
      white-space: nowrap;
    }
  }

  .menuDesktop {
    width: 80%;
    justify-content: flex-end;
    display: flex;
    ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
      width: 75%;
      a {
        text-decoration: none;
        color: #000;
        font-size: 17px;
        font-weight: 600;
        line-height: 2;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 990px) {
    /* padding: 19px 0 !important; */
    .logo {
      height: 65px;
    }
    .flags.mobile {
      display: flex;
      padding-bottom: 34px;
    }
    nav {
      &.menuDesktop {
        display: none;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .logo {
      /* height: 40px; */
    }
  }
`

export const NavMobile = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  transform: ${(props) => (props.open ? 'translateX(0%)' : 'translateX(150%)')};
  transition: 0.3s;
  width: 100% !important;
  height: 100%;
  background: #fff;
  z-index: 9;
  .menu {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 100px;
    height: 100%;
  }
  .institucional {
    &:not(:last-child) {
      margin-bottom: 96px;
    }
    h2 {
      color: var(--colorPrimary);
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 20px;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        margin-top: 38px;
        a {
          /* line-height: 2.6; */
          color: #000;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: -220px;
            border-bottom: 1px solid #e5eaf4;
            width: 207px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 990px) {
    .menu {
      padding: 100px 146px;
    }
  }

  @media screen and (max-width: 576px) {
    .menu {
      padding: 100px;
      .institucional {
        &:not(:last-child) {
          margin-bottom: 69px;
        }
      }
    }
  }
`

export const ButtonMobile = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  transform: ${({ open }) => (open ? 'rotate(-90deg)' : 'rotate(-45deg)')};
  align-items: center;
  border: ${({ open }) =>
    open ? `2px solid var(--colorPrimary)` : '2px solid #000'};
  border-radius: 100%;
  box-sizing: content-box;
  padding: 9px;
  transition: all 0.3s linear;

  &:focus {
    outline: none;
  }

  div {
    width: 1.3rem;
    height: 2px;
    background: ${({ open }) => (open ? `var(--colorPrimary)` : '#000')};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;

    :first-child {
      opacity: ${({ open }) => (open ? '0' : '1')};
    }

    :nth-child(2) {
      width: 1.9rem;
      margin-bottom: ${({ open }) => (open ? '-10px ' : '0')};
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }

    :nth-child(3) {
      width: 1.9rem;
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(4) {
      opacity: ${({ open }) => (open ? '0' : '1')};
    }
  }

  @media screen and (max-width: 990px) {
    display: flex;
  }

  @media screen and (max-width: 576px) {
    /* width: 1.4rem;
        height: 1.4rem;
        justify-content: space-between; */
  }
`
