import React from 'react'
import parse from 'html-react-parser'

import { Container } from '../../components/Layout/styles'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Row, Section, Col } from './styles'

function Delfos({ lang, handleLang }) {
  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <Section className='banner'>
        <img
          src={'./assets/images/IAAplicada/screen.png'}
          className='screen'
          data-aos='fade'
          data-aos-duration='1500'
          alt=''
        />
        <Container>
          <Row>
            <Col>
              <h1>{parse(lang.aiForecast.banner.title)}</h1>
              <h4>{parse(lang.aiForecast.banner.subtitle)}</h4>
              <p>{parse(lang.aiForecast.banner.text)}</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className='col delfos'>
            <Col>
              <h2 className='hidden'>Delfos</h2>
            </Col>
            <Col data-aos='fade' data-aos-duration='1500'>
              <p>{parse(lang.aiForecast.delfos.text)}</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className='col'>
            <Col>
              <h2>{lang.aiForecast.resources.title}</h2>
            </Col>
            <Col>
              <div data-aos='fade' data-aos-duration='1500'>
                <h3>{lang.aiForecast.resources.colTitle1}</h3>
                <p>{parse(lang.aiForecast.resources.colText1)}</p>
              </div>

              <div data-aos='fade' data-aos-duration='1500'>
                <h3>{lang.aiForecast.resources.colTitle2}</h3>
                <p>{parse(lang.aiForecast.resources.colText2)}</p>
              </div>

              <div data-aos='fade' data-aos-duration='1500'>
                <h3>{lang.aiForecast.resources.colTitle3}</h3>
                <p>{parse(lang.aiForecast.resources.colText3)}</p>
              </div>

              <div data-aos='fade' data-aos-duration='1500'>
                <h3 className='big'>{lang.aiForecast.resources.colTitle4}</h3>
                <p className='last'>
                  {parse(lang.aiForecast.resources.colText4)}
                </p>

                <p className='last'>
                  {parse(lang.aiForecast.resources.colText5)}
                </p>

                <p>{parse(lang.aiForecast.resources.colText6)}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
      <Footer lang={lang} />
    </>
  )
}

export default Delfos
