import styled from 'styled-components'
import { Row as RowColumn, Container as ContainerColumn } from '../../components/Layout/styles'

export const Container = styled(ContainerColumn)`
    /* max-width: 100%; */
`

export const Row = styled(RowColumn)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    &.row {
        flex-direction: row;
    }

    &.hire {
        align-items: center;
        .image {
            margin-right: 64px;
        }
    }
`
export const Section = styled.section`
    display: flex;
    align-items: center;

    &.hire {
        img {
            &.imgHired {
                width: 488px;
                height: 488px;
            }
        }

        h2 {
            color: var(--colorPrimary);
            font-weight: 500;
            font-size: 50px;
            position: relative;
            display: flex;
            .span-image {
                display: none;
            }
            span {
                position: relative;
                br {
                    display: none;
                }
            }
            &:after {
                content: "";
                position: absolute;
                bottom: -25px;
                right: 0;
                width: 682px;
                border-bottom: 2px solid #979797;
                z-index: -1;
            }
        }

        p {
            font-size: 22px;
            font-weight: 500;
            color: var(--textPags);
            margin: 52px 0 103px;
            line-height: 1.5;
        }

        .opportunities {
            display: flex;
            flex-direction: column;
            .links {
                display: flex;
                align-items: center;
                &:first-child {
                    margin-bottom: 53px;
                }
                h3 {
                    font-weight: 500;
                    font-size: 18px;
                    color: #171717;
                    margin-right: 40px;
                }
                a {
                    color: var(--colorPrimary);
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    img {
                        margin-right: 10px;
                    }
                    button {
                        img {
                            margin-right: 0;
                        }
                        span {
                            margin-right: 13px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        &.hire {
            .row {
                p {
                    br {
                        display: none;
                    }
                }
                .image{ 
                    img {
                        width: 426px;
                        height: 426px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        &.hire {
            .row {
                justify-content: center;
                .image {
                    display: none;
                }
                .text {
                    order: -1;
                    width: 90%;
                    p {
                        font-size: 17px;
                    }
                    h2 {
                        &:after {
                            width: 100%;
                        }
                        
                    }
                }

                .opportunities {
                    .links {
                        h3 {
                            font-size: 16px;
                        }
                        a {
                            button {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 576px) {
        &.hire {
            h2 {
                font-size: 27px;
                span {
                    br {
                        display: block;
                    }
                    &.title {
                        width: 94%;
                    }

                    &.span-image {
                        width: 40%;
                        display: block;
                        margin-right: 60px;
                    }
                }
                img {
                    display: block;
                    width: auto !important;
                    height: 189px !important;
                    position: absolute;
                    left: -61px;
                    top: -53px;
                }
            }
            p {
                margin: 86px 0 62px;
            }

            .opportunities {
                .links {
                    a {
                        button {
                            white-space: normal;
                        }
                    }
                    h3 {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 320px) {
        &.hire {
            h2 {
                img {
                    top: -32px;
                }
            }
        }
    }
`

export const Col = styled.div`
    display: flex;
    flex-direction: column;
`