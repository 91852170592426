import styled from 'styled-components'

import { Row as RowColumn } from '../../components/Layout/styles'

export const Row = styled(RowColumn)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.row {
    flex-direction: row;
  }

  &.contato {
    height: 100%;
  }

  &.fale-conosco {
    justify-content: space-between;
    align-items: center;
  }
`
export const Section = styled.section`
  display: flex;
  &.contato {
    /* height: 60vh; */
    align-items: center;
    padding-top: 234px;
    margin-bottom: 310px;
    background: url('./assets/images/contato/bgContato.jpg') no-repeat top
      center;
    height: 737px;
    background-size: cover;
    .graph {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 73%;
    }

    .oval-div {
      position: absolute;
      right: 25%;
      display: flex;
      flex-direction: column;
      z-index: 1;
      bottom: 40%;
      img {
        margin-bottom: 20px;
      }
    }

    .polygon {
      position: absolute;
      bottom: 50%;
      right: 10%;
    }

    .col {
      width: 50%;
      display: flex;
      align-items: center;
      .text {
        position: relative;

        h3 {
          margin: 44px 0 60px;
          font-weight: 500;
          font-size: 16px;
          color: var(--textPags);
          position: relative;
          &:after {
            content: '';
            position: absolute;
            bottom: -40px;
            left: 0;
            width: 78px;
            border-bottom: 1px solid var(--colorPrimary);
          }
        }
        p {
          font-weight: bold;
          font-size: 16px;
          color: var(--colorPrimary);
          line-height: 1.5;
          margin-top: 35px;
          position: relative;
        }
      }

      .form {
        width: 100%;
        margin-bottom: -288px;
        z-index: 1;
      }
    }
  }

  &.contactUs {
    h1 {
      color: var(--colorPrimary);
      font-size: 36px;
      font-weight: 400;
      margin-bottom: 40px;
    }
  }

  &.mobileForm {
    display: none;
  }

  @media screen and (max-width: 576px) {
    &.contato {
      padding-top: 0;
      margin-bottom: 63px;
      .polygon {
        width: 94px;
        top: 18%;
      }
      .oval-div {
        top: 22%;
        bottom: 0;
        img {
          width: 20px;
          height: 20px;
        }
      }
      .col {
        width: 100%;
        &.col-form {
          display: none;
        }
      }
    }

    &.mobileForm {
      display: block;
      margin-bottom: 70px;
    }
  }
`

export const Col = styled.div``
