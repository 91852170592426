export default {
  header: {
    menu: {
      li1: 'Servicios',
      li2: 'Productos',
      li3: 'Tecnologias',
      li4: 'La Apllos',
      li5: 'Aliados',
      li6: 'Contacto'
    },
    menuMobile: {
      services: 'Servicios',
      service1: 'Experiencia Digital',
      service2: 'Digital Supply Chain',
      service3: 'Datos',
      products: 'Productos',
      product1: 'APO Health Check',
      product2: 'Delfos',
      technology: 'Tecnologias',
      technology1: 'SAP IBP',
      technology2: 'Salesforce',
      technology3: 'Mulesoft',
      technology4: 'Delmia Quintiq',
      apllos: 'La Apllos',
      about: 'Conozca',
      partners: 'Aliados',
      careers: 'Carreras',
      talk: 'Comuníquese con nosotros'
    }
  },

  footer: {
    services: {
      title: 'Servicios',
      service1: 'Experiencia Digital',
      service2: 'Digital Supply Chain',
      service3: 'Datos',
    },
    products: {
      title: 'Productos',
      product1: 'APO Health Check',
      product2: 'Delfos'
    },
    technology: {
      title: 'Tecnologias'
    },
    apllos: {
      title: 'La Apllos',
      text1: 'Carreras',
      text2: 'Comuníquese con nosotros'
    }
  },

  formContact: {
    nameInput: 'Nombre',
    namePlaceholder: 'Su nombre',
    phoneInput: 'Teléfono',
    expertiseInput: 'Especialidad',
    uploadCV: 'Upload de CV',
    removeCV: 'Remover archivo',
    messageInput: 'Mensaje',
    messagePlaceholder: 'Escriba su mensaje',
    requiredField: 'Campo obligatorio',
    send: 'Enviar',
    typeFile: 'Archivo .pdf',
    titleHome: 'Contacto',
    title: 'Comuníquese con nosotros',
    title2: 'Suscríbete en nuestro<br /> Banco de Talentos',
    fileAlert: 'Formato de arquivo inválido',
    fileSizeAlert: 'Tamaño máximo 500kb',
    success: 'Éxito',
    error: 'Error',
    emailSent: 'Email Enviado',
    emailFail: 'Tente novamente, mais tarde!'
  },

  home: {
    banner: {
      titleDesk: `Evolución digital en la<br /> Gestión de la Cadena de Valor`,
      titleMobile: `Evolución digital<br /> en la Gestión de la Cadena de Valor`,
      textDesk: `Entregamos soluciones para empresas inteligentes, <br />
                        utilizando tecnologías innovadoras para viabilizar <br />
                        nuevos modelos de negocios resilientes y flexibles.`,
      textMobile: `Entregamos soluciones<br /> para empresas inteligentes,<br /> 
                        utilizando tecnologías<br /> innovadoras para viabilizar <br />
                        nuevos modelos de negocios<br /> resilientes y flexibles.`
    },

    dataBanner: {
      titleDesk: `Datos`,
      titleMobile: `Datos`,
      textDesk: `Apllos trabaja con el flujo de datos de extremo<br />
                 a extremo para su negocio, desde la ingestión<br />
                 hasta la construcción de modelos inteligentes.`,
      textMobile: `Apllos trabaja con <br />
                  el flujo de datos de <br />
                  extremo a extremo para <br />
                  su negocio, desde la ingestión<br />
                  hasta la construcción<br />
                  de modelos inteligentes.`
    },

    services: {
      subtitle: 'Nuestros',
      title: 'Servicios',
      service1Title: 'Experiencia Digital',
      service1Text: `Creamos la experiencia digital para optimizar 
                        los<br /> procesos de negocios, la cultura de trabajo y<br /> relaciones con los clientes`,
      service2Title: 'Digital Supply Chain',
      service2Text: `Aplicamos todas las soluciones innovadoras para<br /> el rendimiento 
                        de la Cadena de Valor con base en un<br /> enfoque estratégico, orientado a datos y personas.`,
      service3Title: 'Datos',
      service3Text: `Apllos trabaja con el flujo de datos de extremo<br />
                    a extremo para su negocio, desde la ingestión<br />
                    hasta la construcción de modelos inteligentes.`,
      button: 'Ver Más'
    },

    apllos: {
      title: 'simple, adecuado',
      text: `Creemos en ir más allá de la transformación digital, buscando soluciones simples a<br /> 
                    desafíos complejos del negocio, para que las organizaciones operen a la velocidad y<br /> escala necesarias. `,
      button: 'Conozca'
    },

    products: {
      subtitle: 'Nuestros',
      title: 'Productos',
      product1Title: 'DASHBOARD MONITOR',
      product1Text: 'APO Health Check',
      product2Title: 'I.A. aplicada al pronóstico de demanda',
      product2Text: 'Delfos'
    },

    technology: {
      subtitle: 'Nuestros',
      title: 'Tecnologias',
      text: `Trabajamos con un ecosistema de tecnología<br />
            A continuación las principales tecnologías de nuestros socios`
    },

    clients: {
      subtitle: 'Nuestros',
      title: 'Clientes'
    },

    contactUs: {
      subtitle: 'Comuníquese',
      title: 'Con nosotros',
      text: 'Av. Queiroz Filho, 1700 CJ. D-9 <br /> Vila Leopoldina | São Paulo-SP',
      text2: 'contato@apllos.com'
    }
  },

  digitalExperience: {
    banner: {
      title: 'Experiencia Digital',
      text: `
                    Creamos la experiencia digital para optimizar los procesos de<br /> 
                    negocios, la cultura laboral y relaciones con los clientes;<br /> 
                    responder a los cambios del mercado en la era digital.
                `,
      tag1: 'Design Thinking',
      tag2: 'Metodología ágil',
      tag3: 'Métrica',
      tag4: 'Cases'
    },

    designThinking: {
      title: `
                    Utilizamos <strong>Design thinking</strong>, un enfoque que promueve la innovación a través de un<br /> 
                    proceso estructurado para el desarrollo de proyectos más asertivos, acelerando la<br /> 
                    adopción de la mentalidad digital en la empresa. . 

                    `,
      title2: 'El abordaje incluye dos fases: Entender y Explorar'
    },

    steps: {
      step1: {
        title: '1° <span>entender</span>',
        imersion: 'Inmersión',
        imersionText:
          'Llevar a cabo investigación<br /> para desarrollar una<br /> <strong>comprensión del usuario y<br /> del negocio</strong>',
        define: 'Definir',
        defineText: `
                            Analizar toda la investigación<br /> e identificar <strong>dónde existen<br /> problemas y oportunidades<br /> para sus usuarios y negocio</strong>
                        `
      },

      step2: {
        title: '2° <span>explorar</span>',
        ideation: 'Ideación',
        ideationText: `Producir varias <br /> <strong>ideas</strong> creativas para la<br /> solución del problema `,
        prototyping: 'Prototipado',
        prototypingText: `Construir un prototipo<br /> <strong>funcional</strong>, para sus<br /> principales ideas`,
        tests: 'Pruebas',
        testsText: `<strong>Probar con los usuarios</strong><br /> para recibir feedback y<br /> mejorar`
      }
    },

    about: {
      title: `Contamos con un multidisciplinar que dentro de un<br /> ecosistema de tecnología crea soluciones de punta a punta.`,
      text: `En cada solución, definimos métricas de éxito, analizando 
                    los estándares<br /> de comportamiento de los usuarios y los objetivos del negocio, 
                    direccionando<br /> decisiones basadas en datos e impulsando el crecimiento sostenible.`
    }
  },

  digitalSupplyChain: {
    banner: {
      title: 'Digital Supply Chain',
      text: 'Empresas resilientes para un mercado volátil e incierto',
      tag1: 'Dassault Systemès',
      tag2: 'Asprova',
      tag3: 'OMP',
      tag4: 'DDMRP',
      tag5: 'SAP APO',
      tag6: 'SAP IBP',
      tag7: 'SAP S4/Hana',
      tag8: 'Sap Bussiness one',
      tag9: 'Salesforce'
    },

    section: {
      title1: `
                Para aumentar la capacidad de reacción de la cadena, se debe orquestar una mejor previsibilidad<br /> 
                con una respuesta rápida y flexible a corto plazo. 
            `,
      title2: `El trabajo se lleva a cabo con un equipo multidisciplinar con conocimiento de<br /> diferentes tecnologías y modelos de negocio. `,
      title3: `La solución está pensada de forma sistémica, pero sobre<br /> todo respetando las áreas y la experiencia de la empresa. `,
      title4: `
                Estas interacciones están guiadas por metodologías que promueven una 
                cultura de innovación y<br /> compromiso. Se sugieren tecnologías basadas 
                en un ecosistema de plataformas y startups 
            `,
      title5: `
                Esta cultura permite la construcción de soluciones con más adherencia a los requisitos<br /> 
                comerciales. Además, el concepto de arquitectura en la nube y códigos abiertos permiten<br /> crear soluciones personalizadas.
            `,
      title6: `Los procesos se modelan para aportar resiliencia,<br /> sostenibilidad y optimización a la cadena de valor. `,
      title7: `
                Para asegurar la captura de valor, las soluciones son validadas por datos y pruebas de concepto<br /> 
                (POCs), generando escala, flexibilidad y la mejor experiencia para las personas. 
            `
    }
  },

  data: {
    banner: {
      title: 'Datos',
      text: `Apllos trabaja con el flujo de datos de extremo a extremo <br />
            para su negocio, desde la ingestión hasta la construcción <br />
            de modelos inteligentes.`
    },
    section: {
      text1: `Entendemos su negocio para construir un repositorio centralizado de datos en la nube u on premise
        que permite la ingestión de datos semiestructurados, estructurados y no estructurados de diferentes fuentes utilizando 
        tecnología batch o real time. Este proceso se realiza de forma segura, fiable y automatizada. La construcción de este 
        repositorio permite analizar y tratar los datos generando capas para su utilización en modelos de ciencia y análisis de datos.`,
      text2: `Con nuestros científicos y analistas de datos, es posible comprender comportamientos, monitoreo, “insights”, 
        previsiones y predicciones para respaldar las decisiones de negocio.   `,
      text3: `Actuamos con diferentes herramientas de Big Data como el ecosistema hadoop y soluciones cloud- AWS, GCP, Databricks e Azure - 
        que cuentan con servicios de almacenamiento de bajo costo y funcionalidades para procesar grandes volúmenes de datos.`
    }
  },

  apllos: {
    banner: {
      words: ['La Apllos', 'La Απλος', 'simple', 'adecuado'],
      title: `
                Somos un equipo multidisciplinario compuesto por más de 50 colaboradores <br />
                entre designers, ingenieros, científicos de datos y desarrolladores. <br />
                Entregamos soluciones innovadoras para empresas de diversos sectores. 
            `
    },
    about: {
      professionals: 'Profesionales',
      clients: 'Clientes',
      projects: 'Proyectos',
      text: `
                Nuestros proyectos cuentan siempre con la participación<br /> activa 
                de un equipo experimentado, en una estructura<br /> horizontal. 
                Así, la co-creación con clientes y aliados,<br /> 
                aporta transparencia y seguridad en cada reto. 
                `
    },
    info: {
      organization: 'Organización',
      orgTitle: `Estructura<br /> horizontal`,
      orgText: `
                Alineación constante entre<br /> 
                todos los involucrados, con<br /> 
                una cultura abierta a nuevas ideas y<br /> 
                soluciones. 
            `,
      team: 'Equipo',
      teamTitle: 'Especialistas<br /> comprometidos',
      teamText: `Especialistas comprometidos <br />con la calidad de la solución <br />y plazos de las entregas. 
            `,
      focus: 'Enfoque',
      focusTitle: 'Soluciones<br /> innovadoras',
      focusText: `Aplicación de las soluciones más<br /> avanzadas y adecuadas para <br />la evolución del negocio. 
            `
    }
  },

  hire: {
    title: 'Estamos<br /> Contratando',
    text: `Retos y espacios para diferentes perfiles<br /> en un ambiente de innovación y tecnología. 
        `,
    link1: 'Revisa los vacantes en nuestro',
    link2: 'o suscríbete en nuestro',
    talents: 'Banco de Talentos'
  },

  apoHealthCheck: {
    banner: {
      title: 'APO <br /> Health Check',
      text: `
                El sistema de planificación APS - SAP<br /> 
                APO como todos los sistemas necesita 
                de un<br /> monitoreo de rutina para prevenir<br /> problemas y acción en casos de falla. 
            `
    },
    section: {
      title: 'Inicio',
      text: `
                El producto de Apllos – APO Health Check tiene como objetivo 
                mapear las actividades <br />críticas que afectan el negocio y alertar preventivamente acciones 
                a los responsables.<br /> Las actividades monitoreadas se personalizan y las alertas se 
                activan a través de<br /> correo electrónico con mensajes que ayudan a identificar la acción. 
            `,
      text2: `Qué monitoriza el producto de Apllos:`
    },
    monitor: {
      title: 'Monitor',
      collapse1Title: 'CIF (Core interface)',
      collapse1Text: `<p>Monitorea la cantidad de objetos inconsistentes en la interfaz (SAP APO y SAP ECC) 
                        además de registrar el historial de estos datos. Las alertas se activan cuando el entorno 
                        tiene un número crítico de objetos con problemas.</p>`,
      collapse2Title: 'Almacenamiento de Log',
      collapse2Text: `<p>Alerta cuando el uso reservado para el almacenamiento de log está lleno.</p>`,
      collapse3Title: 'Live Cache',
      collapse3Text: `<p>Monitorea disponibilidad de la memoria “live cache” y en caso de inactividad acciona al responsable.</p>`,
      collapse4Title: 'Cadena de proceso',
      collapse4Text: `<p>Monitorea cuando las cadenas de proceso o “Process Chains” tienen un estatus de cancelación o exceden la duración prevista, se envían alertas por e-mail.  </p>`,
      collapse5Title: 'Dumps',
      collapse5Text: `<p>Monitorea las fallas en algoritmos desarrollados en el sistema (SAP APO). Se envían alerta de fallas por e-mail para corrección.</p>`,
      collapse6Title: 'Utilización de memoria',
      collapse6Text: `<p>Muestra cuando una ejecución se está utilizando excesivamente la memoria o procesamiento del ambiente. Con esto, 
                            se identificarán fallas de filtros en ejecución y fallas en el desempeño de un programa. Esto evitará lentitud en el ambiente.</p>`
    },
    form: {
      title: 'Explore nuestro programa APO Health Check',
      paragraph: 'Complete el formulario y reciba el enlace de acceso',
      message: 'Quiero recibir el enlace de acceso al APO Health Check'
    }
  },

  aiForecast: {
    banner: {
      title: `Delfos`,
      subtitle: 'I.A. aplicada al pronóstico de demanda',
      text: `Delfos es una solución de pronóstico de demanda<br /> 
                    de alto nivel basada en varios algoritmos de<br /> 
                    regresión y modelos de machine learning.`
    },
    delfos: {
      text: `
                Delfos es la solución para quienes desean utilizar herramientas avanzadas<br /> 
                de inteligencia artificial para pronosticar series temporales, sin<br /> 
                necesidad de tener experiencia en machine learning o en programación. 
            `
    },

    resources: {
      title: 'Recursos',
      colTitle1: 'Ajuste automático de la serie temporal',
      colText1: `
                Aplicación automática de métodos estadísticos para ajustar y<br /> 
                transformar los componentes de la serie temporal, como tendencia,<br /> 
                estacionalidad, ruidos y autocorrelación. Estos métodos implican una<br /> 
                mejora en el resultado de los algoritmos.  

            `,
      colTitle2: 'Best Fit',
      colText2: `
                Más de 20 algoritmos, regresivos y de machine learning, ejecutados<br /> 
                simultáneamente.<br /> 
                Delfos se encarga de determinar cuál es el modelo adecuado para<br /> 
                cada nivel de pronóstico.  
            `,
      colTitle3: 'Forecast Jerárquico',
      colText3: `
                Ejecuciones en cualquier nivel de jerarquía temporal y estructural. Es<br /> 
                posible, por ejemplo, hacer un pronóstico por región y luego por<br /> 
                cliente, utilizando frecuencias temporales mensuales y semanales. 
            `,
      colTitle4: 'Cómo funciona',
      colText4: `
                Delfos funciona como un servicio administrado, utilizando la base de<br /> 
                datos histórica.<br /> 
                El sistema identifica la correlación entre las variables y desarrolla<br /> 
                modelos de previsión personalizados y adecuados para cada escenario<br /> 
                encontrado.
            `,
      colText5: `
                Apllos se encarga de proveer toda la infraestructura necesaria<br /> 
                para las ejecuciones, sin la necesidad de otros costos para la empresa. 
            `,
      colText6: `
                Simplemente debe cargar sus datos para obtener mejores resultados en su<br /> 
                proceso de pronóstico de demanda, utilizando técnicas avanzadas de<br /> 
                inteligencia artificial.
            `
    }
  },

  contact: {
    title: 'Contacto',
    text: 'Será un honor construir algo juntos'
  },

  talents: {
    title: 'Banco de Talentos',
    text: `Retos y espacios para diferentes perfiles<br /> en un ambiente de innovación y tecnología.`,
    check: `Revisa los vacantes en nuestro`
  },

  sapIbp: {
    title: 'SAP Integrated<br /> Business Planning',
    text: `
            SAP IBP es una solución completa para la planificación integrada de<br /> la cadena de valor. 
            La plataforma se construyó sobre una base de datos<br /> en la nube y memoria utilizando 
            SAP HANA, que permite el<br /> procesamiento de datos en tiempo real. 
            `,
    text2: `Como diferencial, Apllos cuenta con una experiencia integral en la implementación de procesos de planificación y sistemas APS, así como un equipo multidisciplinario para el desarrollo de interfaces en múltiples plataformas, análisis de datos y definición de procesos para co-crear una solución que aporte valor al cliente. `,
    text3: `SAP IBP es una solución completa para la planificación integrada de la cadena de valor. Combina recursos de ventas y operaciones, demanda, planificación de suministros, torre de control de la cadena y optimización de stock. La plataforma está integrada a través del SAP HANA Cloud Integration con las más variadas arquitecturas de tecnologías permitiendo que los datos estén siempre actualizados para la planificación.  
        `,
    text4: `Su interfaz fue desarrollada para trabajar con plataforma web, Fiori y Excel, facilitando su usabilidad e iteración entre procesos de negocio.
        `,

    services1Title: 'Supply Chain Control Tower',
    services1Text: `
            Plataforma de visibilidad de la cadena de suministro en tiempo real para el apoyo a la toma de decisiones y acciones correctivas rápidas. <br /> 
            <strong>Principales beneficios: </strong>
        `,
    services1List: {
      text1: `Visibilidad end-to-end en toda la cadena de suministro, incluidas alertas, apoyo a la toma de decisiones y acciones correctivas rápidas;
            `,
      text2: `Disminución de los niveles de stock globales, reducción de riesgos; `,
      text3: `Aumento del desempeño del tiempo de entrega para el cliente;  `,
      text4: `Colaboración, comunicación y monitoreo de la cadena de suministros; `,
      text5: ` Mayor agilidad en suministro y reducción de costos de la cadena de suministro  `
    },

    services2Title: 'IBP For Sales and Operations',
    services2Text: `BP S&OP es una plataforma de planificación colaborativa de demanda y cálculo de previsión estadística que utiliza modelajes matemáticos simples. La herramienta utiliza datos flexibles y unificados de la cadena de suministro para respaldar la planificación táctica y estratégica en cualquier nivel de granularidad y dimensión. 
        `,
    services2Text2: `En el caso de una planificación más avanzada de la demanda, se recomienda el uso de IBP Demand. 
        <br />
            <strong>Funcionalidades Clave</strong>
        `,
    services2List: {
      text1: `Planificación colaborativa de la demanda y previsión estadística simple;
            `,
      text2: `Planificación infinita de varios niveles;`,
      text3: `Ajuste manual de la planificación de la operación y sus variables para análisis y simulación;`,
      text4: `Utilización de heurísticas que tienen en cuenta: Lead time de transporte, stock actual, stock de seguridad, stock objetivo, lote mínimo, valor de redondeo, entre otros paramentos 
            ;`,
      text5: `Ambos procesos trabajan con informaciones integradas de ERP y permiten el análisis y simulación del plan completo y delta;`,
      text6: `Para considerar capacidad finita de los recursos, restricciones de stock y otros modelos más avanzados se recomienda la utilización del IBP Responde and Supply.`
    },

    services3Title: 'IBP For Demand',
    services3Text: `Planificación de la demanda estadística a corto, mediano y largo plazo en una solución única. La plataforma utiliza tratamiento de datos, clasificación de comportamiento, agregación y desagregación, además de modelos matemáticos avanzados como inteligencia artificial y regresión para el pronóstico de la demanda. 
        `,
    services3Text2: `La plataforma apoya la creación de demanda consensual a través de la planificación colaborativa y proporciona informaciones relevantes para el análisis del plan. 
        <br />
            <strong>Funcionalidades Clave</strong>
        `,
    services3List: {
      text1: `Demand-sensing y previsión de mediano/largo plazos en una solución única de planificación de la demanda; 
            `,
      text2: `Proceso de planificación automatizada y basado en excepciones, así como funcionalidades de planificación manual; 
            `,
      text3: `Análisis de demanda integrada y dinámica;`,
      text4: `Limpieza de historial y corrección de outliers; 
            `,
      text5: `Previsión estadística con modelos avanzados disponibles; 
            `,
      text6: `Agregación/Desagregación de los datos Time Series;`,
      text7: `Planificación colaborativa de la demanda; 
            `,
      text8: `Generación de alertas; 
            `,
      text9: `Utilización de Dashboards para apoyo a la planificación. `
    },

    services4Title: 'IBP For Inventory',
    services4Text: `SAP IBP inventory tiene un sofisticado algoritmo de optimización para definir la mejor política de stock considerando la cadena de suministro como multinivel, costo, variabilidades y nivel de servicio.  `,
    services4Text2: `Algunos parámetros utilizados en el algoritmo: `,
    services4List: {
      text1: ` Variabilidad de la demanda`,
      text2: `Error de previsión `,
      text3: `Desviación de suministros  `,
      text4: `Costos`,
      text5: `Leadtimes`,
      text6: `Eslabones de la cadena de suministros `,
      text7: `Posicionamiento de los stock a lo largo de la cadena`
    },

    services5Title: 'IBP For Resp N Supply',
    services5Text: `SOP Response and Supply planning es la solución para la planificación de abastecimiento y asignación de la cadena de suministro La herramienta cuenta con diferentes modelos heurísticos y optimización para la creación de escenarios y órdenes para la planificación de la producción, distribución y reabastecimiento. 
        `,
    services5Text2: `Funcionalidades Claves:`,
    services5List: {
      text1: `Optimización con función objetivo para maximizar la utilidad o el nivel de servicio `,
      text2: `Heurística`,
      text3: `Planificación finita multinivel`,
      text4: `Prioridades gestionada por costos 
            `,
      text5: `Stock de seguridad y stock máximo 
            `,
      text6: `Costos de entrega tardía, violación de stock de seguridad, y stock por unidad, costos de producción, transporte, compras por unidad o fijo, costo de producción, entre otros 
            `,
      text7: `Generación de órdenes de suministro (producción, compras, distribución) e integración con un nuevo modelo de integración próxima al tiempo real con ERP`
    }
  },

  salesforce: {
    title: 'Salesforce',
    text: `CRM Salesforce es una plataforma que cuenta con un<br /> 
        software líder en gestión y relación con los<br /> 
        clientes, y es el más vendido del mundo. 
        `,
    text2: `La herramienta gestiona cuestiones fundamentales para el negocio, como el embudo 
        de ventas, leads y el seguimiento de tareas.`,
    text3: `Salesforce cuenta con toda la infraestructura en nube, lo que posibilita que el 
        vendedor pueda acceder a las informaciones de su cliente desde cualquier dispositivo que 
        esté conectado a Internet.`,
    text4: `La herramienta Salesforce fue diseñada para facilitar el desarrollo aportando 
        velocidad y bajo costo en la implementación. Sus licencias permiten el uso por pequeña, 
        medianas y grandes empresas.`,
    text5: `La herramienta Salesforce cuenta con diversas soluciones para su negocio y 
        los principales son: Sales Cloud, Service Cloud, marketing cloud, entre otras.`,
    services1Title: 'Sales Cloud',
    services1Text: `Es una solución completa con varias funcionalidades para hacer más competitivo el 
        trabajo de los equipos de marketing y ventas.
        `,
    services1Text2: `Beneficios
        `,
    services1List: {
      text1: `Posibilidad de tener una visión de 360° del cliente, generando confianza, garantizando 
            la venta y mejorando la relación postventa;
            `,
      text2: `Mejora la participación de los vendedores y socios creando experiencias que mejoran el 
            proceso de venta con informaciones claras y relevantes;`,
      text3: `Es posible seguir en tiempo real los momentos de decisión de compra de un posible 
            cliente dentro de la organización;`,
      text4: `Viabilidad de automatizar acciones y procesos;`,
      text5: `La herramienta le permite controlar informaciones sobre la competencia, así como los 
            contratos y las negociaciones.`
    },

    services2Title: 'Service Cloud',
    services2Text: `Es una solución diseñada para satisfacer las solicitudes de sus clientes sobre su producto o servicio. 
        Esta herramienta permite a su empresa retener a sus clientes, generando valor para su marca.`,
    services2Text2: `Beneficios
        `,
    services2List: {
      text1: `Aumentar la satisfacción de sus clientes;
            `,
      text2: `Puede ayudar a las empresas a optimizar sus procesos y llamadas, reduciendo la resolución de sus problemas y 
            mejorando drásticamente la satisfacción del cliente;`,
      text3: `Gestión de casos, acceso de clientes a través de canales, integraciones con sistemas heredados, ticket de soporte, base de conocimientos, enrutamiento y escalamiento, y gestión de colas de atención;`,
      text4: `Le permite hablar con los clientes a través del canal de su elección, ya sea por teléfono, SMS (mensajes de texto), WhatsApp o Facebook Messenger;`,
      text5: `Aumenta la reputación de la empresa y aumenta las oportunidades de ventas;`,
      text6: `Permite definir metas y el seguimiento de los resultados.`
    },

    services3Title: 'Marketing Cloud',
    services3Text: `Plataforma para la creación de jornadas relevantes y personalizadas para su público objetivo en los canales y dispositivos correctos.
        `,
    services3Text2: `Beneficios`,
    services3List: {
      text1: `Es posible conectar datos de diferentes fuentes y dispositivos a esta plataforma, con el fin de obtener una visión única del cliente. Además, podrá capturar y activar datos de primarios, secundarios y de terceros;`,
      text2: `Proporciona datos para la herramienta de inteligencia artificial de Salesforce - Einstein. Los análisis realizados permiten interacciones organizadas y personalizada con el cliente;`,
      text3: `Genera atención y conciencia durante todo el proceso (un compromiso bidireccional en tiempo real), lo que también proporciona insights para ofrecer a cada cliente la mejor acción para ellos.`
    }
  },

  mulesoft: {
    title: 'MuleSoft',
    text: `
        Mule es un mecanismo de tiempo de ejecución liviano y escalonable<br /> para APIs, 
        integraciones y microservicios. Se lo utiliza con una<br /> arquitectura de plataforma 
        híbrida -local, en la nube o en ambos.
        `,
    text2: `
        Como líder global en integraciones y soluciones de gerenciamiento de API, MuleSoft tiene 
        como objetivo con el Anypoint Platform conectar sus aplicativos y resolver los problemas 
        de conectividad más desafiadores en SOA, SaaS y APIs.<br />
        Mulesoft es una plataforma simple para construcción de API’s y gerenciamiento de integraciones, 
        ellos son  ERP’s, MES y aplicativos backend.
        `,
    text3: `Juntas, Salesforce y MuleSoft aceleran su transformación digital, 
        capacitándolos a abrir los datos a lo largo de sistemas legados, aplicativos de cloud y 
        dispositivos para la toma de decisiones más rápidas y más inteligentes, y para crear 
        experiencias altamente diferenciadas y conectadas a los consumidores. 
        `,
    text4: `Anypoint Platform acelera la velocidad de desarrollo reutilizando APIs, conectores, 
        modelos, ejemplos y otros activos de integración ya construidos y publicados en el Exchange de Mulesoft. 
        `
  },

  delmiaQuintiq: {
    title: 'Delmia Quintiq',
    text: `	El software de planificación de la cadena de suministros de DELMIA<br /> 
						Quintiq planifica y optimiza su cadena de suministros de punta<br /> a punta, 
						brindándole el nivel de control y visión necesarios para<br /> transformar 
						la planificación de la cadena de suministros de un centro<br /> de costo en 
						un generador de ingresos.`,
    text2: `Defina claramente los KPI de toda la organización y alinee cada<br /> función 
						de negocios con estos objetivos. Optimice cada etapa<br /> de su cadena de 
						suministros, desde planes estratégicos de largo<br /> plazo hasta detalles
						críticos en el punto de ejecución, para lograr<br /> altos niveles de servicio, 
						calidad y eficiencia que sean consistentes<br /> y sostenibles.`,
    text3: `DELMIA Quintiq es el software de planificación y optimización de la cadena de suministros (SCP & O) que ofrece el poder de la optimización.`,
    text4: `<strong>Sales and operations planning</strong> – Planificación Integrada de Ventas y Operaciones. Recursos avanzados de modelado y optimización para simular cualquier número de escenarios hipotéticos y luego recomendar el mejor para atender a los KPI de negocio y respaldar la toma de decisión eficaz para entregar el máximo valor.`,
    text5: `<strong>Master planning</strong> – conduzca el soporte de decisión global con visibilidad en tiempo real y análisis predictivo para la planificación maestra y la programación y planificación de requisitos de material.`,
    text6: `<strong>Programación de producción detallada</strong> - reduzca el tiempo de espera y el stock, optimizando la producción dentro y entre las líneas de producción, las células de trabajo y las operaciones de ensamblaje, al tiempo que mejora la utilización de los activos y brinda soporte a las fechas de vencimiento del cliente.`,
    text7: `<strong>Planificación y optimización de las operaciones logísticas</strong> - gerentes, planificadores y despachantes obtienen total visibilidad y control sobre todo el proceso de planificación y optimización, desde la planificación estratégica de la red de largo alcance hasta la programación diaria de la fuerza laboral y la planificación de rutas.`,
    text8: `<strong>Planejamento e otimização das operações de trânsito</strong> – uma solução para lidar com todos os desafios - desde a programação da tripulação 
					e da frota até a conquista de novas licitações por meio de licitações abertas. A solução funciona para operações grandes e pequenas e se 
					integra perfeitamente a uma ampla gama de sistemas de terceiros.`,
    text9: `Planificación y optimización de las operaciones de la fuerza laboral</strong> - se basa en un modelo de negocios 100% adecuado que incluye todas las restricciones exclusivas de su organización, como habilidades y calificaciones de los empleados, requisitos específicos de contratos, las regulaciones laborales y la disponibilidad de recursos. Usted define sus metas de eficacia, satisfacción y rentabilidad; capacitamos a sus planificadores con las herramientas y percepciones que necesitan para mejorar los resultados importantes.`,
    text10: `El software DELMIA Quintiq incluye una aplicación web desarrollada con una nueva interfaz moderna que se enfoca en la experiencia del usuario. Esto le proporciona a los usuarios la libertad y la flexibilidad para acceder inmediatamente a DELMIA Quintiq desde cualquier navegador web.`,
    text11: `https://www.3ds.com/products-services/delmia/products/delmia-quintiq/`
  },

  partners: {
    main: {
      title: `Nuestros Aliados`,
      text: `Trabajamos con un ecosistema de aliados<br /> para que podamos buscar soluciones innovadoras<br /> para su negocio.`
    },
    dassault: {
      text: `Dassault Systèmes, empresa 3DEXPERIENCE, proporciona a las empresas y personas universos virtuales para la creación de innovaciones sostenibles y que contribuyan a la mejora de los procesos y prácticas de negocios. Sus soluciones conocidas mundialmente transforman la forma en que se desarrollan, producen y gestionan los productos. Las soluciones colaborativas de Dassault Systèmes incentivan la innovación social, ampliando las posibilidades de mejora del mundo real a través del mundo virtual. La empresa entrega valor a más de 210.000 clientes de todos los portes e industrias, en más de 140 países.`
    },
    sap: {
      text: `SAP es uno de los líderes mundiales en el desarrollo de software para la gestión de procesos de negocios, como ECC, S4 Hana, IBP, SAC, creando soluciones que facilitan el procesamiento efectivo de datos y el flujo de informaciones entre las organizaciones. <br /><br />
			Hoy, SAP tiene más de 230 millones de usuarios en la nube, más de 100 soluciones que abarcan todas las funciones de negocios y el mayor portafolio soluciones en la nube de cualquier proveedor.`
    },
    poweron: {
      text: `PowerOn permite que su PowerBI realice la operación de actualización y escritura integrando sus dashboards con más de 200 bases de datos. Esto permitirá la creación de planes colaborativos, modelos dinámicos con cambios de parámetros y una mejor usabilidad para su plataforma.`
    },
    aimms: {
      text: `AIMMS proporciona una plataforma de modelado y optimización que se ha utilizado en varias funciones de prácticamente cualquier tipo de negocio. Es una excelente opción para los equipos que están listos para alejarse de hojas de cálculo o herramientas antiguas de proyecto de red, previsión de demanda y S&OP / IBP. AIMMS SC Navigator proporciona aplicaciones listas para usar en la nube para obtener los beneficios de análisis sofisticados sin la necesidad de experiencia en análisis incluidos solucionadores de clase mundial (y enlaces de solucionador) para programación lineal, entera mixta y no lineal como baron, cplex, conopt, gurobi, knitro, path, snopt y xa. Además, conceptos como la programación estocástica y la optimización robusta están disponibles para incluir la incertidumbre de los datos en los modelos.
      `
    },
    asprova: {
      text: `Asprova es un Sistema de APS (Advanced Planning & Scheduling) para la programación de producción en alta velocidad para múltiples ítems y procesos con la integración de ventas, manufactura, stock y compra. Asprova fue desarrollado originalmente en Japón, donde es la herramienta de programación de producción más utilizada con un “market share” del 52,4%. (De acuerdo con el Techno Systems Research Co.,Ltd Report 2011)`
    }
  },

  asprova: {
    text: `Asprova funciona como una planilla de Excel fácil, incluso con<br /> grandes cantidades de datos, mantenimiento facilitado.`,
    text2: `Asprova fue desarrollado originalmente en Japón, donde es la herramienta de programación de producción más utilizada con un “market share” del 52,4%. (De acuerdo con el Techno Systems Research Co.,Ltd Report 2011)`,
    text3: `Asprova es un Sistema de APS (Advanced Planning & Scheduling) para la programación de producción en alta velocidad para múltiples ítems y procesos con la integración de ventas, manufactura, stock y compra. La herramienta tiene múltiples funcionalidades para la programación de producción, además de ser fácil de personalizar sin la complejidad de las nuevas codificaciones.`,
    text4: `La aplicación trabaja con dos tipos de visualización de datos: una hoja de cálculo fácil de editar y una vista gráfica. Se puede visualizar recursos, órdenes de producción, stock, compras y ventas. Las programaciones se realizan en diferentes agregaciones temporales como día, semana y mes, además de ser flexible para la personalización del usuario. La aplicación se integra con diferentes ERP como SAP y Oracle.`,
    features: {
      title: `<strong>Algunas funcionalidades</strong>`,
      text: `Ventas: Vincular las informaciones de ventas a la planificación de la producción en fábrica.`,
      text2: `Compra: Vincular la planificación de la producción con las informaciones de compras.`,
      text3: `KPI: Evalúe varios KPI’s en cada proyecto.`,
      text4: `Restricción de tiempo MAX: Ajuste el tiempo predefinido entre los procesos.`,
      text5: `Bloqueo de recursos: Bloquear temporalmente un recurso después de la producción.`,
      text6: `Evento: Definir condiciones y generar eventos.`,
      text7: `Optimización: secuenciación de trabajo para todos los recursos.`,
      text8: `Escenarios: Soporte para la creación de múltiples escenarios.`,
      text9: `Restricciones de recurso: Elija las sobrecargas de línea y las entregas retrasadas al instante utilizando tablas y gráficos.`
    }
  }
}
