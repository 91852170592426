import styled from 'styled-components'

import { Row as RowColumn } from '../../components/Layout/styles'

export const Row = styled(RowColumn)``

export const Section = styled.section`
  display: flex;
  margin: 40px 0 0;
  &.banner {
    margin: 0 auto;
    height: 737px;
    align-items: center;
    background: url('./assets/images/salesforce/banner.png') no-repeat top
      center;
    background-size: cover;
    overflow-x: hidden;
    h1 {
      color: var(--colorPrimary);
      font-size: 42px;
      font-weight: 500;
      margin-bottom: 55px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: -26px;
        left: 0;
        width: 990px;
        border-bottom: 2px solid var(--colorPrimary);
        z-index: 1;
      }
    }
    p {
      line-height: 1.6;
    }

    /* .screen {
            position: absolute;
            right: 0;
            top: 25%;
            width: 50%;
            z-index: 2;
        } */

    .screen {
      position: absolute;
      right: 0;
      top: 25%;
      width: 50%;
      z-index: 2;
      border-top-left-radius: 21px;
      border-bottom-left-radius: 21px;
      box-shadow: 1px 4px 4px 0px #9090905e;
    }

    @media screen and (min-width: 1556px) {
      .screen {
        width: auto !important;
        top: 17%;
      }
    }
  }
  &.salesforce {
    h2 {
      color: var(--textPags);
      font-size: 17px;
      font-weight: normal;
      margin-bottom: 25px;
    }
    .collapseDivs {
      h1 {
        font-size: 20px;
        font-weight: 600;
      }

      strong {
        font-size: 16px;
        color: var(--textPags);
        line-height: 1.6;
        margin: 21px 0;
        display: block;
      }

      p {
        margin-bottom: 20px;
        display: block;
        strong {
          margin: 18px 0 21px;
        }
      }

      ul {
        li {
          font-size: 16px;
          color: var(--textPags);
          /* line-height: 2.8; */
          margin-bottom: 26px;
        }
      }

      .app__toggle {
        padding: 20px 20px 0 0;
      }

      .app__content {
        padding: 20px 45px;
      }
    }
  }

  p {
    font-size: 16px;
    color: var(--textPags);
    line-height: 1.6;
  }

  @media screen and (max-width: 1024px) {
    &.banner {
      .screen {
        width: 596px;
      }

      p {
        br {
          display: block;
        }
      }
    }

    p {
      br {
        display: none;
      }
    }
  }

  @media screen and (max-width: 990px) {
    &.banner {
      .screen {
        width: 555px;
      }
    }
    &.salesforce {
      .type {
        padding: 8px !important;
        position: relative;
        h2 {
          span {
            /* position: absolute;
                        left: 25%;
                        top: 7%; */
          }
        }
        &:last-child {
          display: block !important;
          padding: 0 !important;
          div {
            margin-right: 0 !important;
            margin-bottom: 15px;
            position: relative;
            padding: 8px !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &.banner {
      flex-direction: column;
      height: auto;
      padding: 131px 0px 67px;
      align-items: flex-end;
      .screen {
        position: relative;
        width: 96%;
        margin-bottom: 40px;
      }
      h1 {
        font-size: 30px;
        &:before {
          width: 100%;
        }
      }
    }

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 15px;
      br {
        display: none !important;
      }
    }
  }

  @media screen and (max-width: 576px) {
    &.banner {
      h1 {
        font-size: 25px;
      }
      br {
        display: none;
      }
    }
    &.salesforce {
      h2 {
        font-size: 15px;
        line-height: 1.5;
      }
      .collapseDivs {
        h1 {
          font-size: 15px;
          span {
            width: 38px;
            height: 31px;
          }
        }

        .app__content {
          padding: 20px;
          p,
          strong {
            font-size: 14px;
          }
          ul {
            li {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    &.salesforce {
      .services {
        .type {
          h2 {
            font-size: 13px;
            span {
              width: 34px;
              height: 30px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 360px) {
    &.salesforce {
      .collapseDivs {
        h1 {
          font-size: 14px;
          span {
            width: 33px;
            height: 28px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 330px) {
    &.salesforce {
      .services {
        .type {
          h2 {
            font-size: 12px;
            span {
              width: 33px;
              height: 29px;
            }
          }
        }
      }
      .collapseDivs {
        h1 {
          font-size: 12px;
          span {
            width: 27px;
            height: 22px;
          }
        }

        p,
        strong {
          font-size: 12px !important;
        }
        ul {
          li {
            font-size: 12px !important;
          }
        }
      }
    }
  }
`

export const Col = styled.div`
  /* display: flex;
    flex-direction: column; */
  p {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
