import React from 'react'
import parse from 'html-react-parser'

import { Container } from '../../components/Layout/styles'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Title, Text } from '../../components/Title/styles.js'
import { Row, Section, Col } from './styles'
import { Link } from 'react-router-dom'
import Routes from '../../routes'
import { Button } from '../../components/Button/styles'

function Partners({ lang, handleLang }) {
  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <Section className='banner'>
        <img
          src='./assets/images/digitalSupplyChain/graphSupplyChainPage.svg'
          className='graph'
          data-aos='fade-down-left'
          data-aos-duration='1500'
          alt=''
        />
        <img
          src='./assets/images/digitalSupplyChain/points.svg'
          className='points'
          alt=''
          data-aos='flip-up'
          data-aos-duration='1500'
        />

        <Container>
          <Row className='col'>
            <Col>
              <Title>{parse(lang.partners.main.title)}</Title>
              <Text>{parse(lang.partners.main.text)}</Text>
            </Col>
            <Col className='content-image'>
              <img
                src='./assets/images/partners/hands.png'
                className='hands'
                alt=''
              />
              <img
                src='./assets/images/apllos/object.png'
                className='objectApllos'
                alt=''
              />
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className='partners'>
            <Col>
              <img
                src='./assets/images/partners/dassault.png'
                alt='Dassault Systèmes'
              />
            </Col>
            <Col className='with-link'>
              <p>{parse(lang.partners.dassault.text)}</p>
              <Link to={Routes.delmiaQuintiq}>
                <Button className='right'>
                  <span>{parse(lang.home.services.button)}</span>
                  <span className='arrow'></span>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className='partners'>
            <Col>
              <img src='./assets/images/partners/sap-logo.png' alt='SAP' />
            </Col>
            <Col>
              <p>{parse(lang.partners.sap.text)}</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className='partners'>
            <Col>
              <img src='./assets/images/partners/powerOn.png' alt='PowerOn' />
            </Col>
            <Col>
              <p>{parse(lang.partners.poweron.text)}</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className='partners'>
            <Col>
              <img src='./assets/images/partners/aimms.png' alt='AIMMS ' />
            </Col>
            <Col>
              <p>{parse(lang.partners.aimms.text)}</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className='partners'>
            <Col>
              <img src='./assets/images/partners/asprova.png' alt='Asprova' />
            </Col>
            <Col className='with-link'>
              <p>{parse(lang.partners.asprova.text)}</p>
              <Link to={Routes.asprova}>
                <Button className='right'>
                  <span>{parse(lang.home.services.button)}</span>
                  <span className='arrow'></span>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Section>

      <Footer lang={lang} />
    </>
  )
}

export default Partners
