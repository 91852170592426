import styled from 'styled-components'

export const Tags = styled.div`
  span {
    display: inline-block;
    margin: 10px 20px 0 0;
    color: #fff;
    background: var(--colorPrimary);
    border-radius: 4px;
    padding: 6px 13px;
    font-size: 13px;
    /* cursor: pointer; */
    &:hover {
      box-shadow: 0 0 4px 0px #7e17a98c;
      background: #7e17a9;
    }
    @media (max-width: 576px) {
      font-size: 10px;
      margin: 10px 5px 0 0;
    }
  }

  @media screen and (max-width: 1024px) {
    br {
      display: none;
    }
  }
`
