import React  from 'react';
import {Container, BannerContainer, BannerContent, Row, Button} from './styles'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import Slider from 'react-slick'
import Routes from '../../../routes';

const settings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
}

function Banner({ lang }) {
  return (
    <Container>
      <Slider {...settings}>
      <div>
          <BannerContainer>
            <div className='graph' data-aos='fade-down-right'></div>
            <BannerContent>
              <Row className='flex'>
                <div className='col'>
                  <div className='logo'>
                    <div className='logoApllos'></div>
                  </div>
                  <h1 className='desktop'>{parse(lang.home.banner.titleDesk)}</h1>
                  <h1 className='mobile'>{parse(lang.home.banner.titleMobile)}</h1>
                  <p className='desktop'>{parse(lang.home.banner.textDesk)}</p>
                  <p className='mobile'>{parse(lang.home.banner.textMobile)}</p>
                </div>
                <div className='banners'>
                  <div className='graphs'>
                    <div className='graph2' data-aos='fade-down-left'></div>
                    <div className='people' data-aos='fade-down-left'></div>
                    <div className='object' data-aos='fade-down-left'></div>
                    <div className='polygon' data-aos='fade-down-left'></div>
                  </div>
                </div>
              </Row>
            </BannerContent>
          </BannerContainer>
        </div>
      <div>
          <BannerContainer>
            <div className='graph' data-aos='fade-down-right'></div>
            <BannerContent>
              <Row className='flex'>
                <div className='col'>
                  <div className='logo'>
                    <div className='logoApllos'></div>
                  </div>
                  <h1 className='desktop'>{parse(lang.home.dataBanner.titleDesk)}</h1>
                  <h1 className='mobile'>{parse(lang.home.dataBanner.titleMobile)}</h1>
                  <p className='desktop'>{parse(lang.home.dataBanner.textDesk)}</p>
                  <p className='mobile'>{parse(lang.home.dataBanner.textMobile)}</p>
                  <Link to={Routes.dados}>
                    <Button>
                      <span>{parse(lang.home.services.button)}</span>
                      <span className='arrow'></span>
                    </Button>
                  </Link>
                </div>
                <div className='data-banner'>
                  <img alt="Dados" src='./assets/images/home/data.png' />
                </div>
              </Row>
            </BannerContent>
          </BannerContainer>
        </div>
        {/* <div>
          <BannerContainer>
            <div className='graph' data-aos='fade-down-right'></div>
            <BannerContent>
              <Row className='flex'>
                <div className='col'>
                  <div className='logo'>
                    <div className='logoApllos'></div>
                  </div>
                  <h1 className='desktop'>Dassault System <br /> Parceiros Apllos</h1>
                  <h1 className='mobile'>Dassault System <br /> Parceiros Apllos</h1>
                  <p className='desktop'>Atuamos com o fluxo de dados ponta a ponta <br /> para o seu negócio desde a ingestão até <br />a construção de modelos inteligente</p>
                  <p className='mobile'>Atuamos com o fluxo de dados ponta a ponta <br /> para o seu negócio desde a ingestão até <br />a construção de modelos inteligente</p>
                </div>
                <div className='banners'>
                  <div className='graphs'>
                    <div className='graph2' data-aos='fade-down-left'></div>
                    <div className='people' data-aos='fade-down-left'></div>
                    <div className='object' data-aos='fade-down-left'></div>
                    <div className='polygon' data-aos='fade-down-left'></div>
                  </div>
                </div>
              </Row>
            </BannerContent>
          </BannerContainer>
        </div> */}
      </Slider>
    </Container>
  )
}

export default Banner;