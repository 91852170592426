import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'

import Routes from '../../routes'
import { HeaderTag, Row, ButtonMobile, NavMobile } from './styles'
import { Container } from '../Layout/styles'

import { anchorScroll } from '../../utils/anchorScroll'

function Header({ classes, home, lang, handleLang }) {
  const [headerFixed, setHeaderFixed] = useState(undefined)
  const [menuOpen, setMenuOpen] = useState(false)
  const history = useHistory()
  const ref = useRef(null)
  const [langActive, setLangActive] = useState('')

  const handleClick = (e) => {
    e.preventDefault()
    setMenuOpen(false)
    let href = e.target.dataset.href
    if (home) {
      anchorScroll(href)
    } else {
      const location = {
        pathname: '/',
        state: { section: href }
      }

      history.push(location)
    }
  }

  const handleScroll = () => {
    const { pageYOffset } = window

    if (ref.current) {
      setHeaderFixed(pageYOffset > 0)
    }
  }

  const checkFlag = (val) => {
    setLangActive(val)
    handleLang(val)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    const flag = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : 'ptbr'
    if (!localStorage.getItem('lang')) localStorage.setItem('lang', 'ptbr')
    setLangActive(flag)
  }, [])

  const returnLangs = () => {
    return (
      <>
        {!localStorage.getItem('lang') && (
          <span className='langsSpan'>
            Português - <strong>PT</strong>
          </span>
        )}
        {(langActive === 'ptbr' || localStorage.getItem('lang') === 'ptbr') && (
          <span className='langsSpan'>
            Português - <strong>PT</strong>
          </span>
        )}
        {(langActive === 'us' || localStorage.getItem('lang') === 'us') && (
          <span className='langsSpan'>
            English - <strong>EN</strong>
          </span>
        )}
        {(langActive === 'es' || localStorage.getItem('lang') === 'es') && (
          <span className='langsSpan'>
            Español - <strong>ES</strong>
          </span>
        )}
        {(langActive === 'fr' || localStorage.getItem('lang') === 'fr') && (
          <span className='langsSpan'>
            French - <strong>FR</strong>
          </span>
        )}
        {(langActive === 'de' || localStorage.getItem('lang') === 'de') && (
          <span className='langsSpan'>
            Deutsh - <strong>DE</strong>
          </span>
        )}
      </>
    )
  }

  return (
    <>
      <HeaderTag headerFixed={headerFixed} className={classes} ref={ref}>
        <Container>
          <Row headerFixed={headerFixed}>
            <div className='logo'>
              {classes ? (
                <Link to='/'>
                  <img src='./assets/images/logo.svg' alt='Apllos Solutions' />
                </Link>
              ) : (
                (headerFixed || window.innerWidth <= 990) && (
                  <Link to='/'>
                    <img
                      src='./assets/images/logo.svg'
                      alt='Apllos Solutions'
                    />
                  </Link>
                )
              )}
            </div>

            <ButtonMobile
              onClick={() => setMenuOpen(!menuOpen)}
              open={menuOpen}
            >
              <div />
              <div />
              <div />
              <div />
            </ButtonMobile>
            <NavMobile open={menuOpen}>
              <div className='menu'>
                <div className='services institucional'>
                  <h2>{lang.header.menuMobile.services}</h2>
                  <ul>
                    <li>
                      <Link
                        to={Routes.experienciaDigital}
                        onClick={() => setMenuOpen(false)}
                      >
                        {lang.header.menuMobile.service1}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={Routes.digitalSupplyChain}
                        onClick={() => setMenuOpen(false)}
                      >
                        {lang.header.menuMobile.service2}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={Routes.dados}
                        onClick={() => setMenuOpen(false)}
                      >
                        {lang.header.menuMobile.service3}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className='products institucional'>
                  <h2>{lang.header.menuMobile.products}</h2>
                  <ul>
                    <li>
                      <Link
                        to={Routes.apoHealthCheck}
                        onClick={() => setMenuOpen(false)}
                      >
                        {lang.header.menuMobile.product1}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={Routes.delfos}
                        onClick={() => setMenuOpen(false)}
                      >
                        {lang.header.menuMobile.product2}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className='technology institucional'>
                  <h2>{lang.header.menuMobile.technology}</h2>
                  <ul>
                    <li>
                      <Link
                        to={Routes.delmiaQuintiq}
                        onClick={() => setMenuOpen(false)}
                      >
                        {lang.header.menuMobile.technology4}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={Routes.sapIbp}
                        onClick={() => setMenuOpen(false)}
                      >
                        {lang.header.menuMobile.technology1}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={Routes.salesforce}
                        onClick={() => setMenuOpen(false)}
                      >
                        {lang.header.menuMobile.technology2}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={Routes.mulesoft}
                        onClick={() => setMenuOpen(false)}
                      >
                        {lang.header.menuMobile.technology3}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={Routes.asprova}
                        onClick={() => setMenuOpen(false)}
                      >
                        Asprova
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className='apllos institucional'>
                  <h2>{lang.header.menuMobile.apllos}</h2>
                  <ul>
                    <li>
                      <Link
                        to={Routes.apllos}
                        onClick={() => setMenuOpen(false)}
                      >
                        {lang.header.menuMobile.about}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={Routes.bancoDeTalentos}
                        onClick={() => setMenuOpen(false)}
                      >
                        {lang.header.menuMobile.careers}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={Routes.contato}
                        onClick={() => setMenuOpen(false)}
                      >
                        {lang.header.menuMobile.talk}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={Routes.partners}
                        onClick={() => setMenuOpen(false)}
                      >
                        {lang.header.menu.li5}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className='social institucional'>
                  <a
                    href='https://www.linkedin.com/company/apllos/'
                    target='.blank'
                    rel='noopener'
                  >
                    <img
                      src='./assets/images/footer/linkedinIcon.svg'
                      alt='LinkedIn'
                    />
                  </a>
                </div>
                <div className='flags mobile institucional'>
                  {returnLangs()}
                  <span>
                    <img
                      src='./assets/images/flags/down-arrow.svg'
                      className='arrow'
                      alt=''
                    />
                  </span>
                  <ul>
                    {localStorage.getItem('lang') &&
                      localStorage.getItem('lang') !== 'ptbr' && (
                        <li>
                          <span onClick={() => checkFlag('ptbr')}>
                            Português - <strong>PT</strong>
                          </span>
                        </li>
                      )}
                    {localStorage.getItem('lang') &&
                      localStorage.getItem('lang') !== 'us' && (
                        <li>
                          <span onClick={() => checkFlag('us')}>
                            English - <strong>EN</strong>
                          </span>
                        </li>
                      )}
                    {localStorage.getItem('lang') &&
                      localStorage.getItem('lang') !== 'es' && (
                        <li>
                          <span onClick={() => checkFlag('es')}>
                            Español - <strong>ES</strong>
                          </span>
                        </li>
                      )}
                    {localStorage.getItem('lang') &&
                      localStorage.getItem('lang') !== 'fr' && (
                        <li>
                          <span onClick={() => checkFlag('fr')}>
                            French - <strong>FR</strong>
                          </span>
                        </li>
                      )}
                    {localStorage.getItem('lang') &&
                      localStorage.getItem('lang') !== 'de' && (
                        <li>
                          <span onClick={() => checkFlag('de')}>
                            Deutsh - <strong>DE</strong>
                          </span>
                        </li>
                      )}
                  </ul>
                </div>
              </div>
            </NavMobile>

            <nav className='menuDesktop'>
              <ul>
                <li>
                  <a
                    data-href='#services'
                    onClick={handleClick}
                    href='.'
                    className='hvr-underline-reveal'
                  >
                    {lang.header.menu.li1}
                  </a>
                </li>
                <li>
                  <a
                    data-href='#products'
                    onClick={handleClick}
                    href='.'
                    className='hvr-underline-reveal'
                  >
                    {lang.header.menu.li2}
                  </a>
                </li>
                <li>
                  <a
                    data-href='#technology'
                    onClick={handleClick}
                    href='.'
                    className='hvr-underline-reveal'
                  >
                    {lang.header.menu.li3}
                  </a>
                </li>
                <li>
                  <Link to={Routes.apllos} className='hvr-underline-reveal'>
                    {lang.header.menu.li4}
                  </Link>
                </li>
                <li>
                  <Link to={Routes.partners} className='hvr-underline-reveal'>
                    {lang.header.menu.li5}
                  </Link>
                </li>
                <li>
                  <Link to={Routes.contato} className='hvr-underline-reveal'>
                    {lang.header.menu.li6}
                  </Link>
                </li>
              </ul>

              <div className='flags'>
                {returnLangs()}
                <span>
                  <img
                    src='./assets/images/flags/down-arrow.svg'
                    className='arrow'
                    alt=''
                  />
                </span>
                <ul>
                  {localStorage.getItem('lang') &&
                    localStorage.getItem('lang') !== 'ptbr' && (
                      <li>
                        <span onClick={() => checkFlag('ptbr')}>
                          Português - <strong>PT</strong>
                        </span>
                      </li>
                    )}
                  {localStorage.getItem('lang') &&
                    localStorage.getItem('lang') !== 'us' && (
                      <li>
                        <span onClick={() => checkFlag('us')}>
                          English - <strong>EN</strong>
                        </span>
                      </li>
                    )}
                  {localStorage.getItem('lang') &&
                    localStorage.getItem('lang') !== 'es' && (
                      <li>
                        <span onClick={() => checkFlag('es')}>
                          Español - <strong>ES</strong>
                        </span>
                      </li>
                    )}
                  {localStorage.getItem('lang') &&
                    localStorage.getItem('lang') !== 'fr' && (
                      <li>
                        <span onClick={() => checkFlag('fr')}>
                          French - <strong>FR</strong>
                        </span>
                      </li>
                    )}
                  {localStorage.getItem('lang') &&
                    localStorage.getItem('lang') !== 'de' && (
                      <li>
                        <span onClick={() => checkFlag('de')}>
                          Deutsh - <strong>DE</strong>
                        </span>
                      </li>
                    )}
                </ul>
              </div>
            </nav>
          </Row>
        </Container>
      </HeaderTag>
    </>
  )
}

export default Header
