import React from 'react'
import parse from 'html-react-parser'

import { Container } from '../../components/Layout/styles'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Row, Section, Col } from './styles'

export default function Mulesoft({ lang, handleLang }) {
  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <Section className='banner'>
        <img
          src='./assets/images/mulesoft/screen.png'
          className='screen'
          data-aos='fade'
          data-aos-duration='1500'
          alt=''
        />
        <Container>
          <Row>
            <Col>
              <h1>{parse(lang.mulesoft.title)}</h1>
              <p>{parse(lang.mulesoft.text)}</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className='mulesoft'>
        <Container>
          <Row>
            <h2>{parse(lang.mulesoft.text2)}</h2>
            <h2>{lang.mulesoft.text3}</h2>
            <h2>{lang.mulesoft.text4}</h2>
            <h2>{lang.mulesoft.text5}</h2>
          </Row>
        </Container>
      </Section>

      <Footer lang={lang} />
    </>
  )
}
