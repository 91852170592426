import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
	@import './assets/css/montserrat.css';

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html, body, #root {
        max-height: 100vh;
        max-width: 100vw;
        width: 100%;
        height: 100%;
        font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        /* overflow-x: hidden; */
    }

    body {
        overflow-x: hidden;
    }

    a {
        text-decoration: none;
    }

    button {
        font-family: "Montserrat", sans-serif !important;
    }

    :root {
        --colorPrimary: #5C1F75;
        --colorText: #838383;
        --textPags: #656768;
    }

    .wow { visibility: hidden; }

    .hvr-underline-reveal:before {
        background: var(--colorPrimary);
    }
`
