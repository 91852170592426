import styled from 'styled-components'

export const Button = styled.button`
  background: var(--colorPrimary);
  border-radius: 50px;
  color: #fff;
  outline: none;
  text-decoration: none;
  border: none;
  padding: 10px 26px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-family: 'Monserrat', sans-serif;
  letter-spacing: 1px;
  justify-content: center;

  margin: ${(props) => (props.margin ? props.margin : '0')};

  &:hover {
    box-shadow: 0 0 10px 2px var(--colorPrimary);
    box-shadow: 0 0 8px 0px #5c1f7561;
    background: #894ba2;
  }

  .arrow {
    background: url('./assets/images/arrow.svg') no-repeat;
    width: 13px;
    height: 13px;
    margin-left: 15px;
  }
`
