import React, { useState } from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'

import 'hover.css'

import Routes from './routes'
import ScrollToTop from './scrolltop'
import GlobalStyles from './styles/GlobalStyles'
import Home from './Pages/Home'
import ExperienciaDigital from './Pages/ExperienciaDigital'
import DigitalSupplyChain from './Pages/DigitalSupplyChain'
import APOHealthCheck from './Pages/APOHealthCheck'
import Delfos from './Pages/Delfos'
import Contato from './Pages/Contato'
import Apllos from './Pages/Apllos'
import BancoDeTalentos from './Pages/BancoDeTalentos'
import SAPIbp from './Pages/SAPIbp'
import Salesforce from './Pages/Salesforce'
import Mulesoft from './Pages/Mulesoft'
import Partners from './Pages/Partners'
import Asprova from './Pages/Asprova'
import DelmiaQuintiq from './Pages/DelmiaQuintiq'
import Dados from './Pages/Dados'
// import GA from './utils/googleanalytics'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import AOS from 'aos'
import 'aos/dist/aos.css'

import langs from './langs'

function App() {
  AOS.init()

  const [lang, setLang] = useState(
    localStorage.getItem('lang')
      ? langs[localStorage.getItem('lang')]
      : langs.ptbr
  )
  // const [lang, setLang] = useState(langs.ptbr)
  const handleLang = (val) => {
    setLang(langs[val])
    localStorage.setItem('lang', val)
  }

  return (
    <>
      <Router>
        <ScrollToTop />
        {/*GA.init() && <GA.RouteTracker />*/}
        <Switch>
          <Route exact path={Routes.home}>
            <Home lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.experienciaDigital}>
            <ExperienciaDigital lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.digitalSupplyChain}>
            <DigitalSupplyChain lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.apllos}>
            <Apllos lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.contato}>
            <Contato lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.apoHealthCheck}>
            <APOHealthCheck lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.delfos}>
            <Delfos lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.inteligenciaArtificialAplicada}>
            <Delfos lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.bancoDeTalentos}>
            <BancoDeTalentos lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.sapIbp}>
            <SAPIbp lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.salesforce}>
            <Salesforce lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.mulesoft}>
            <Mulesoft lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.partners}>
            <Partners lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.asprova}>
            <Asprova lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.delmiaQuintiq}>
            <DelmiaQuintiq lang={lang} handleLang={handleLang} />
          </Route>
          <Route path={Routes.dados}>
            <Dados lang={lang} handleLang={handleLang} />
          </Route>
        </Switch>
      </Router>
      <GlobalStyles />
    </>
  )
}

export default App
