import React from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'

import Routes from '../../routes'
import { Container, Row } from '../Layout/styles'
import { FooterTag } from './styles'

function Footer({ lang }) {
  return (
    <Container>
      <Row>
        <FooterTag>
          <div className='logo'>
            <div className='apllos'>
              <div className='logoApllos'></div>
            </div>
            <div className='social-media'>
              <a
                href='https://www.linkedin.com/company/apllos/'
                target='.blank'
                rel='noopener'
              >
                <img
                  src='./assets/images/footer/linkedinIcon.svg'
                  alt='LinkedIn'
                />
              </a>
            </div>
          </div>
          <div className='social institucional'>
            <a
              href='https://www.linkedin.com/company/apllos/'
              target='.blank'
              rel='noopener'
            >
              <img
                src='./assets/images/footer/linkedinIcon.svg'
                alt='LinkedIn'
              />
            </a>
          </div>
          <div className='services institucional'>
            <h2>{parse(lang.footer.services.title)}</h2>
            <ul>
              <li>
                <Link to={Routes.experienciaDigital}>
                  {parse(lang.footer.services.service1)}
                </Link>
              </li>
              <li>
                <Link to={Routes.digitalSupplyChain}>
                  {parse(lang.footer.services.service2)}
                </Link>
              </li>
              <li>
                <Link to={Routes.dados}>
                  {parse(lang.footer.services.service3)}
                </Link>
              </li>
            </ul>
          </div>
          <div className='products institucional'>
            <h2>{parse(lang.footer.products.title)}</h2>
            <ul>
              <li>
                <Link to={Routes.apoHealthCheck}>
                  {parse(lang.footer.products.product1)}
                </Link>
              </li>
              <li>
                <Link to={Routes.delfos}>
                  {parse(lang.footer.products.product2)}
                </Link>
              </li>
            </ul>
          </div>
          <div className='technology institucional'>
            <h2>{parse(lang.footer.technology.title)}</h2>
            <ul>
              <li>
                <Link to={Routes.delmiaQuintiq}>Delmia Quintiq</Link>
              </li>
              <li>
                <Link to={Routes.sapIbp}>SAP IBP</Link>
              </li>
              <li>
                <Link to={Routes.salesforce}>Salesforce</Link>
              </li>
              <li>
                <Link to={Routes.mulesoft}>Mulesoft</Link>
              </li>
              <li>
                <Link to={Routes.asprova}>Asprova</Link>
              </li>
            </ul>
          </div>
          <div className='apllos institucional'>
            <h2>{parse(lang.footer.apllos.title)}</h2>
            <ul>
              <li>
                <Link to={Routes.bancoDeTalentos}>
                  {parse(lang.footer.apllos.text1)}
                </Link>
              </li>
              <li>
                <Link to={Routes.contato}>
                  {parse(lang.footer.apllos.text2)}
                </Link>
              </li>
            </ul>
          </div>
          <div className='greatPlace institucional'>
            <div className='seloGptw'></div>
          </div>
        </FooterTag>
      </Row>
    </Container>
  )
}

export default Footer
