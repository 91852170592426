import styled from 'styled-components'

export const Collapse = styled.div`
  /* margin: 0 auto;
  font-size: 1.6rem; */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  padding: 3rem 1rem 1rem; */
  box-shadow: 9px 1px 5px -10px #979797;
  border-radius: 13px;
  margin-bottom: 20px;
  &.collapsed {
    /* box-shadow: 0 0 10px 2px #979797; */
    /* border-right: 2px solid #9797972e;
    border-bottom: 2px solid #9797972e; */
    box-shadow: 11px 11px 9px -11px #97979782;
    .app__toggle {
        border-color: transparent;
    }
  }

  .__text {
    width: 100%;
  }

  .app__collapse {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);

    &--gradient {
      .app__content {
        border-color: transparent;
      }

      &:after {
        pointer-events: none;
        transition: opacity 300ms;
        will-change: opacity;
        opacity: 1;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent 50%, white);
      }
    }

    &--active {
      &:after {
        opacity: 0;
      }
    }
  }

  .app__content {
    border: 1px solid transparent;
    padding: 20px 60px;
    margin-bottom: 3rem;
    position: relative;
    transition: 300ms;
    p {
        line-height: 1.8;
        color: var(--textPags);
    }
  }

  .app__toggle {
    width: 100%;
    margin: 1rem 0;
    border: 1px solid transparent;
    padding: 20px 41px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    background-color: #fff;
    transition: background-color 300ms;
    outline: none;
    color: var(--colorPrimary);
    font-size: 22px;
    cursor: pointer;
    &.padding {
      padding: 20px 41px 20px 0;
    }
    &--active {
      /* background-color: #fafafa; */
    }
  }

  .app__toggle-text {
    font-size: inherit;
  }

  .app__button {
    display: block;
    width: 20rem;
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }


    .icon {
        width: 18px;
        height: 18px;
        transition: transform 300ms cubic-bezier(0, 1, 0, 1);
        will-change: transform;
        &--expanded {
            transform: rotateZ(-179.99deg);
        }
    }

    .rotate90 {
        transform: rotateZ(90deg);
    }

    @media(max-width: 576px) {
      .app__toggle {
        font-size: 17px;
      }
    }
`

/* export const Icon = styled.svg`
    width: 1em;
    height: 1em;
    transition: transform 300ms cubic-bezier(0, 1, 0, 1);
    will-change: transform;
    &--expanded {
        transform: rotateZ(-179.99deg);
    }
`

export const Rotate90 = styled.div`
  .rotate90 {
    transform: rotateZ(90deg);
    }
` */

