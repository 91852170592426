import styled from 'styled-components'

import { Row as RowColumn } from '../../components/Layout/styles'

export const Row = styled(RowColumn)`
  /* display: flex; */
  /* align-items: center; */
  &.col {
    display: flex;
    h2 {
      margin-right: 200px;
    }
    &.delfos {
      h2 {
        margin-right: 233px;
      }
    }
  }

  @media (max-width: 576px) {
    &.col {
      flex-direction: column;
      h2 {
        margin: 0 0 20px 0;
      }
      &.delfos {
        h2 {
          margin: 0 0 20px 0;
        }
        p {
          margin: 0;
        }
      }
    }
  }
`

export const Section = styled.section`
  display: flex;
  margin: 90px 0 0;
  &.banner {
    margin: 0 auto;
    height: 737px;
    align-items: center;
    background: url('./assets/images/IAAplicada/banner.jpg') no-repeat top
      center;
    background-size: cover;
    h1 {
      color: var(--colorPrimary);
      font-size: 42px;
      font-weight: 500;
      /* margin-bottom: 55px; */
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: -30px;
        left: 0;
        width: 990px;
        border-bottom: 2px solid var(--colorPrimary);
        z-index: 1;
      }
    }

    h4 {
      color: var(--colorPrimary);
      margin-bottom: 25px;
      line-height: 1.6;
      font-size: 16px;
      position: relative;
      font-weight: 600;
    }
    p {
      line-height: 1.6;
    }

    .screen {
      position: absolute;
      right: 0;
      top: 17%;
      /* width: 60%; */
      z-index: 2;
    }
  }

  h2 {
    &.hidden {
      visibility: hidden;
    }
  }

  h3 {
    color: var(--colorPrimary);
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
  }

  p {
    font-size: 16px;
    color: var(--textPags);
    line-height: 1.6;
    margin-bottom: 54px;
  }

  @media screen and (max-width: 1024px) {
    &.banner {
      .screen {
        width: 546px;
        top: 25%;
      }
    }

    p {
      br {
      }
    }
  }

  @media screen and (max-width: 990px) {
    &.banner {
      .screen {
        width: 510px;
        top: 28%;
      }
    }
  }

  @media (max-width: 768px) {
    &.banner {
      flex-direction: column;
      height: auto;
      padding: 137px 0 0;
      align-items: flex-end;
      .screen {
        position: relative;
        width: 96%;
        margin-bottom: 40px;
      }
      h1 {
        font-size: 30px;
        br {
          display: none;
        }
        &:before {
          width: 100%;
        }
      }
    }
    h2 {
      font-size: 20px;
      &.hidden {
        visibility: visible;
      }
    }

    p {
      font-size: 15px;
      br {
        display: none;
      }
    }
  }

  @media (max-width: 576px) {
    &.banner {
      .screen {
      }
    }
  }
`

export const Col = styled.div`
  /* display: flex;
    flex-direction: column; */
  p {
    &.last {
      margin-bottom: 40px;
    }
  }
`
