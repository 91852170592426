import emailjs from 'emailjs-com'
import { slugify } from '../utils/uglify'

export const SendEmail = async (type, body, file, fileBase64) => { 
    const serviceID = "service_2rtyqq7";
    const userID = "fcNB3RFYcysMAo9Gd";

    if (type === "bancoDeTalentos") {

        const cvTemplateID = "template_fnxjd54";
        const { name, email, phone, expertise } = body

        const file_name = slugify(file.name);

        const data = {
            name,
            email,
            phone,
            expertise,
            file: fileBase64,
            file_name
        }
        return emailjs.send(
            serviceID,
            cvTemplateID,
            data, 
            userID
        )
    } 
    else {
        const contactTemplateID = "template_ywiqs6j";
        const subject = type === "apoHealthCheck" 
            ? "APO Health Check"
            : "Contato"

        const { name, email, message } = body

        const data = {
            subject,
            name,
            email,
            message,
        }

        return emailjs.send(
            serviceID,
            contactTemplateID,
            data, 
            userID
        )
    }
}
