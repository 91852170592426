import styled from 'styled-components'

export const Title = styled.h1`
  color: var(--colorPrimary);
  font-size: 50px;
  margin-bottom: 25px;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`

export const Text = styled.p`
  font-style: italic;
  font-size: 16px;
  line-height: 2;
  color: var(--textPags);
  margin-bottom: 20px;
  &.normalStyle {
    font-style: normal;
  }

  @media (max-width: 768px) {
    br {
      display: none;
    }
  }
`

export const H2Title = styled.h2`
  color: var(--textPags);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 20px;
  width: 84%;
  margin: 0 auto 20px;
  strong {
    color: var(--colorPrimary);
    font-weight: 500;
  }
  &.full {
    width: 100%;
    font-size: 22px;
    /* line-height:  */
  }

  @media screen and (max-width: 990px) {
    &.full {
      font-size: 19px;
    }
  }

  @media screen and (max-width: 576px) {
    &.full {
      font-size: 17px;
    }
  }
`

export const H2Text = styled.h2`
  color: var(--textPags);
  position: relative;
  font-weight: 400;
  margin: 0 auto;
  margin-bottom: 60px;
  font-size: 16px;
  line-height: 1.6;
  width: 88%;

  &.gray {
    &:before {
      content: '';
      position: absolute;
      bottom: -30px;
      left: 0;
      width: 77px;
      border-bottom: 2px solid #d8d8d8;
    }
  }
  &.purple {
    font-size: 21px;
    line-height: 1.2;
    width: 84%;
    color: var(--colorPrimary);
    font-weight: 500;
    &:before {
      content: '';
      position: absolute;
      top: -4px;
      height: 110%;
      border-left: 2px solid var(--colorPrimary);
      border-bottom: none;
      bottom: auto;
      left: -21px;
    }
  }

  @media (max-width: 576px) {
    &.purple {
      font-size: 20px;
    }
    br {
      display: none;
    }
  }
`
