export default {
  header: {
    menu: {
      li1: 'Serviços',
      li2: 'Produtos',
      li3: 'Tecnologias',
      li4: 'A Apllos',
      li5: 'Parceiros',
      li6: 'Contato'
    },
    menuMobile: {
      services: 'Serviços',
      service1: 'Experiência Digital',
      service2: 'Digital Supply Chain',
      service3: 'Dados',
      products: 'Produtos',
      product1: 'APO Health Check',
      product2: 'Delfos',
      technology: 'Tecnologias',
      technology1: 'SAP IBP',
      technology2: 'Salesforce',
      technology3: 'Mulesoft',
      technology4: 'Delmia Quintiq',
      apllos: 'A Apllos',
      about: 'Conheça',
      partners: 'Parceiros',
      careers: 'Carreiras',
      talk: 'Fale com a gente'
    }
  },

  footer: {
    services: {
      title: 'Serviços',
      service1: 'Experiência Digital',
      service2: 'Digital Supply Chain',
      service3: 'Dados',
    },
    products: {
      title: 'Produtos',
      product1: 'APO Health Check',
      product2: 'Delfos'
    },
    technology: {
      title: 'Tecnologias'
    },
    apllos: {
      title: 'A Apllos',
      text1: 'Carreiras',
      text2: 'Fale com a gente'
    }
  },

  formContact: {
    nameInput: 'Nome',
    namePlaceholder: 'Seu nome',
    phoneInput: 'Telefone',
    expertiseInput: 'Especialidade',
    uploadCV: 'Upload do CV',
    removeCV: 'Remover arquivo',
    messageInput: 'Mensagem',
    messagePlaceholder: 'Sua mensagem',
    requiredField: 'Campo Obrigatório',
    send: 'Enviar',
    typeFile: 'Arquivo em .pdf',
    titleHome: 'Contato',
    title: 'Fale conosco',
    title2: 'Inscreva-se em nosso<br /> Banco de Talentos',
    fileAlert: 'Formato de arquivo inválido',
    fileSizeAlert: 'Tamanho máximo 500kb',
    success: 'Sucesso',
    error: 'Erro',
    emailSent: 'Email Enviado',
    emailFail: 'Tente novamente, mais tarde!'
  },

  home: {
    banner: {
      titleDesk: `Evolução digital na Gestão <br /> da Cadeia de Valor`,
      titleMobile: `Evolução digital<br/> na Gestão da<br/> Cadeia de Valor`,
      textDesk: `Entregamos soluções para empresas inteligentes, <br /> 
                            utilizando tecnologias inovadoras para viabilizar  <br />
                            novos modelos de negócios resilientes e flexíveis.`,
      textMobile: `Entregamos soluções<br /> para empresas<br /> inteligentes, 
                            utilizando<br/> tecnologias inovadoras<br /> para viabilizar 
                            novos<br/> modelos de negócios<br/> resilientes e flexíveis.`
    },
    
    dataBanner: {
      titleDesk: `Dados`,
      titleMobile: `Dados`,
      textDesk: `Atuamos com o fluxo de dados ponta a ponta <br /> 
                  para o seu negócio desde a ingestão até <br />
                  a construção de modelos inteligente.`,
      textMobile: `Atuamos com o fluxo <br />
                  de dados ponta a ponta <br /> 
                  para o seu negócio desde <br />
                  a ingestão até a construção <br />
                  de modelos inteligente.`
    },

    services: {
      subtitle: 'Nossos',
      title: 'Serviços',
      service1Title: 'Experiência Digital',
      service1Text:
        'Projetamos a experiência digital para criar e otimizar <br /> processos de negócios, cultura de trabalho e relações <br /> com os clientes',
      service2Title: 'Digital Supply Chain',
      service2Text: `Aplicamos todas as soluções inovadoras para a performance <br /> da Cadeia de Valor a partir de uma abordagem estratégica, <br /> orientada a dados e pessoas.`,
      service3Title: 'Dados',
      service3Text: `Atuamos com o fluxo de dados ponta a ponta <br /> 
                    para o seu negócio desde a ingestão até <br />
                    a construção de modelos inteligente.`,
      button: 'Veja Mais',
    },

    apllos: {
      title: 'simples, adequado',
      text: `
                        Acreditamos em ir além da transformação digital, buscando soluções simples aos <br /> desafios 
                        complexos do negócio, para que as organizações operem na velocidade e <br /> escala necessárias.
                    `,
      button: 'Conheça'
    },

    products: {
      subtitle: 'Nossos',
      title: 'Produtos',
      product1Title: 'DASHBOARD MONITOR',
      product1Text: 'APO Health Check',
      product2Title: 'I.A. aplicada à previsão de demanda',
      product2Text: 'Delfos'
    },

    technology: {
      subtitle: 'Nossas',
      title: 'Tecnologias',
      text: `Trabalhamos com um ecossistema de tecnologias. <br />
                    Abaixo as principais tecnologias dos nossos parceiros
                `
    },

    clients: {
      subtitle: 'Nossos',
      title: 'Clientes'
    },

    contactUs: {
      subtitle: 'Fale',
      title: 'Conosco',
      text: 'Av. Queiroz Filho, 1700 CJ. D-9 <br /> Vila Leopoldina | São Paulo-SP',
      text2: 'contato@apllos.com'
    }
  },

  digitalExperience: {
    banner: {
      title: 'Experiência Digital',
      text: `Criamos a experiência digital para otimizar processos de <br /> 
                        negócios, cultura de trabalho e relações com os clientes; <br /> 
                        respondendo às mudanças do mercado na era digital.
                    `,
      tag1: 'Design Thinking',
      tag2: 'Metodologias ágeis',
      tag3: 'Métricas',
      tag4: 'Cases'
    },

    designThinking: {
      title: `Utilizamos o <strong>Design thinking</strong>, abordagem que promove inovação através de um <br /> 
                        processo estruturado para desenvolvimento de projetos mais assertivos, acelerando a <br /> 
                        adoção da mentalidade digital na empresa. `,
      title2: 'A abordagem inclui duas fases: Entender e Explorar.'
    },

    steps: {
      step1: {
        title: '1° <span>entender</span>',
        imersion: 'Imersão',
        imersionText:
          'Conduzir a pesquisa para <br /> desenvolver um <br /> <strong>entendimento do usuário e <br /> do negócio</strong>',
        define: 'Definir',
        defineText: `Analisar toda a pesquisa e <br /> identificar <strong>onde existem <br /> problemas e oportunidades <br />
                                para seus usuários e negócio</strong>`
      },

      step2: {
        title: '2° <span>explorar</span>',
        ideation: 'Ideação',
        ideationText: `Gerar várias <br />
                                    <strong>idéias</strong> criativas para a  <br />
                                    solução do problema`,
        prototyping: 'Prototipação',
        prototypingText: `Construir um protótipo <br /> 
                        <strong>funcional</strong>, para suas <br /> 
                        principais idéias 
                    `,
        tests: 'Testes',
        testsText: `<strong>Testar com os usuários</strong> <br />
                        para colher feedback e <br />
                        melhorar 
                    `
      }
    },

    about: {
      title: ` Possuimos um time multidisciplinar que dentro de um <br /> ecossistema de tecnologia cria soluções de ponta a ponta.`,
      text: `Em cada solução definimos métricas de sucesso, analisando <br />
                    padrões de comportamento dos usuários e objetivos do negócio, <br /> direcionando decisões baseadas em dados e impulsionando o<br /> crescimento sustentável.
                `
    }
  },

  digitalSupplyChain: {
    banner: {
      title: 'Digital Supply Chain',
      text: 'Empresas resilientes para um mercado volátil e incerto',
      tag1: 'Dassault Systemès',
      tag2: 'Asprova',
      tag3: 'OMP',
      tag4: 'DDMRP',
      tag5: 'SAP APO',
      tag6: 'SAP IBP',
      tag7: 'SAP S4/Hana',
      tag8: 'Sap Bussiness one',
      tag9: 'Salesforce'
    },

    section: {
      title1: `
                    Para aumentar a capacidade de reação da cadeia, a melhoria da 
                    previsibilidade deve estar<br /> orquestrada com a resposta rápida e 
                    flexível no curto prazo.  
                `,
      title2: `O trabalho é realizado a partir de um time multidisciplinar com conhecimento em<br /> diferentes tecnologias e modelos de negócio. `,
      title3: `A solução é pensada de maneira sistêmica, mas sobre tudo<br /> respeitando as áreas e a experiência da empresa.`,
      title4: `
                    Essas interações são guiadas por metodologias que promovem a 
                    cultura de inovação e o<br /> engajamento. As tecnologias são sugeridas 
                    com base em um ecossistema de plataformas<br /> e startups. 
                `,
      title5: `
                    Essa cultura permite a construção de soluções com mais aderência
                    aos requerimentos do<br /> negócio. Além disso, o conceito de 
                    arquitetura em nuvem e códigos abertos possibilitam<br /> a criação de soluções customizadas.
                `,
      title6: `Os processos são modelados para trazer resiliência,<br /> sustentabilidade e otimização à cadeia de valor.`,
      title7: `
                    Para garantir a captura de valor as soluções são validadas por dados 
                    e provas de conceito<br /> (POCs), gerando escala, flexibilidade e a melhor
                    experiência para as pessoas.
                `
    }
  },

  data: {
    banner: {
      title: 'Dados',
      text: `A Apllos atua com o fluxo de dados ponta a ponta <br />
            para o seu negócio desde a ingestão até a construção <br />
            de modelos inteligentes.`
    },
    section: {
      text1: `Entendemos o seu negócio para construção de um repositório centralizado de dados em nuvem ou onpremise
        que permita a ingestão de dados semi-estruturados, estruturados e não estruturados de diferentes fontes utilizando de
        tecnologia batch ou real time. Esse processo é realizado de forma segura, confiável e automatizada. A construção desse
        repositório permite que os dados sejam analisados e tratados gerando camadas para utilização em modelos de ciência e análise
        de dados.`,
      text2: `Com os nossos cientistas e analistas de dados é possível o entendimento de comportamentos, monitoramento, "insights",
        previsões e predições para apoiar nas decisões de negócio.`,
      text3: `Atuamos com diferentes ferramentas de BIG data como o ecossistema hadoop e soluções cloud - AWS, GCP, Databricks e Azure -
        que possuem serviços com baixo custo de armazenamento e funcionalidades para o processamento de altos volumes de dados.`
    }
  },

  apllos: {
    banner: {
      words: ['A Apllos', 'A Απλος', 'simples', 'adequado'],
      title: `
                    Somos um time multidisciplinar composto por mais de 50 colaboradores <br />
                    entre designers, engenheiros, cientistas de dados e desenvolvedores. <br />
                    Entregamos soluções inovadoras para empresas de diversos setores.
                `
    },
    about: {
      professionals: 'Profissionais',
      clients: 'Clientes',
      projects: 'Projetos',
      text: `
                    Nossos projetos sempre têm a participação ativa
                    de uma equipe<br /> experiente, em uma estrutura horizontal. <br /><br />
                    Dessa forma, a co-criação com os clientes e parceiros, 
                    fornece<br /> transparência e segurança em cada desafio.`
    },
    info: {
      organization: 'Organização',
      orgTitle: `Estrutura <br />horizontal`,
      orgText: `
                    Alinhamento constante entre<br /> 
                    todos os envolvidos, com<br /> 
                    cultura aberta a novas ideias e<br /> soluções.
                `,
      team: 'Equipe',
      teamTitle: 'Especialistas<br /> comprometidos',
      teamText: `
                    Especialistas comprometidos<br /> 
                    com a qualidade da solução <br />
                    e prazos das entregas.
                `,
      focus: 'Foco',
      focusTitle: 'Soluções<br /> inovadoras',
      focusText: `
                    Aplicação das soluções mais<br /> avançadas e adequadas para <br />a evolução do negócio.
                `
    }
  },

  hire: {
    title: 'Trabalhe<br /> com a gente',
    text: `
                Aqui na Apllos, existem desafios e 
                espaços para<br /> 
                diferentes perfis em um ambiente de inovação<br /> 
                e tecnologia. 
            `,
    link1: 'Confira as vagas no nosso',
    link2: 'ou inscreva-se no nosso',
    talents: 'Banco de Talentos'
  },

  apoHealthCheck: {
    banner: {
      title: 'APO <br /> Health Check',
      text: `
                    O sistema de planejamento APS - SAP<br /> 
                    APO como todos sistemas necessita 
                    de um<br /> monitoramento de rotina para 
                    prevenção<br /> de problemas e ação em 
                    casos de falha. 
                `
    },
    section: {
      title: 'Início',
      text: `
                    O produto da Apllos – APO Health Check tem como objetivo
                    mapear as atividades no<br /> sistema SAP APO que impactam 
                    o negócio e alertar preventivamente ações para<br /> os usuários. O 
                    monitoramento é customizado e os alertas enviados por e-mail 
                    com<br /> mensagens que auxiliam na identificação das ações.
                `,
      text2: `O que o produto da Apllos monitora:`
    },
    monitor: {
      title: 'Monitor',
      collapse1Title: 'CIF (Core interface)',
      collapse1Text: `<p>CIF (Core Interface): A interface de dados mestres e transacionais entre o sistema de 
                        APS (SAP APO) com o ERP (SAP ECC) é conhecido como CIF. O APO Health Check mostra a 
                        quantidade de objetos inconsistentes nessa interface além de registrar o histórico 
                        desses dados. Alertas são disparados quando o ambiente possuí um número crítico de 
                        objetos com problemas. </p>`,
      collapse2Title: 'Armazenamento de Log',
      collapse2Text: `<p>Algumas transações gravam suas execuções para análise posterior do usuário em uma 
                        área de armazenamento do ambiente. Quando o espaço delimitado para o armazenamento 
                        alcança uma ocupação crítica é necessária a atuação para apagar ou arquivar o 
                        conteúdo. O APO Health Check avisa quando o percentual utilizado desse armazenamento 
                        alcança um limite crítico. </p>`,
      collapse3Title: 'Live Cache',
      collapse3Text: `<p>O SAP APO trabalha com dois tipos de memória, banco de dados e “LiveCache”. O “Livecache” é imprescindível
                        para o funcionamento do ambiente. O APO Health check monitora a sua disponibilidade e em caso de 
                        inatividade acionará o responsável.  </p>`,
      collapse4Title: 'Cadeia de processo',
      collapse4Text: `<p>O SAP APO monitora quando cadeias de processo, as “Process Chains” possuem o status de cancelamento 
                        ou tempos de execuções excessivas através de e-mail.</p>`,
      collapse5Title: 'Dumps',
      collapse5Text: `<p>Falhas em algoritmos desenvolvidos no sistema (SAP APO) são armazenados para correção e análise. 
                        O APO Health Check monitora a quantidade dessas falhas e alerta quando problemas ocorrem 
                        com frequência.</p>`,
      collapse6Title: 'Utilização de memória',
      collapse6Text: `<p>O APO Health Check permite monitor quando uma execução estiver consumindo um recurso excessivo do ambiente. 
                        Com isso, falhas no processo de execução de uma rotina como falta de filtro e falhas no desempenho de
                        um programa serão identificados o que evita a lentidão do ambiente.</p>`
    },

    form: {
      title: 'Navegue em nosso programa APO Health Check',
      paragraph: 'Preencha o formulário e receba o link de acesso',
      message: 'Quero receber o link de acesso ao APO Health Check'
    }
  },

  aiForecast: {
    banner: {
      title: `Delfos`,
      subtitle: 'I.A. aplicada à previsão de demanda',
      text: `
                    O Delfos é uma solução para previsão de demanda <br /> 
                    em alto nível baseada em diversos algoritmos de <br /> 
                    regressão e modelos de machine learning. 
                `
    },
    delfos: {
      text: `
                    O Delfos é a solução para quem deseja utilizar ferramentas avançadas <br /> 
                    de inteligência artificial para previsão de séries temporais, sem a <br /> 
                    necessidade de possuir experiência em machine learning ou em <br /> programação. 
                `
    },

    resources: {
      title: 'Recursos',
      colTitle1: 'Ajuste automático da série temporal',
      colText1: `
                    Aplicação automática de métodos estatísticos para ajuste e <br /> 
                    transformação dos componentes da série temporal, como tendência, <br /> 
                    sazonalidade, ruídos e auto-correlação. Esses métodos implicam em <br /> 
                    uma melhoria no resultado dos algoritmos. 
                `,
      colTitle2: 'Best Fit',
      colText2: `
                    Mais de 20 algoritmos, regressivos e de machine learning, executados <br /> 
                    simultaneamente.  <br />
                    O Delfos se encarrega de determinar qual o modelo adequado para <br /> 
                    cada nível de previsão.  
                `,
      colTitle3: 'Forecast Hierárquico',
      colText3: `
                    Execuções em qualquer nível da hierarquia temporal e estrutural. É <br /> 
                    possível, por exemplo, elaborar uma previsão por região e depois por <br /> 
                    cliente, utilizando frequências temporais mensais e semanais. 
                `,
      colTitle4: 'Como Funciona',
      colText4: `
                    O Delfos funciona como um serviço gerenciado, utilizando a base de <br /> 
                    dados histórica.  <br />
                    O sistema identifica a correlação entre as variáveis e desenvolve <br /> 
                    modelos de previsão personalizados e adequados para cada cenário <br /> 
                    encontrado. 
                `,
      colText5: `
                    A Apllos se encarrega de prover toda a infraestrutura necessária para <br /> 
                    as execuções, sem necessidade de outros custos para a empresa. 
                `,
      colText6: `
                    Basta carregar seus dados para obter melhores resultados em seu <br /> 
                    processo de previsão de demanda, utilizando técnicas avançadas de <br /> 
                    inteligência artificial. 
                `
    }
  },

  contact: {
    title: 'Contato',
    text: 'Será uma honra construirmos algo juntos'
  },

  talents: {
    title: 'Banco de Talentos',
    text: `
                Aqui na Apllos, existem desafios e espaços<br /> 
                para diferentes perfis em um ambiente de<br /> 
                inovação e tecnologia.
            `,
    check: `Confira as vagas no nosso`
  },

  sapIbp: {
    title: 'SAP Integrated<br /> Business Planning',
    text: `O SAP IBP é uma solução completa para o planejamento
                integrado da<br /> cadeia de valor. A plataforma foi
                construída em uma base de dados<br /> em nuvem e memória do
                SAP HANA, que permite um<br /> processamento de dados em
                tempo real.`,
    text2: `A Apllos possui como diferencial uma abrangente experiência na implementação de processos
                de planejamento e sistemas APS além de um time multidisciplinar para desenvolvimento de
                interfaces em múltiplas plataformas, análise de dados e definição de processo para co-criação
                de uma solução que traga valor ao cliente.`,
    text3: `O SAP IBP é uma solução completa para o planejamento integrado da cadeia de valor. Ele combina
                recursos de vendas e operações, demanda, planejamento de suprimentos, torre de controle de cadeia
                e otimização do estoque. A plataforma é integrada através do SAP HANA Cloud Integration com as 
                mais variadas arquiteturas de tecnologias permitindo dados sempre atualizados para o planejamento.
            `,
    text4: `A sua interface foi desenvolvida para trabalhar com plataforma web, Fiori e Excel facilitando sua 
                usabilidade e iteração entre os processo do negócio.
            `,

    services1Title: 'Supply Chain Control Tower',
    services1Text: `Plataforma de visibilidade da cadeia de suprimentos em tempo real para o
                suporte a decisão e ações corretivas rápidas.<br />
                <strong>Principais benefícios</strong>
            `,
    services1List: {
      text1: `Visibilidade end-to-end em toda a cadeia de suprimentos, incluindo
                    alertas, suporte a decisão e ações corretivas rápidas;
                `,
      text2: `Diminuição dos níveis de estoques globais, redução de riscos;`,
      text3: `Aumento de desempenho do tempo de entrega para o cliente;`,
      text4: `Colaboração, comunicação e monitoramento da cadeia de suprimentos;`,
      text5: `Aumento a agilidade de fornecimento e redução dos custos da cadeia de abastecimento`
    },

    services2Title: 'IBP For Sales and Operations',
    services2Text: `O IBP S&OP é uma plataforma de planejamento colaborativo de demanda e cálculo de previsão
                estatística através de modelos matemáticos simples. A ferramenta utiliza-se de dados flexíveis e 
                unificados da cadeia de suprimentos para suportar o planejamento tático e estratégico em qualquer
                nível de granularidade e dimensão.
            `,
    services2Text2: `No caso de um planejamento mais avançado de demanda, é recomendada a utilização do IBP Demand<br />
                <strong>Funcionalidades Chave</strong>
            `,
    services2List: {
      text1: `Planejamento colaborativo de demanda e previsão estatística simples;
                `,
      text2: `Planejamento infinito multi nível;`,
      text3: `Ajuste manual do planejamento de operação e suas variáveis para análise e simulação;`,
      text4: `Utilização de heurística que leva em consideração: Lead time de transporte, estoque atual, 
                    estoque de segurança, estoque alvo, lote mínimo, valor de arredondamento, entre outros parametros;`,
      text5: `Ambos os processos trabalham com informações integradas do ERP e permitem análises e simulação do plano 
                    completo e delta;`,
      text6: `Para considerar capacidade finita dos recurso, restrições de estoque e outras modelagens mais avançadas é 
                    recomendada a utilização do IBP Responde and Supply.`
    },

    services3Title: 'IBP For Demand',
    services3Text: `Planejamento da demanda estatística no curto, médio e longo prazo em uma solução única. A plataforma utiliza-se 
                de tratamento de dados, classificação de comportamento, agregação e desagregação além de modelos matemáticos
                avançados como os de inteligência artificial e regressão para a previsão de demanda.
            `,
    services3Text2: `A plataforma suporta a criação da demanda consensada através do planejamento colaborativo e disponibiliza informações
                relevantes para a análise do plano<br />
                <strong>Funcionalidades Chave</strong>
            `,
    services3List: {
      text1: `Demand-sensing e previsão de médio/longo prazos em uma solução única de planejamento de demanda;`,
      text2: `Processo de planejamento automatizado e baseado em exceções, assim como funcionalidades de planejamento manual;`,
      text3: `Análise de demanda integrada e dinâmica;`,
      text4: `Limpeza de histórico e correção de outliers;`,
      text5: `Previsão estatística com modelos avançados disponíveis;`,
      text6: `Agregação/Desagregação dos dados Time Series;`,
      text7: `Planejamento colaborativo de demanda;`,
      text8: `Geração de alertas;`,
      text9: `Utlização de Dashboards para suporte ao planejamento`
    },

    services4Title: 'IBP For Inventory',
    services4Text: `O SAP IBP inventory possuí um sofisticado algoritmo de otimização para definir a melhor política de estoque 
                considerando todos os elos da cadeia de suprimento e parâmetros como custo, variabilidades e nível de serviço.`,
    services4Text2: `Alguns parâmetros utilizados no algoritmo:`,
    services4List: {
      text1: `Variabilidade da demanda`,
      text2: `Erro de previsão`,
      text3: `Desvio de suprimentos`,
      text4: `Custos`,
      text5: `Leadtimes`,
      text6: `Elos da cadeia de suprimentos`,
      text7: `Posicionamento dos estoques ao longo da cadeia`
    },

    services5Title: 'IBP For Resp N Supply',
    services5Text: `O SOP Response and Supply planning é a solução para o planejamento e alocação da cadeia de suprimento. 
                A ferramenta possuí diferentes modelos heurísticos e otimização para criação de cenários e ordens para o planejamento 
                de produção, distribuição e reabastecimento.`,
    services5Text2: `Funcionalidades chaves:`,
    services5List: {
      text1: `Otimização com função objetivo para maximização do lucro ou nível de serviço`,
      text2: `Heurística`,
      text3: `Planejamento finito multinível`,
      text4: `Prioridades gerenciadas por custos`,
      text5: `Estoque de segurança e estoque máximo`,
      text6: `Custos de entrega tardia, violação do estoque de segurança, e estoque por unidade, custos de produção, transporte, compras por unidade ou fixo, custo de produção, entre outros`,
      text7: `Geração de ordens de suprimento (produção, compras, distribuição) e integração com o ERP próxima ao tempo real através de uma nova solução`
    }
  },

  salesforce: {
    title: 'Salesforce',
    text: `CRM Salesforce é uma plataforma que possui <br />
            software líder em gestão e relacionamento com <br />
            clientes, sendo o mais vendido no mundo.`,
    text2: `A ferramenta gerencia questões fundamentais para o negócio, como funil de vendas,
            leads e acompanhamento de tarefas.`,
    text3: `Salesforce possui toda infra-estrutura em nuvem, o que possibilita o vendedor acessar
            as informações do seu cliente de qualquer dispositivo que esteja conectado a internet.
            `,
    text4: `O Salesforce foi concebido para facilitar o desenvolvimento trazendo rapidez e baixo
            custo na implementação. Suas licenças permitem o uso por pequena, médias e grandes empresas.
            `,
    text5: `O Salesforce possui diversas soluções para o seu negócio e os principais são:
            Sales Cloud, Service Cloud, marketing cloud, dentre outras.
            `,
    services1Title: 'Sales Cloud',
    services1Text: `É uma solução completa com ampla funcionalidades para tornar mais competitivo o trabalho
            das equipes de marketing de vendas.
            `,
    services1Text2: `Benefícios
            `,
    services1List: {
      text1: `Possibilidade de ter uma visão 360° do cliente, gerando confiança, garantindo a venda
                e melhorando o relacionamento pós-venda;
                `,
      text2: `Melhora o engajamento dos vendedores e parceiros criando experiências que melhoram o processo
                de venda com informações claras relevantes;`,
      text3: `É possível acompanhar em tempo real os momentos de decisão de compra de um possível cliente
                dentro da organização;`,
      text4: `Viabilidade de automatizar ações e processos;`,
      text5: `A ferramenta permite controlar informações sobre concorrentes bem como contratos e negociações.`
    },

    services2Title: 'Service Cloud',
    services2Text: `É uma solução feita para atender as requisições de seus clientes seu produto ou serviço. Essa
            ferramenta proporciona sua empresa reter seus clientes, gerando um valor para sua marca.
            `,
    services2Text2: `Benefícios
            `,
    services2List: {
      text1: `Aumenta a satisfação dos seus clientes;
                `,
      text2: `Pode ajudar as empresas a otimizar seus processos e chamadas, reduzindo a resolução de seus problemas e
                melhorando drasticamente a satisfação do cliente;`,
      text3: `Gestão de casos, acesso dos clientes através de canais, integrações com sistemas legados, tíquete suporte,
                base de conhecimento, roteamento e escalação, e gestão de filas de atendimento;`,
      text4: `Permite falar com os clientes através do canal de sua preferência, seja por telefone, SMS(mensagens de texto),
                WhatsApp ou pelo Facebook Messenger;`,
      text5: `Aumenta a reputação da empresa e incrementa as oportunidades de vendas;`,
      text6: `Possibilitar definir metas e acompanhamento dos resultados.`
    },

    services3Title: 'Marketing Cloud',
    services3Text: `Plataforma para criação de jornadas relevantes e personalizadas para seu público-alvo nos canais e 
            dispositivos certos.
            `,
    services3Text2: `Benefícios`,
    services3List: {
      text1: `É possível conectar dados de diferentes fontes e dispositivos a esta plataforma, de forma a obter uma visão
                única do cliente. E também, você será capaz de capturar e ativar dados primários, secundários e de terceiros;`,
      text2: `Fornece de dados para a ferramenta de inteligência artificial da Salesforce - o Einstein. As análises realizadas
                permitem interações organizadas e personalizada com o cliente;`,
      text3: `Gera atenção e consciência durante todo o processo(um engajamento bidirecional em tempo real), o que também 
                fornece insights para oferecer a cada cliente a melhor ação para eles.`
    }
  },

  mulesoft: {
    title: 'MuleSoft',
    text: `O Mule é um mecanismo de tempo de execução leve e escalonável<br /> 
            para APIs, integrações e microsserviços. É utilizado com uma<br /> arquitetura
            de plataforma híbrida - local, na nuvem ou em ambos.`,
    text2: `Como líder global em integrações e soluções de gerenciamento de API, a Mulesoft
            tem como objetivo com o Anypoint Platform conectar seus aplicativos e resolver os 
            problemas de conectividade mais desafiadores em SOA, SaaS e APIs.<br /> Mulesoft é uma
            plataforma simples para a construção de API's e gerenciamento de integrações, sejam eles 
            um ERP's, MES e aplicações backend.`,
    text3: `Juntas, Salesforce e MuleSoft aceleram a sua transformação digital, capacitando-os a
            abrir os dados ao longo de sistemas legados, aplicações de cloud e dispositivos para a tomada
            de decisões mais rápidas e mais inteligentes, e para criar experiências altamente diferenciadas e
            conectadas aos consumidores.
            `,
    text4: `O Anypoint Platform acelera a velocidade de desenvolvimento reutilizando APIs, conectores,
            modelos, exemplos e outros ativos de integração já construídos e publicamos no Exchange da MuleSoft.
            `
  },

  delmiaQuintiq: {
    title: 'Delmia Quintiq',
    text: `O software de planejamento de cadeia de suprimentos da DELMIA<br /> 
					Quintiq planeja e otimiza sua cadeia de suprimentos de ponta a ponta,<br /> 
					dando a você o nível de controle e visão necessários para transformar o<br /> 
					planejamento da cadeia de suprimentos de um centro de custo em<br /> 
					um gerador de receita.`,
    text2: `Defina claramente os KPIs de toda a organização e alinhe cada função<br /> 
						de negócios a esses objetivos. Otimize cada etapa de sua cadeia de<br /> 
						suprimentos - desde planos estratégicos de longo prazo até detalhes<br /> 
						críticos no ponto de execução - para alcançar altos níveis de serviço,<br /> 
						qualidade e eficiência que sejam consistentes e sustentáveis.`,
    text3: `DELMIA Quintiq é o software de planejamento e otimização da cadeia de suprimentos (SCP & O) que oferece o poder de otimização`,
    text4: `<strong>Sales and operations planning</strong> – Planejamento Integrado de Vendas e Operações. Recursos avançados de modelagem e otimização para 
					simular qualquer número de cenários hipotéticos e, em seguida, recomendar o melhor para atender aos KPIs de negócios e apoiar a 
					tomada de decisão eficaz para entregar o máximo de valor.`,
    text5: `<strong>Master planning</strong> – conduza o suporte à decisão global com visibilidade em tempo real e análise preditiva para planejamento mestre e 
					programação e planejamento de requisitos de material.`,
    text6: `<strong>Programação de produção detalhada</strong> – reduza o tempo de espera e o estoque, otimizando a produção dentro e entre as linhas de 
					produção, células de trabalho e operações de montagem, enquanto melhora a utilização de ativos e dá suporte às datas de vencimento do 
					cliente.`,
    text7: `<strong>Planejamento e otimização de operações logísticas</strong> – gerentes, planejadores e despachantes ganham total visibilidade e controle sobre 
					todo o processo de planejamento e otimização - desde o planejamento estratégico de rede de longo alcance até a programação diária da 
					força de trabalho e o planejamento de rotas.`,
    text8: `<strong>Planejamento e otimização das operações de trânsito</strong> – uma solução para lidar com todos os desafios - desde a programação da tripulação 
					e da frota até a conquista de novas licitações por meio de licitações abertas. A solução funciona para operações grandes e pequenas e se 
					integra perfeitamente a uma ampla gama de sistemas de terceiros.`,
    text9: `<strong>Planejamento e otimização das operações da força de trabalho</strong> – Construído em um modelo de negócios 100% adequado que inclui todas 
					as restrições exclusivas da sua organização, como habilidades e qualificações dos funcionários, requisitos específicos de contratos, 
					regulamentações trabalhistas e disponibilidade de recursos. Você define suas metas de eficiência, satisfação e lucratividade; capacitamos 
					seus planejadores com as ferramentas e percepções de que precisam para melhorar os resultados importantes.`,
    text10: `O software DELMIA Quintiq inclui um aplicativo web desenvolvido com uma nova interface moderna que foca na experiência do usuário. Isso 
					fornece aos usuários liberdade e flexibilidade para acessar imediatamente DELMIA Quintiq em qualquer navegador da web.`,
    text11: `https://www.3ds.com/products-services/delmia/products/delmia-quintiq/`
  },

  partners: {
    main: {
      title: `Nossos Parceiros`,
      text: `Trabalhamos com um ecossistema de parceiros<br /> com objetivo de buscarmos soluções inovadoras<br /> para o seu negócio.`
    },
    dassault: {
      text: `A Dassault Systèmes, empresa 3DEXPERIENCE, fornece para empresas e pessoas universos virtuais para a criação de inovações  sustentáveis e que contribuem a melhoria de processos e praticas de negocios. Suas soluções conhecidas mundialmente transformam a forma como produtos são desenvolvidos, produzidos e gerenciados. As soluções colaborativas da Dassault Systèmes incentivam a inovação social, ampliando as possibilidades de aprimoramento do mundo real por meio do mundo virtual. A empresa entrega valor a mais de 210.000 clientes de todos os portes e indústrias, em mais de 140 países.`
    },
    sap: {
      text: `A SAP é um dos líderes mundiais de desenvolvimento de softwares para gerenciamento de processos de negócios, como o ECC, S4 Hana, IBP, SAC, criando soluções que facilitam o processamento efetivo de dados e o fluxo de informações entre as organizações. <br /><br />Hoje, a SAP tem mais de 230 milhões de usuários na nuvem, mais de 100 soluções que abrangem todas as funções de negócios e o maior portfólio de nuvem de qualquer provedor.`
    },
    poweron: {
      text: `PowerOn permite que o seu PowerBI realize a operação de atualização e escrita integrando os seus dashboards com mais de 200 bases de dados. Isso permitirá a criação de planos colaborativos, modelos dinâmicos com alterações de parâmetros e uma melhor usabilidade para sua plataforma.`
    },
    aimms: {
      text: `O AIMMS fornece uma plataforma de modelagem e otimização que tem sido usada em várias funções de praticamente qualquer tipo de negócio. É uma ótima opção para equipes prontas para se distanciarem de planilhas ou ferramentas antigas de projeto de rede, previsão de demanda e S&OP / IBP. O AIMMS SC Navigator oferece aplicativos prontos para uso na nuvem para obter os benefícios de análises sofisticadas sem a necessidade de experiência em análises incluindo solucionadores de classe mundial (e links de solucionador) para programação linear, inteira mista e não linear, como baron, cplex, conopt, gurobi, knitro, path, snopt e xa. Além disso, conceitos como programação estocástica e otimização robusta estão disponíveis para incluir a incerteza dos dados nos modelos. 
      `
    },
    asprova: {
      text: `Asprova é um Sistema de APS (Advanced Planning & Scheduling) para programação de produção em alta velocidade para multiplus itens e processos com a integração de vendas, manufatura, estoque e compra. Asprova foi desenvolvido originalmente no Japão onde é a ferramenta de programação de produção mais utilizada com um “market share” de 52.4%. (De acordo com Techno Systems Research Co.,Ltd Report 2011)`
    }
  },

  asprova: {
    text: `O Asprova funciona como uma planilha do Excel fácil, mesmo com<br /> grandes quantidades de dados, manutenção facilitada.`,
    text2: `Asprova foi desenvolvido originalmente no Japão onde é a ferramenta de programação de produção mais utilizada com um 
		"market share" de 52.4%. (De acordo com Techno Systems Research Co.,Ltd Report 2011)`,
    text3: `Asprova é um Sistema de APS (Advanced Planning & Scheduling) para programação de produção em alta velocidade para multiplus itens e processos com a integração de vendas, manufatura, estoque e compra. A ferramenta possui multiplas funcionalidades para a programação de produção, além de uma facilidade para sua customização sem a complexidade de novas codificações. `,
    text4: `A aplicação trabalha com dois tipos de visualização de dados: uma planilha de fácil edição e uma visão gráfica. É possível visualizar recursos, ordens de produção, estoque, compras e vendas. A programaçãoes é realizada em diferentes agregações temporais como dia, semana e mês além de ser flexivel para a customização do usuário. A aplicação se integra com diferentes ERPs como SAP e Oracle.`,
    features: {
      title: `<strong>Algumas funcionalidades</strong>`,
      text: `Vendas : Vincule as informações de vendas ao planejamento da produção da fábrica. `,
      text2: `Compra: Vincule o planejamento da produção às informações de compra. `,
      text3: `KPI: Avalie vários KPI's em cada projeto. `,
      text4: `Restrição de tempo MAX: Ajuste o tempo predefinido entre os processos. `,
      text5: `Bloqueio de recursos: Bloqueie temporariamente um recurso após a produção. `,
      text6: `Evento: Defina condições e gere eventos. `,
      text7: `Otimização: sequenciamento de trabalho para todos os recursos. `,
      text8: `Cenários: Suporte para criação de multiplus cenários. `,
      text9: `Restrições de recurso: Escolha sobrecargas de linha e entregas atrasadas instantaneamente usando tabelas e gráficos. `
    }
  }
}
