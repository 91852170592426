import React from 'react'
import parse from 'html-react-parser'

import Particles from './Particles'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Video from '../../components/Video'
import { Title, Text, H2Text, H2Title } from '../../components/Title/styles.js'
import { Tags } from '../../components/Tags/styles'
import { Container } from '../../components/Layout/styles'
import { Row, Section, Col } from './styles'

function ExperienciaDigital({ lang, handleLang }) {
  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <Section className='main'>
        {/* <Container> */}
        <Row className='main-row'>
          <Col className='main-column'>
            <div className='wrapColumn text'>
              <Title>{parse(lang.digitalExperience.banner.title)}</Title>
              <Text>{parse(lang.digitalExperience.banner.text)}</Text>
              <Tags>
                <span>{lang.digitalExperience.banner.tag1}</span>
                <span>{lang.digitalExperience.banner.tag2}</span>
                <span>{lang.digitalExperience.banner.tag3}</span>
                <span>{lang.digitalExperience.banner.tag4}</span>
              </Tags>
            </div>
          </Col>
          <Col className='main-column col-particle'>
            <div className='wrapColumn particle'>
              <Particles />
            </div>
          </Col>
        </Row>
        {/* </Container> */}
      </Section>
      <Section className='design-thinking'>
        <Container>
          <Row className='column'>
            <H2Title>
              {parse(lang.digitalExperience.designThinking.title)}
            </H2Title>
            <H2Title>
              {parse(lang.digitalExperience.designThinking.title2)}
            </H2Title>
          </Row>
        </Container>
      </Section>
      <Section className='steps'>
        <Container>
          <Row className='stepsRow'>
            <Col className='steps' data-aos='fade-up' data-aos-duration='1000'>
              <div className='step-number'>
                <h2>{parse(lang.digitalExperience.steps.step1.title)}</h2>
              </div>
              <div className='step-info'>
                <div className='steps-name'>
                  <img
                    src='./assets/images/experienciaDigital/imersao.svg'
                    alt='Imersão'
                  />
                  <h2>{lang.digitalExperience.steps.step1.imersion}</h2>
                  <p>
                    {parse(lang.digitalExperience.steps.step1.imersionText)}
                  </p>
                </div>
                <div className='steps-name'>
                  <img
                    src='./assets/images/experienciaDigital/definir.svg'
                    alt='Definir'
                  />
                  <h2>{lang.digitalExperience.steps.step1.define}</h2>
                  <p>{parse(lang.digitalExperience.steps.step1.defineText)}</p>
                </div>
              </div>
            </Col>
            <Col className='steps' data-aos='fade-up' data-aos-duration='1600'>
              <div className='step-number'>
                <h2>{parse(lang.digitalExperience.steps.step2.title)}</h2>
              </div>
              <div className='step-info step2'>
                <div className='steps-name'>
                  <img
                    src='./assets/images/experienciaDigital/ideacao.svg'
                    alt='Ideação'
                  />
                  <h2>{parse(lang.digitalExperience.steps.step2.ideation)}</h2>
                  <p>
                    {parse(lang.digitalExperience.steps.step2.ideationText)}
                  </p>
                </div>
                <div className='steps-name'>
                  <img
                    src='./assets/images/experienciaDigital/prototipacao.svg'
                    alt='Prototipação'
                  />
                  <h2>
                    {parse(lang.digitalExperience.steps.step2.prototyping)}
                  </h2>
                  <p>
                    {parse(lang.digitalExperience.steps.step2.prototypingText)}
                  </p>
                </div>
                <div className='steps-name'>
                  <img
                    src='./assets/images/experienciaDigital/testes.svg'
                    alt='Testes'
                  />
                  <h2>{parse(lang.digitalExperience.steps.step2.tests)}</h2>
                  <p>{parse(lang.digitalExperience.steps.step2.testsText)}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className='about' data-aos='fade-up' data-aos-duration='1200'>
        <Container>
          <Row className='column'>
            <H2Text className='purple'>
              {parse(lang.digitalExperience.about.title)}
            </H2Text>
            <H2Text className='gray'>
              {parse(lang.digitalExperience.about.text)}
            </H2Text>
          </Row>
        </Container>
      </Section>
      <Video iframe='https://www.youtube.com/embed/SyWNU0GJ8nA' />
      <Footer lang={lang} />
    </>
  )
}

export default ExperienciaDigital
