import styled from 'styled-components'
import { Row as RowColumn } from '../../components/Layout/styles'

export const Row = styled(RowColumn)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.row {
    flex-direction: row;
  }

  &.info {
    justify-content: space-between !important;
  }
`

export const Apllos = styled.section`
  display: flex;
  align-items: center;
  height: 627px;
  padding: 58px 0 18px;
  position: relative;
  align-items: center;
  h1 {
    font-weight: 600;
    width: 400px;
  }
  img {
    right: 0;
    position: absolute;
    &.graph {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      width: 73%;
    }
    &.points {
      position: absolute;
      right: 11%;
      bottom: 41%;
    }
    &.postit {
      width: 415px;
      height: 415px;
      bottom: 25px;
      right: 8%;
    }
    &.objectApllos {
      width: 385px;
      height: 385px;
      bottom: -20%;
      z-index: -1;
      transform: rotate(-9deg);
    }
  }

  @media screen and (max-width: 1024px) {
    height: 516px;
    padding: 182px 0 0;

    img {
      &.postit,
      &.objectApllos {
        display: none;
      }
    }
  }
`

export const About = styled.section`
  display: flex;
  align-items: center;
  margin-top: -43px;

  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }
`

export const Info = styled.section`
  display: flex;
  align-items: center;
  margin: 75px auto;
  span {
    height: 79px;
    width: 79px;
    background-size: cover !important;
    &.organizacao {
      background: url('./assets/images/apllos/estruturaHorizontal.jpg')
        no-repeat top center;
    }
    &.equipe {
      background: url('./assets/images/apllos/especialistasComprometidos.jpg')
        no-repeat top center;
    }
    &.foco {
      background: url('./assets/images/apllos/solucoesInovadoras.jpg') no-repeat
        top center;
    }
  }
  strong {
    color: #d8d8d8;
    font-size: 14px;
    font-weight: 400;
    margin-top: 22px;
  }
  p {
    color: #7b7e7f;
    font-size: 14px;
    line-height: 1.7;
  }
  h2 {
    color: #171717;
    font-size: 24px;
    font-weight: 500;
    margin: 0px 0 20px;
  }

  @media screen and (max-width: 990px) {
    p {
      br {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    span {
      margin: 0 auto;
    }
    h2 {
      font-size: 18px;
    }
  }

  @media (max-width: 576px) {
    margin-top: 41px;
    .row {
      flex-direction: column;
      > div {
        &:not(:last-child) {
          margin-bottom: 49px;
        }
      }
    }
  }
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  &.50 {
    width: 50%;
    flex-direction: row;
  }
`

export const Reconhecimento = styled.section`
  margin-bottom: 80px;
  h2 {
    color: var(--colorPrimary);
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 50px;
  }

  .reconhecimento {
    display: flex;
    justify-content: space-between;
    img {
      height: 180px;
      margin: 0 auto 40px;

      &.gptw2021 {
        width: 100px;
      }

      &.gptw2022 {
        width: 119px;
      }

      &.gptw2023 {
        width: 115px;
      }


      &.melhores-empresas {
        width: 175px;
      }

      &.qlicar {
        width: 99px;
      }
    }

    p {
      color: #7b7e7f;
      font-size: 14px;
      line-height: 1.7;
      text-align: center;
    }
  }

  @media screen and (max-width: 660px) {
    h2 {
      font-size: 28px;
    }
    .reconhecimento {
      display: grid;
      justify-content: center;

      img {
        margin: 0px auto 20px;
      }

      ${Col} {
        margin-bottom: 40px;
      }
    }
  }
`
