import styled from 'styled-components'

export const Form = styled.div`
  border-radius: 7px;
  box-shadow: 0 0 19px 0px #72727224;
  background: #fff;
  .row-form {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 38px;
    .col-form {
      display: flex;
      flex-direction: column;
      &:last-child {
        margin-bottom: 0;
      }
      h2 {
        color: var(--colorPrimary);
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 44px;
      }
      .inputForm {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        margin-bottom: 44px;
        &.fileUploaded {
          display: flex;
          align-items: center;
          flex-direction: row;
          .nameFileColumn {
            padding: 15px 0 0;
            margin-left: 30px;
          }
          .nameFile {
            display: block;
          }
          .removeFile {
            background: transparent;
            border: none;
            display: flex;
            color: #ff0033;
            outline: none;
            align-items: center;
            cursor: pointer;
            span {
              line-height: 1;
              margin-right: 8px;
              &.icon {
                font-size: 26px;
              }
              &.text {
                font-size: 13px;
                text-decoration: underline;
              }
            }
          }
        }

        &.error {
          input,
          textarea {
            border: 1px solid #ff0033;
          }

          .errorField {
            display: block;
          }
        }

        input {
          height: 46px;
          border-radius: 6px;
          border: 1px solid #c6c6c6;
          padding: 10px;
        }
        textarea {
          resize: none;
          height: 163px;
          border: 1px solid #c6c6c6;
          padding: 10px;
          border-radius: 6px;
        }
        label {
          margin-bottom: 10px;
          display: flex;
          &.button {
            background: #fff;
            border: 1px solid var(--colorPrimary);
            color: var(--colorPrimary);
            border-radius: 50px;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-family: 'Monserrat', sans-serif;
            letter-spacing: 1px;
            justify-content: center;
            cursor: pointer;
            width: 220px;
            padding: 10px 0;
            font-size: 14px;
            input {
              position: absolute;
              left: -9999px;
              visibility: hidden;
            }
            span {
              &.arrow {
                background: url('./assets/images/arrow-purple.svg') no-repeat;
                width: 13px;
                height: 13px;
                margin: 0 15px -1px;
              }
            }

            &.error {
              color: #ff0033;
              border-color: #ff0033;

              & > span.arrow {
                background: url('./assets/images/arrow-error.svg') no-repeat;
              }
            }
          }
        }

        p {
          font-weight: medium;
          font-size: 12px;

          &.errorField {
            color: #ff0033;
            margin-top: 10px;
            display: none;
          }

          &.info {
            color: #727272;
            display: block;
            &.error {
              color: #ff0033;
            }
          }
        }
      }

      &.buttonForm {
        flex-direction: row;
        justify-content: flex-end;
        button {
          width: 135px;
          box-sizing: content-box;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .row-form {
      .col-form {
        .inputForm {
          label {
            &.button {
              input {
                position: absolute;
                left: 0;
                opacity: 0;
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    .row-form {
      .col-form {
        h2 {
          font-size: 22px;
          br {
            /* display: none; */
          }
        }
        .inputForm.fileUploaded {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }

  @media screen and (max-width: 350px) {
    .row-form {
      .col-form {
        h2 {
          font-size: 18px;
        }
      }
    }
  }
`
