import React from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'

import routes from '../../routes'

import { Container, Section, Row, Col } from './styles'
import { Button } from '../../components/Button/styles'

function Hire({ lang }) {
  return (
    <>
      <Section className='hire'>
        <Container>
          <Row className='row hire'>
            <Col className='image'>
              <img
                src='./assets/images/apllos/people.png'
                className='imgHired'
                alt='Estamos Contratando'
                data-aos='fade-right'
                data-aos-duration='1200'
              />
            </Col>
            <Col className='text'>
              <h2>
                <span className='span-image'>
                  <img
                    src='./assets/images/apllos/people.png'
                    className='imgHired'
                    alt='Estamos Contratando'
                    data-aos='fade-right'
                    data-aos-duration='1200'
                  />
                </span>
                <span className='title'>{parse(lang.hire.title)}</span>
              </h2>
              <p>{parse(lang.hire.text)}</p>
              <div className='opportunities'>
                <div className='links'>
                  <h3>{parse(lang.hire.link1)}</h3>
                  <a
                    href='https://www.linkedin.com/company/apllos/'
                    target='.blank'
                    rel='noopener'
                  >
                    <img
                      src='./assets/images/apllos/linkedin.svg'
                      alt='LinkedIn'
                    />
                    LinkedIn
                  </a>
                </div>
                <div className='links'>
                  <h3>{parse(lang.hire.link2)}</h3>
                  <Link to={routes.bancoDeTalentos} className='filled'>
                    <Button>
                      <span>{parse(lang.hire.talents)}</span>
                      <img src='./assets/images/arrow.svg' alt='' />
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Hire
