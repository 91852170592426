import React from 'react'
import Typed from 'react-typed'
import parse from 'html-react-parser'

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Title, Text } from '../../components/Title/styles.js'
import { Container } from '../../components/Layout/styles'

import * as S from './styles'

import Hire from '../../components/Hire'

function Apllos({ lang, handleLang }) {
  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <S.Apllos>
        <img
          src='./assets/images/digitalSupplyChain/graphSupplyChainPage.svg'
          className='graph'
          data-aos='fade-down-left'
          data-aos-duration='1500'
          alt=''
        />
        <img
          src='./assets/images/digitalSupplyChain/points.svg'
          className='points'
          alt=''
          data-aos='flip-up'
          data-aos-duration='1500'
        />

        <Container>
          <S.Row>
            <S.Col className='50'>
              <Title>
                <Typed
                  loop
                  typeSpeed={100}
                  backSpeed={80}
                  strings={lang.apllos.banner.words}
                  smartBackspace
                  shuffle={false}
                  backDelay={1}
                  fadeOut={false}
                  fadeOutDelay={100}
                  loopCount={0}
                  cursorChar='_'
                />
              </Title>
              <Text className='normalStyle'>
                {parse(lang.apllos.banner.title)}
              </Text>
            </S.Col>
            <S.Col className='50'>
              <img
                src='./assets/images/apllos/postit.png'
                className='postit'
                alt='Apllos'
                data-aos='fade-left'
                data-aos-duration='1200'
              />
              <img
                src='./assets/images/apllos/object.png'
                className='objectApllos'
                alt='Apllos'
                // data-aos="fade-left"
                // data-aos-duration="1200"
              />
            </S.Col>
          </S.Row>
        </Container>
      </S.Apllos>

      <S.About>
        <Container>
          <S.Row className='about'>
            <S.Col>
              <Text
                className='normalStyle'
                data-aos='fade-up'
                data-aos-duration='1200'
              >
                {parse(lang.apllos.about.text)}
              </Text>
            </S.Col>
          </S.Row>
        </Container>
      </S.About>
      <S.Info data-aos='fade-up' data-aos-duration='1200'>
        <Container>
          <S.Row className='about info row'>
            <S.Col>
              <span className='icon organizacao'></span>
              <strong>{parse(lang.apllos.info.organization)}</strong>
              <h2>{parse(lang.apllos.info.orgTitle)}</h2>
              <p>{parse(lang.apllos.info.orgText)}</p>
            </S.Col>
            <S.Col>
              <span className='icon equipe'></span>
              <strong>{parse(lang.apllos.info.team)}</strong>
              <h2>{parse(lang.apllos.info.teamTitle)}</h2>
              <p>{parse(lang.apllos.info.teamText)}</p>
            </S.Col>
            <S.Col>
              <span className='icon foco'></span>
              <strong>{parse(lang.apllos.info.focus)}</strong>
              <h2>{parse(lang.apllos.info.focusTitle)}</h2>
              <p>{parse(lang.apllos.info.focusText)}</p>
            </S.Col>
          </S.Row>
        </Container>
      </S.Info>
      <S.Reconhecimento>
        <Container>
          <S.Row>
            <h2>Reconhecimento</h2>
            <div className='reconhecimento'>
              <S.Col>
                <img
                  src='./assets/images/apllos/gptw2021.png'
                  alt='Selo Great Place to Work de 30/07/2020 a 30/07/2021'
                  className='gptw2021'
                />
                <p>
                  Em 2020, no seu primeiro
                  <br /> ano de participação, a<br /> Apllos é certificada como
                  <br /> uma empresa GPTW
                </p>
              </S.Col>
              <S.Col>
                <img
                  src='./assets/images/apllos/gptw2022.png'
                  alt='Selo Great Place to Work de 30/07/2021 a 30/07/2022'
                  className='gptw2022'
                />
                <p>
                  Em 2021, repetimos o<br /> feito e pelo 2º ano
                  <br /> consecutivo obtemos o<br />
                  certificado de empresa
                  <br /> GPTW
                </p>
              </S.Col>
              <S.Col>
                <img
                  src='./assets/images/apllos/gptw2023.png'
                  alt='Selo Great Place to Work de 12/03/2023 a 12/04/2024'
                  className='gptw2023'
                />
                <p>
                  Em 2023, pela 3º vez<br /> fomos certificados
                  <br /> como uma empresa GPTW
                </p>
              </S.Col>
              <S.Col>
                <img
                  src='./assets/images/apllos/melhores-empresas.png'
                  alt='Selo de entrada do ranking de 100 melhores empresas para se trabalhar no Brasil'
                  className='melhores-empresas'
                />
                <p>
                  Em 2021, além do
                  <br /> certificado GPTW, a<br /> Apllos entra no ranking
                  <br />
                  das 100 melhores
                  <br /> empresas para se
                  <br /> trabalhar no Brasil na
                  <br />
                  categoria “Pequenas
                  <br /> Empresas”
                </p>
              </S.Col>
              <S.Col>
                <img
                  src='./assets/images/apllos/qlicar.png'
                  alt='Prêmio Qlicar entregue para Apllos'
                  className='qlicar'
                />
                <p>
                  Em 2021, fomos eleitos
                  <br /> pela NATURA&CO como
                  <br /> o melhor fornecedor de
                  <br /> TD Tecnologias Digitas
                  <br /> pela parceria ao longo do
                  <br /> ano de 2020 e sendo
                  <br /> reconhecido com o<br /> Prêmio Qlicar
                </p>
              </S.Col>
            </div>
          </S.Row>
        </Container>
      </S.Reconhecimento>
      <Hire lang={lang} />
      <Footer lang={lang} />
    </>
  )
}

export default Apllos
