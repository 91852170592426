import React from 'react'
import parse from 'html-react-parser'

import { Container } from '../../components/Layout/styles'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Row, Section, Col } from './styles'

import Video from '../../components/Video'

function DelmiaQuintiq({ lang, handleLang }) {
  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <Section className='banner'>
        <img
          src='./assets/images/delmia/screen2.png'
          className='screen'
          data-aos='fade'
          data-aos-duration='1500'
          alt=''
        />
        <Container>
          <Row>
            <Col>
              <h1>{parse(lang.delmiaQuintiq.title)}</h1>
              <p>{parse(lang.delmiaQuintiq.text)}</p>
              <p>{parse(lang.delmiaQuintiq.text2)}</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className='delmia'>
        <Container>
          <Row>
            <img
              src='./assets/images/delmia/delmiaLogo.png'
              alt=''
              className='delmiaLogo'
            />
            <h2>{parse(lang.delmiaQuintiq.text3)}</h2>

            <img
              src='./assets/images/delmia/fluxo.png'
              alt=''
              className='fluxoImage'
            />

            <h2>{parse(lang.delmiaQuintiq.text4)}</h2>
            <h2>{parse(lang.delmiaQuintiq.text5)}</h2>
            <h2>{parse(lang.delmiaQuintiq.text6)}</h2>
            <h2>{parse(lang.delmiaQuintiq.text7)}</h2>
            <h2>{parse(lang.delmiaQuintiq.text9)}</h2>
            <h2>{parse(lang.delmiaQuintiq.text10)}</h2>

            <a
              href={lang.delmiaQuintiq.text11}
              target='_blank'
              rel='noopener noreferrer'
            >
              {parse(lang.delmiaQuintiq.text11)}
            </a>
          </Row>
        </Container>
      </Section>
      <Video iframe='https://www.youtube.com/embed/-nS6uUe3Mnk' />
      <Footer lang={lang} />
    </>
  )
}

export default DelmiaQuintiq
