import React, { useState } from 'react'
import parse from 'html-react-parser'

import { Container } from '../../components/Layout/styles'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Row, Section, Col } from './styles'

import CollapseComponent from '../../components/Collapse'
import Video from '../../components/Video'

export default function Salesforce({ lang, handleLang }) {
  const [collapse, setCollapse] = useState({
    col1: false,
    col2: false,
    col3: false
  })

  const handleClick = (type) => {
    let newCollapse = { ...collapse }

    newCollapse.col1 = type === 'col1' && !newCollapse.col1 ? true : false
    newCollapse.col2 = type === 'col2' && !newCollapse.col2 ? true : false
    newCollapse.col3 = type === 'col3' && !newCollapse.col3 ? true : false

    setCollapse(newCollapse)
  }
  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <Section className='banner'>
        <img
          src='./assets/images/salesforce/screen2.png'
          className='screen'
          data-aos='fade'
          data-aos-duration='1500'
          alt=''
        />
        <Container>
          <Row>
            <Col>
              <h1>{parse(lang.salesforce.title)}</h1>
              <p>{parse(lang.salesforce.text)}</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className='salesforce'>
        <Container>
          <Row>
            <h2>{lang.salesforce.text2}</h2>
            <h2>{lang.salesforce.text3}</h2>
            <h2>{lang.salesforce.text4}</h2>
            <h2>{lang.salesforce.text5}</h2>
            <div className='collapseDivs'>
              <CollapseComponent
                collapsed={collapse.col1}
                collapseName={'col1'}
                title={<h1>{lang.salesforce.services1Title}</h1>}
                handleClick={handleClick}
                className='padding'
                content={
                  <>
                    <p>{parse(lang.salesforce.services1Text)}</p>
                    <ul>
                      <li>{parse(lang.salesforce.services1List.text1)}</li>
                      <li>{parse(lang.salesforce.services1List.text2)}</li>
                      <li>{parse(lang.salesforce.services1List.text3)}</li>
                      <li>{parse(lang.salesforce.services1List.text4)}</li>
                      <li>{parse(lang.salesforce.services1List.text5)}</li>
                    </ul>
                  </>
                }
              />

              <CollapseComponent
                collapsed={collapse.col2}
                collapseName={'col2'}
                title={<h1>{lang.salesforce.services2Title}</h1>}
                handleClick={handleClick}
                className='padding'
                content={
                  <>
                    <p>{parse(lang.salesforce.services2Text)}</p>
                    <p>{parse(lang.salesforce.services2Text2)}</p>
                    <ul>
                      <li>{parse(lang.salesforce.services2List.text1)}</li>
                      <li>{parse(lang.salesforce.services2List.text2)}</li>
                      <li>{parse(lang.salesforce.services2List.text3)}</li>
                      <li>{parse(lang.salesforce.services2List.text4)}</li>
                      <li>{parse(lang.salesforce.services2List.text5)}</li>
                      <li>{parse(lang.salesforce.services2List.text6)}</li>
                    </ul>
                  </>
                }
              />

              <CollapseComponent
                collapsed={collapse.col3}
                collapseName={'col3'}
                title={<h1>{lang.salesforce.services3Title}</h1>}
                handleClick={handleClick}
                className='padding'
                content={
                  <>
                    <p>{parse(lang.salesforce.services3Text)}</p>
                    <p>{parse(lang.salesforce.services3Text2)}</p>
                    <ul>
                      <li>{parse(lang.salesforce.services3List.text1)}</li>
                      <li>{parse(lang.salesforce.services3List.text2)}</li>
                      <li>{parse(lang.salesforce.services3List.text3)}</li>
                    </ul>
                  </>
                }
              />
            </div>
          </Row>
        </Container>
      </Section>
      <Video iframe='https://www.youtube.com/embed/0qZCYc2l928' />
      <Footer lang={lang} />
    </>
  )
}
