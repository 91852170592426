import React from 'react'
import { Container } from '../../components/Layout/styles'

import { Section, Row } from './styles'

const Video = ({ iframe }) => (
  <Section>
    <Container>
      <Row>
        <div className='iframe-container'>
          <iframe
            width='100%'
            height='500'
            src={iframe}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen='1'
          ></iframe>
        </div>
      </Row>
    </Container>
  </Section>
)

export default Video
