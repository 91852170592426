import React from 'react'
import { Row, Section, Col, DataImagesContainer } from './styles'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import parse from 'html-react-parser'

function Dados({ lang, handleLang }) {
  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <Section>
        <Row className='col'>
          <Col>
            <h1>{parse(lang.data.banner.title)}</h1>
            <p>{parse(lang.data.banner.text)}</p>
          </Col>
          <Col className='content-image'>
            <img
              src='./assets/images/dados/dados.png'
              alt='Dados'
              data-aos='fade-left'
              data-aos-duration='1200'
            />
          </Col>
        </Row>

        <Row className='col'>
          <Col>
            <p>{parse(lang.data.section.text1)}</p>
            <p>{parse(lang.data.section.text2)}</p>

            <DataImagesContainer>
              <img className='aws' src='./assets/images/dados/aws.png' alt='Amazon AWS' />
              <img className='google-cloud' src='./assets/images/dados/google-cloud.png' alt='Google Cloud' />
              <img className='azure' src='./assets/images/dados/azure.png' alt='Azure' />
              <img className='databricks' src='./assets/images/dados/databricks.png' alt='Databricks' />
            </DataImagesContainer>

            <p>{parse(lang.data.section.text3)}</p>
          </Col>
        </Row>
      </Section>

      <Footer lang={lang} />
    </>
  )
}

export default Dados
