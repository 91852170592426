import ptbr from './ptbr'
import us from './us'
import es from './es'
import fr from './fr'
import de from './de'

export default {
    ptbr,
    us,
    es,
    fr,
    de
}