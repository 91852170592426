import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser'
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask'
import { Form } from './styles'
import {Button} from '../Button/styles'
import Loader from '../../components/Loader'
import { SendEmail } from '../../services'

function FormContact({ title, type, lang }) {
    const [loaderVisible, setLoaderVisible] = useState(false)

    const [file, setFile] = useState(undefined)
    const [fileBase64, setFileBase64] = useState("")
    const [fields, setFields] = useState({
        name: "",
        email: "",
        phone: "",
        expertise: "",
        message: "",
    })

    const [errors, setErrors] = useState({ 
        name: false,
        email: false,
        phone: false,
        expertise: false,
        message: false,
        file: false
    })

    const acceptedFiles = ['application/pdf'];
    const maxFileSize = 5000000;

    const onFileUploaded = (e) => {
        setFileBase64("")
        setFile(undefined)

        let fileUploaded = e.target.files[0];
        if (fileUploaded) {
            if(!acceptedFiles.includes(fileUploaded.type)){
                alert(lang.formContact.fileAlert);
                return;
            }
            if(fileUploaded.size > maxFileSize) {
                alert(lang.formContact.fileSizeAlert);
                return;
            }

            setFile(fileUploaded)
        }
    }

    useEffect(() => {
        if (file) {
            getBase64(file).then((data) => {
                setFileBase64(data)
            })
        }
    }, [file, fileBase64])

    const getBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = error => reject(error);
        });
    }

    const handleChange = (field, value) => {
        setFields(prev => ({ 
            ...prev,
            [field]: value 
        }))
    }

    const handleSubmit = () => {
        
        setLoaderVisible(true)

        if (type === "bancoDeTalentos" ) {
            if (fields.name === "") {
                setErrors({ name: true })
            } 
            else if (fields.email === "") {
                setErrors({ email: true })
            } 
            else if (fields.phone === "") {
                setErrors({ phone: true})
            } 
            else if (fields.expertise === "") {
                setErrors({ expertise: true })
            } 
            else if (!file) {
                setErrors({ file: true })
            }

            if (fields.name && fields.email && fields.phone && fields.expertise && file) {
              
                SendEmail(type, {
                    name: fields.name,
                    email: fields.email,
                    phone: fields.phone,
                    expertise: fields.expertise,                    
                }, file, fileBase64).then((response) => {
                    setLoaderVisible(false)
                    console.log(response)

                    Swal.fire(
                        lang.formContact.success,
                        lang.formContact.emailSent,
                        'success'
                    )
                }).catch((err) => {
                    console.log(err)
                    setLoaderVisible(false)
                    Swal.fire(
                        lang.formContact.error,
                        lang.formContact.emailFail,
                        'error'
                    )
                })

                clearState()

            } else {
                setLoaderVisible(false)
            }

        }

        else if (type === "apoHealthCheck") {
            if (fields.name === "") {
                setErrors({ name: true })
            } 
            
            else if (fields.email === "") {
                setErrors({ email: true })
            } 

            if (fields.name && fields.email){
                clearState()

                SendEmail(type, { 
                    name: fields.name,
                    email: fields.email,
                    message: lang.apoHealthCheck.form.message
                }).then((response) => {
                    setLoaderVisible(false)
                    Swal.fire(
                        lang.formContact.success,
                        lang.formContact.emailSent,
                        'success'
                    )

                }).catch((err) => {
                    setLoaderVisible(false)
                    console.log(err)
                    Swal.fire(
                        lang.formContact.error,
                        lang.formContact.emailFail,
                        'error'
                    )
                })


            } else {
                setLoaderVisible(false)
            }

        }

        else if (type === "contact") {

            if (fields.name === "") {
                setErrors({ name: true })
            } 
            
            else if (fields.email === "") {
                setErrors({ email: true })
            } 
            
            else if (fields.message === "") {
                setErrors({ message: true})
            } 

            if (fields.name && fields.email && fields.message){

                SendEmail(type, { 
                    name: fields.name,
                    email: fields.email,
                    message: fields.message
                }).then((response) => {
                    setLoaderVisible(false)
                    Swal.fire(
                        lang.formContact.success,
                        lang.formContact.emailSent,
                        'success'
                    )

                }).catch((err) => {
                    setLoaderVisible(false)
                    console.log(err)
                    Swal.fire(
                        lang.formContact.error,
                        lang.formContact.emailFail,
                        'error'
                    )
                })

            clearState()
            
            } else {
                setLoaderVisible(false)
            }

        }
    }


    const clearState = () => {
        setFileBase64("")
        setFile(undefined)
        setFields({
            name: "",
            email: "",
            phone: "",
            expertise: "",
            message: "",
        })
        setErrors({ 
            name: false,
            email: false,
            phone: false,
            expertise: false,
            message: false,
            file: false
        })
    }

    const formBancoTalentosType = () => (
        <Form>
            <div className="row-form">
                <div className="col-form">
                    <h2>{ title ? parse(title) : parse(lang.formContact.title)}</h2>
                </div>
                <div className="col-form">
                    <div className={`inputForm ${errors.name && !fields.name  ? 'error' : ''}`}>
                        <label>{lang.formContact.nameInput}*</label>
                        {/* <input type="text" placeholder="Mariana Orlandini" onChange={(e) => setName(e.target.value)} value={name} /> */}
                        <input 
                            type="text" 
                            placeholder={lang.formContact.namePlaceholder} 
                            onChange={(e) => handleChange('name', e.target.value)} 
                            value={fields.name || ''} 
                        />
                        <p className="errorField">{lang.formContact.requiredField}</p>
                    </div>
                    <div className={`inputForm ${errors.email && !fields.email  ? 'error' : ''}`}>
                        <label>Email*</label>
                        {/* <input type="email" placeholder="you@example.com" onChange={(e) => setEmail(e.target.value)} value={email} /> */}
                        <input 
                            type="email" 
                            placeholder="you@example.com" 
                            onChange={(e) => handleChange('email', e.target.value)} 
                            value={fields.email || ''} 
                        />
                        <p className="errorField">{lang.formContact.requiredField}</p>
                    </div>
                    {type && (
                        <>
                            <div className={`inputForm ${errors.phone && !fields.phone  ? 'error' : ''}`}>
                                <label>{lang.formContact.phoneInput}*</label>
                                <InputMask 
                                    mask={fields.phone.length <= 10 ? '(99) 9999-9999?' : '(99) 99999-9999'}
                                    formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                                    onChange={(e) => handleChange('phone', e.target.value)}
                                    value={fields.phone || ''}
                                    placeholder="00 0000-0000"
                                />
                            <p className="errorField">{lang.formContact.requiredField}</p>
                            </div>
                            <div className={`inputForm ${errors.expertise && !fields.expertise  ? 'error' : ''}`}>
                                <label>{lang.formContact.expertiseInput}*</label>
                                <input 
                                    type="text" 
                                    placeholder="Ex.: UX Designer, Dev Fullstack, etc..." 
                                    onChange={(e) => handleChange('expertise', e.target.value)} 
                                    value={fields.expertise}
                                />
                                <p className="errorField">{lang.formContact.requiredField}</p>
                            </div>
                        </>
                    )}
                </div>

                    <div className="col-form">
                        <div className="inputForm fileUploaded">
                            <div className="">
                                <label 
                                    htmlFor="fileUpload" 
                                    className={`button ${errors.file && !file  ? 'error' : ''}`}
                                >
                                    <span>{lang.formContact.uploadCV}</span>
                                    <span className="arrow"></span>
                                    <input 
                                        type="file" 
                                        id="fileUpload" 
                                        onChange={ (e)=> onFileUploaded(e) }
                                        value=""
                                    />
                                </label>
                                <p className={`info ${errors.file && !file  ? 'error' : ''}`}>{lang.formContact.typeFile}*</p>
                            </div>
                            <div className="nameFileColumn">
                                <label className="nameFile">
                                    <span>{file ? file.name : ''}</span>
                                </label>
                                {
                                    file && (
                                        <button className="removeFile" onClick={() => setFile(undefined)}>
                                            <span className="icon">&times;</span>
                                            <span className="text">{lang.formContact.removeCV}</span>
                                        </button>
                                    )
                                }
                            </div>
                            
                            
                        </div>
                    </div>

                <div className="col-form buttonForm">
                    <Button onClick={handleSubmit}>
                        <span>{lang.formContact.send}</span>
                        <span className="arrow"></span>
                    </Button>
                </div>
            </div>
        </Form>
    )

    const formContactType = () => (
        <Form>
            <div className="row-form">
                <div className="col-form">
                    <h2>{ title ? parse(title) : parse(lang.formContact.title)}</h2>
                </div>
                <div className="col-form">
                    <div className={`inputForm ${errors.name && !fields.name  ? 'error' : ''}`}>
                        <label>{lang.formContact.nameInput}*</label>
                        <input 
                            type="text" 
                            placeholder={lang.formContact.namePlaceholder} 
                            onChange={(e) => handleChange('name', e.target.value)} 
                            value={fields.name || ''} 
                        />
                        <p className="errorField">{lang.formContact.requiredField}</p>
                    </div>
                    <div className={`inputForm ${errors.email && !fields.email  ? 'error' : ''}`}>
                        <label>Email*</label>
                        <input 
                            type="email" 
                            placeholder="you@example.com" 
                            onChange={(e) => handleChange('email', e.target.value)} 
                            value={fields.email || ''} 
                        />
                        <p className="errorField">{lang.formContact.requiredField}</p>
                    </div>
                </div>
                <div className="col-form">
                    <div className={`inputForm ${errors.message && !fields.message  ? 'error' : ''}`}>
                        <label>{lang.formContact.messageInput}*</label>
                        <textarea 
                            placeholder={lang.formContact.messagePlaceholder}
                            onChange={(e) => handleChange('message', e.target.value)} 
                            value={fields.message}
                        ></textarea>
                        <p className="errorField">{lang.formContact.requiredField}</p>
                    </div>
                </div>
                
                <div className="col-form buttonForm">
                    <Button onClick={handleSubmit}>
                        <span>{lang.formContact.send}</span>
                        <span className="arrow"></span>
                    </Button>
                </div>
            </div>
        </Form>
    ) 

    const formApoHealthCheckType = () => (
        <Form>
            <div className="row-form">
                <div className="col-form">
                    {/* <h2>Contato</h2> */}
                </div>
                <div className="col-form">
                    <div className={`inputForm ${errors.name && !fields.name  ? 'error' : ''}`}>
                        <label>{lang.formContact.nameInput}*</label>
                        <input 
                            type="text" 
                            placeholder={lang.formContact.namePlaceholder} 
                            onChange={(e) => handleChange('name', e.target.value)} 
                            value={fields.name || ''} 
                        />
                        <p className="errorField">{lang.formContact.requiredField}</p>
                    </div>
                    <div className={`inputForm ${errors.email && !fields.email  ? 'error' : ''}`}>
                        <label>Email*</label>
                        <input 
                            type="email" 
                            placeholder="you@example.com" 
                            onChange={(e) => handleChange('email', e.target.value)} 
                            value={fields.email || ''} 
                        />
                        <p className="errorField">{lang.formContact.requiredField}</p>
                    </div>
                </div>
                
                <div className="col-form buttonForm">
                    <Button onClick={handleSubmit}>
                        <span>{lang.formContact.send}</span>
                        <span className="arrow"></span>
                    </Button>
                </div>
            </div>
        </Form>
    )

    return(
        <>
            <Loader visible={loaderVisible} />
            {type === "contact" && formContactType()}
            {type === "bancoDeTalentos" && formBancoTalentosType()}
            {type === "apoHealthCheck" && formApoHealthCheckType()}
        </>
    )
}

export default FormContact