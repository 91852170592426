import styled from 'styled-components'

import {
  Row as RowColumn,
  Container as ContainerColumn
} from '../../components/Layout/styles'

export const Row = styled(RowColumn)`
  &.flex {
    display: flex;
  }

  &.clients {
    @media (max-width: 576px) {
      width: 100%;
      .title {
        width: 90%;
        margin: 0 auto 29px;
      }
    }
  }
`

export const Container = styled(ContainerColumn)``

export const Section = styled.section`
  height: ${(props) =>
    props.height ? `min(${props.height}, 750px)` : 'min(60vh, 750px)'};
  align-items: center;
  display: flex;

  &.section {
    &.technology {
      height: auto;
      padding: 40px 0;

      .product {
        display: block;
        width: 95%;

        .slick-slider {
          display: flex;
          align-items: center;
          .slick-track {
            display: flex;
            align-items: center;
          }
          .slick-list {
            margin: 0 -7px;
            & .slick-slide > div {
              padding: 0 10px;
            }
          }
        }
        .buttonArrow {
          position: relative;
          padding: 21px;
          border: none;
          overflow: hidden;
          outline: 0;
          font-size: 0;
          line-height: 0;
          background-color: #7b59ff;
          cursor: pointer;
          top: auto;
          transform: initial;
          &:before {
            display: none;
          }
          span {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            border-color: #fff;
            border-style: solid;
            border-width: 3px 3px 0 0;
            padding: 5px;
            transition: 0.3s;
            font-size: 0;
          }
          &.arrowRight {
            span {
              transform: translate(-50%, -50%) rotate(45deg);
              margin-left: -2.45px;
            }
          }
          &.arrowLeft {
            span {
              transform: translate(-50%, -50%) rotate(-135deg);
              margin-left: 2.45px;
            }
          }
          &.slick-disabled {
            border: 2px solid #ccc;
            background: #ccc;
            pointer-events: none;
          }
          &:not(.slick-disabled) {
            background: #fff;
            border: 2px solid var(--colorPrimary) !important;
            transition: 0.3s;
            span {
              border-color: var(--colorPrimary);
            }
            &:hover {
              background: var(--colorPrimary) !important;
              span {
                border-color: #fff;
              }
            }
          }
        }
      }
      .sliderTechMobile {
        display: none;
      }
    }
  }

  &#products {
    /* margin-bottom: 67px; */
  }

  &#services {
    @media screen and (max-width: 768px) {
      margin: 25% 0 40%;
    }
  }

  &.contactUs {
    background: url('./assets/images/home/bgContact.jpg') no-repeat top center;
    height: 737px;
    background-size: cover;
    padding: 49px 0;
    box-sizing: content-box;
    margin-bottom: 70px;
    .col {
      display: flex;
      flex-direction: column;
      width: 50%;
      h1 {
        margin-bottom: 40px;
      }
      .text {
        font-weight: bold;
        font-size: 16px;
        color: var(--colorPrimary);
        line-height: 1.5;
        p {
          margin-top: 35px;
          position: relative;
          &:first-child:before {
            content: '';
            position: absolute;
            top: -22px;
            left: 0;
            width: 78px;
            border-bottom: 1px solid var(--colorPrimary);
          }
        }
      }
    }
  }

  .title {
    margin-bottom: 64px;
    &.contact {
      margin-bottom: 30px;
    }
    span {
      text-transform: uppercase;
      color: #727272;
      font-weight: 500;
    }
    h1 {
      font-weight: 600;
      color: var(--colorPrimary);
      font-size: 42px;
    }
    h4 {
      color: #727272;
      font-weight: 400;
      line-height: 1.7;
      margin: 29px 0;
    }
  }

  .services {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .services-container {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    .services-line {
      display: flex;
      gap: 16px;
    }
    .col {
      h2 {
        font-size: 20px;
        font-weight: 500;
        margin: 25px 0 12px;
      }
      p {
        color: var(--colorText);
        font-size: 14px;
        line-height: 1.7;
        margin-bottom: 35px;
      }

      &.image {
        width: 271px;
        position: relative;
        .servicesGraph {
          position: absolute;
          left: -117px;
          bottom: -66px;
          background: url('./assets/images/home/servicesGraph.png') no-repeat;
          width: 271px;
          height: 271px;
        }
      }

      .logo {
        position: relative;
        width: 60px;
        height: 70px;
        align-items: flex-end;
        display: flex;
        img {
          /* width: 60px; */
        }

        .laptopIcon {
          background: url('./assets/images/home/laptopIcon.svg') no-repeat;
          width: 60px;
          height: 46px;
        }

        .supplyIcon {
          background: url('./assets/images/home/supplyIcon.svg') no-repeat;
          width: 39px;
          height: 59px;
        }

        .dataIcon {
          background: url('./assets/images/home/dataIcon.svg') no-repeat;
          width: 60px;
          height: 59px;
        }

        &::before {
          content: '';
          height: 64px;
          width: 64px;
          border-radius: 100%;
          background: #efe9f1;
          position: absolute;
          z-index: -1;
          right: -15px;
          top: 2px;
        }
      }
    }

    @media (max-width: 990px) {
      .col {
        &.image {
          display: none;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .services-line {
        flex-direction: column;
      }
    }
  }

  .product {
    width: 88%;
    justify-content: center;
    margin: 0 auto;
    display: flex;
    .col {
      width: 50%;
      &:first-child {
        margin-right: 27px;
      }
      img {
        width: 100%;
        height: 340.891px;
      }
      h1 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
        color: #000;
        white-space: nowrap;
      }
      span {
        font-size: 12px;
        color: var(--colorText);
        text-transform: uppercase;
      }
    }
    @media (max-width: 990px) {
      .col {
        h1 {
          font-size: 20px;
        }
      }
    }
  }

  .clients {
    background: url('./assets/images/home/base.png') no-repeat top;
    height: 384px;
    width: 95%;
    margin: 0 auto;
    background-size: contain;
    .row {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      align-items: center;
      padding: 20px 20px 31px;
      justify-items: center;
      position: relative;
      .client {
        width: 147px;
        height: 69px;
        position: relative;
        .clientDiv {
          height: 100%;
          width: 100%;
          position: absolute;
          background-position: center !important;
        }
        .fadeInOut {
          animation-name: fadeInOut;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          animation-duration: 2.3s;
          animation-direction: alternate;
        }
        .fadeIn {
          opacity: 0;
          animation-name: fadeIn;
          animation-timing-function: ease-in-out;
          animation-iteration-count: infinite;
          animation-duration: 2.3s;
          animation-direction: alternate;
        }

        .gerdau {
          background: url('./assets/images/home/clients/gerdau.png') no-repeat;
        }
        .takeda {
          background: url('./assets/images/home/clients/takeda.png') no-repeat;
        }
        .abInbev {
          background: url('./assets/images/home/clients/ab-inbev.png') no-repeat;
        }
        .mars {
          background: url('./assets/images/home/clients/mars.png') no-repeat;
        }
        .suzano {
          background: url('./assets/images/home/clients/suzano.png') no-repeat;
        }
        .cba {
          background: url('./assets/images/home/clients/cba.png') no-repeat;
        }
        .braskem {
          background: url('./assets/images/home/clients/braskem.png') no-repeat;
        }
        .jde {
          background: url('./assets/images/home/clients/jde.png') no-repeat;
        }
        .ameDigital {
          background: url('./assets/images/home/clients/ame-digital.png')
            no-repeat;
        }
        .grupoPetropolis {
          background: url('./assets/images/home/clients/grupoPetropolis.png')
            no-repeat;
        }
        .heineken {
          background: url('./assets/images/home/clients/heineken.png') no-repeat;
        }
        .boticario {
          background: url('./assets/images/home/clients/boticario.png')
            no-repeat;
        }
        .natura {
          background: url('./assets/images/home/clients/natura.png') no-repeat;
        }
        .duratex {
          background: url('./assets/images/home/clients/duratex.png') no-repeat;
        }
        .johnsonJohnson {
          background: url('./assets/images/home/clients/johnson-johnson.png')
            no-repeat;
        }
        .abBrasil {
          background: url('./assets/images/home/clients/abBrasil.png') no-repeat;
        }
        .mondelez {
          background: url('./assets/images/home/clients/mondelez.png') no-repeat;
        }
        .vallourec {
          background: url('./assets/images/home/clients/vallourec.png')
            no-repeat;
        }
        .nufarm {
          background: url('./assets/images/home/clients/nufarm.png') no-repeat;
        }
        .vifor {
          background: url('./assets/images/home/clients/vifor.jpeg') no-repeat;
        }
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        border-bottom: 1px solid #d8d8d8;
      }
      &:last-child {
        border: none;
        &:after {
          display: none;
        }
      }
    }

    @-webkit-keyframes fadeInOut {
      0% {
        -webkit-opacity: 1;
      }
      45% {
        -webkit-opacity: 1;
      }
      55% {
        -webkit-opacity: 0;
      }
      100% {
        -webkit-opacity: 0;
      }
    }

    @keyframes fadeInOut {
      0% {
        opacity: 1;
      }
      45% {
        opacity: 1;
      }
      55% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @-webkit-keyframes fadeIn {
      0% {
        -webkit-opacity: 0;
      }
      45% {
        -webkit-opacity: 0;
      }
      55% {
        -webkit-opacity: 1;
      }
      100% {
        -webkit-opacity: 1;
      }
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      45% {
        opacity: 0;
      }
      55% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 990px) {
    &#products,
    &#technology {
      height: auto;
      padding: 78px 0 0;
      margin-bottom: 67px;
    }

    .services {
      .col {
        &:nth-child(2) {
          margin-right: 40px;
        }
        p {
          br {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .product {
      width: 100% !important;
      .col {
        h1 {
          font-size: 16px;
        }
      }
    }

    .clients {
      width: 90%;
      .row {
        display: flex;
        .client {
          width: 165px;
          height: 44px;
          &:not(:last-child) {
            margin-right: 37px;
          }
          .clientDiv {
            background-size: contain !important;
            -webkit-background-size: contain !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    &.section {
      &.technology {
        flex-direction: column;
        .title {
          margin-bottom: 36px;
          h4 {
            br {
              display: none;
            }
          }
        }
        .product {
          .buttonArrow {
            padding: 13px;
          }

          .slick-slider {
            display: none;
          }
        }

        .sliderTechMobile {
          display: block;
          white-space: nowrap;
          overflow-x: scroll;
          width: 100%;
          padding: 0 25px;
          .col {
            display: inline-block;
            width: 80%;
            margin-right: 28px;
            span {
              font-size: 12px;
              color: var(--colorText);
            }
            img {
              width: 100%;
              height: 280px;
            }
            h1 {
              font-size: 16px;
              color: #000;
              margin-bottom: 20px;
              font-weight: 600;
            }
          }
        }
      }
    }

    &.clientsSection {
      height: auto;
      padding: 30px 0 0;
    }
    &.contactUs {
      height: auto;
      .flex {
        flex-direction: column;
        .col {
          width: 100%;
        }

        .title {
          margin-bottom: 0;
        }

        .text {
          display: none;
        }

        /* FormContact */
        .row-form {
          padding: 38px 20px;
        }
        .row-form .col-form {
          &:first-child {
            display: none;
          }
        }
        /* FormContact */
      }
    }

    .title {
      h1 {
        font-size: 28px;
      }
      span {
        font-size: 12px;
      }
    }

    .services {
      flex-direction: column;
      .col {
        display: flex;
        margin-right: 0;
        margin-bottom: 67px;
        .logo {
          margin-right: 40px;
        }
        &:last-child {
          .logo {
            margin-right: 58px;
          }
        }
        h2 {
          margin-top: 0;
        }
        p {
          font-size: 13px;
          br {
            display: none;
          }
        }
      }
    }

    .product {
      width: 80%;
      flex-direction: column;
      .col {
        width: 100%;
        &:first-child {
          margin: 0 0 50px 0;
        }
        span {
          font-size: 12px;
        }

        h1 {
          font-size: 16px;
          white-space: normal;
        }
      }
    }

    .clients {
      width: 100%;
      .row {
        display: flex;
        .client {
          width: 65px;
          height: 36px;
        }
      }
    }
  }
`

export const SectionAboutUs = styled.section`
  height: 100vh;
  display: flex;
  justify-content: content;
  text-align: center;
  align-items: center;
  position: relative;
  margin: 70px 0 0;
  .symbolDiv {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    height: 100%;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .symbolApllos {
    background: url('./assets/images/home/symbolApllos.png') no-repeat;
    width: 658px;
    height: 650px;
    height: 100%;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  .icon {
    position: absolute;
    margin: 0 auto;
    left: 30%;
    top: 15%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: 0.3s;
    width: 100%;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
    }
    .stroke1 {
      animation: spin 4.2s linear infinite;
    }
    .stroke2 {
      /* top: 55px; */
      /* left: 10px; */
      /* transform: rotate(-19deg); */
      animation: spin 4s linear infinite;
    }
    .stroke3 {
      /* left: -30px; */
      animation: spin 4s linear infinite;
    }
    .stroke4 {
      /* left: -7px;
            top: 16px; */
      /* transform: rotate(-30deg); */
      animation: spin 4s linear infinite;
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .aspas {
  }
  h1 {
    font-size: 54.6px;
    font-weight: 600;
    margin-bottom: 30px;
    strong {
      color: var(--colorPrimary);
      position: relative;
      img {
        position: absolute;
        top: -30px;
        left: -30px;
      }
    }
  }

  p {
    color: var(--colorText);
    font-size: 16px;
    line-height: 2;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 40px;
    }
  }

  @media screen and (max-width: 576px) {
    height: auto;
    padding: 120px 0 0;
    h1 {
      font-size: 30px;
      position: relative;
      strong {
        position: unset;
        img {
          top: -44px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    p {
      font-size: 12px;
      br {
        display: none;
      }
    }

    .symbolApllos {
      width: 300px;
      height: 300px;
      background-size: cover;
    }
  }
`
