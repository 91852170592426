import styled from 'styled-components'

import {
  Row as RowColumn,
  Container as ContainerColumn
} from '../../../components/Layout/styles'

export const Container = styled.div`
  .slick-dots {
    bottom: 48px;
  }

  .slick-arrow {
    &::before {
      content: '';
    }

    &:hover {
      filter: brightness(0.9); 
    }

    width: 0; 
    height: 0;
    border-radius: 4px;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    z-index: 1;

    &.slick-prev {
      left: 25px; 
      border-right:14px solid #e3e6ec;
    }
  
    &.slick-next {
      right: 25px;
      border-left: 14px solid #e3e6ec;
    }
  }
  
  @media screen and (max-width: 768px) {
    .slick-dots {
      bottom: 0;
    }
  }

  @media screen and (max-width: 576px) {
    .slick-arrow {
      &.slick-prev, &.slick-next {
        display: none !important;
      } 
    }

    .slick-dots {
      bottom: 18%;
    }
  }
`;

export const Row = styled(RowColumn)`
  &.flex {
    display: flex;
  }

  &.clients {
    @media (max-width: 576px) {
      width: 100%;
      .title {
        width: 90%;
        margin: 0 auto 29px;
      }
    }
  }
`

export const BannerContainer = styled.div`
  margin-left: 1rem; 
  margin-right: 32px; 
  height: min(90vh, 900px);
  display: flex;
  align-items: center;
  position: relative;

  .banners {
    position: relative;
    width: 50%;
    overflow-y: visible;
    .graphs {
      overflow-x: hidden;
      .graph2 {
        position: absolute;
        margin-right: -110px;
        top: -46%;
        background: url('./assets/images/home/graph2.png') no-repeat;
        width: 662px;
        height: 662px;
      }
      .people {
        position: absolute;
        top: -11%;
        right: 22%;
        background: url('./assets/images/home/people.png') no-repeat;
        width: 314px;
        height: 314px;
      }
      .object {
        position: absolute;
        bottom: -30%;
        right: -10%;
        z-index: 2;
        background: url('./assets/images/home/object.png') no-repeat;
        width: 284px;
        height: 284px;
      }
      .polygon {
        position: absolute;
        bottom: -30%;
        right: -17%;
        background: url('./assets/images/home/polygon.png') no-repeat;
        width: 152px;
        height: 152px;
      }
    }
  }

  .data-banner {
    position: absolute;
    bottom: -15%;
    right: -45%;
    width: 100%;
    height: 100%;

    & img {}
  }

  .col {
    width: 50%;
    h1 {
      font-weight: 400;
      font-size: 43.2px;
      margin-bottom: 20px;
      &.mobile {
        display: none;
      }
    }
    p {
      font-size: 16px;
      line-height: 2;
      margin-bottom: 40px;
      color: var(--colorText);
      position: relative;
      z-index: 1;
      &.mobile {
        display: none;
      }
    }
    .logo {
      .logoApllos {
        margin-bottom: 23px;
        background: url('./assets/images/logo.svg') no-repeat;
        width: 78px;
        height: 78px;
      }
    }
  }
  .graph {
    position: absolute;
    left: 0;
    top: -115px;
    background: url('./assets/images/home/graph.svg');
    width: 705px;
    height: 510px;
  }

  @media (max-width: 1268px) {
  }

  @media screen and (max-width: 768px) {
    padding: 46px 0 0;
    box-sizing: content-box;
    overflow-x: hidden;
    .banners {
      width: 40%;
      .graphs {
        .graph2 {
          right: -52%;
          width: 432px;
          height: 432px;
          background-size: cover;
        }
        .people {
          right: 11%;
          width: 217px;
          height: 217px;
          background-size: cover;
        }
        .object {
          bottom: -21%;
          right: -31%;
          z-index: 2;
          width: 217px;
          height: 217px;
          background-size: cover;
        }
        .polygon {
          right: -34%;
          width: 112px;
          height: 112px;
          background-size: contain;
        }
      }
    }

    .col {
      .logo {
        display: none;
      }
      h1 {
        font-size: 36.2px;
      }
      p {
        br {
          display: none;
        }
      }
    }

    .data-banner {
      top: 40%;
      right: -40%;
      height: 500px;
      & img {
        width: 420px;
        height: auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  @media screen and (max-width: 576px) {
    padding: 70px 0 96px;
    .banners {
      width: 32%;
      .graphs {
        .graph2 {
          margin-right: 0;
          right: -65%;
          top: 29%;
          width: 209px;
          height: 211px;
          background-size: cover;
        }
        .people {
          top: 39%;
          right: 41%;
          width: 100px;
          height: 100px;
          background-size: cover;
        }
        .object {
          top: 64%;
          right: -22%;
          z-index: 2;
          width: 100px;
          height: 100px;
          background-size: cover;
        }
        .polygon {
          top: 77%;
          right: -56%;
          width: 86px;
          height: 71px;
          background-size: contain;
        }
      }
    }

    .data-banner {
      top: 14%;
      & img {
        width: 325px;
      }
    }

    .col {
      width: 100%;
      z-index: 1;
      h1 {
        font-size: 30px;
        margin-bottom: 64px;
        &.mobile {
          display: block;
          br {
            display: block;
          }
        }
        &.desktop {
          display: none;
        }
      }
      p {
        font-size: 15px;
        line-height: 1.6;
        &.mobile {
          display: block;
          br {
            display: block;
          }
        }
        &.desktop {
          display: none;
        }
      }
      .logo {
        display: none;
      }
    }
  }
`

export const BannerContent = styled(ContainerColumn)``

export const Button = styled.button`
  background: #fff;
  border-radius: 50px;
  color: var(--colorPrimary);
  font-weight: 600;
  outline: none;
  text-decoration: none;
  border: 1px solid var(--colorPrimary);;
  padding: 10px 26px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-family: 'Monserrat', sans-serif;
  letter-spacing: 1px;
  justify-content: center;

  margin: ${(props) => (props.margin ? props.margin : '0')};

  &:hover {
    box-shadow: 0 0 10px 2px #fff;
    box-shadow: 0 0 8px 0px #5c1f7561;
    background: #fff;
  }

  .arrow {
    background: url('./assets/images/arrow-purple.svg') no-repeat;
    width: 13px;
    height: 13px;
    margin-left: 15px;
  }
`