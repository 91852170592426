import styled from 'styled-components'
import { Row as RowColumn } from '../../components/Layout/styles'

export const Row = styled(RowColumn)`

`
export const Section = styled.section`
    display: flex;
    align-items: center;
    position: relative;
    &.banner {
        height: 100vh;
        /* margin-bottom: 30px; */
        h1 {
            padding-top: 173px;
        }
    }
    .graph {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        width: 73%;
    }
    .points {
        position: absolute;
        right: 4%;
        bottom: 50%;
    }
    .oval-div {
        position: absolute;
        right: 14%;
        display: flex;
        z-index: 1;
        bottom: 50%;
        img {
            margin-right: 20px;
        }
    }
    @media(max-width: 576px) {
        .points {
            top: 20%;
            width: 103px;
        }

        .oval-div {
            top: 27%;
            img {
                width: 22px;
                height: 22px;
                background-size: contain;
            }
        }
    }
`

export const Col = styled.div`
    &.col-8 {
        width: 60%;
    }
    &.col-4 {
        width: 40%;
    }
`