import React, { useState } from 'react'
import parse from 'html-react-parser'

import { Container } from '../../components/Layout/styles'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Row, Section, Col } from './styles'

import CollapseComponent from '../../components/Collapse'

export default function SAPIbp({ lang, handleLang }) {
  const [collapse, setCollapse] = useState({
    col1: false,
    col2: false,
    col3: false,
    col4: false,
    col5: false,
    col6: false
  })

  const handleClick = (type) => {
    let newCollapse = { ...collapse }

    newCollapse.col1 = type === 'col1' && !newCollapse.col1 ? true : false
    newCollapse.col2 = type === 'col2' && !newCollapse.col2 ? true : false
    newCollapse.col3 = type === 'col3' && !newCollapse.col3 ? true : false
    newCollapse.col4 = type === 'col4' && !newCollapse.col4 ? true : false
    newCollapse.col5 = type === 'col5' && !newCollapse.col5 ? true : false
    newCollapse.col6 = type === 'col6' && !newCollapse.col6 ? true : false

    setCollapse(newCollapse)
  }
  return (
    <>
      <Header
        headerFixed={true}
        classes={'pageIntern'}
        lang={lang}
        handleLang={handleLang}
      />
      <Section className='banner'>
        <img
          src='./assets/images/sapIbp/monitor.jpg'
          className='screen'
          data-aos='fade'
          data-aos-duration='1500'
          alt=''
        />
        <Container>
          <Row>
            <Col>
              <h1>{parse(lang.sapIbp.title)}</h1>
              <p>{parse(lang.sapIbp.text)}</p>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className='ibp'>
        <Container>
          <Row>
            <h2>{lang.sapIbp.text2}</h2>
            <h2>{lang.sapIbp.text3}</h2>
            <div className='services'>
              <div className='type'>
                <h2>
                  <span className='supply'></span>
                  {lang.sapIbp.services1Title}
                </h2>
              </div>
              <div className='type'>
                <h2>
                  <span className='ibpSales'></span>
                  {lang.sapIbp.services2Title}
                </h2>
              </div>
              <div className='type'>
                <div>
                  <h2>
                    <span className='ibpDemand'></span>
                    {lang.sapIbp.services3Title}
                  </h2>
                </div>
                <div>
                  <h2>
                    <span className='ibpInventory'></span>
                    {lang.sapIbp.services4Title}
                  </h2>
                </div>
                <div>
                  <h2>
                    <span className='ibpResponse'></span>
                    {lang.sapIbp.services5Title}
                  </h2>
                </div>
              </div>
            </div>
            <h2>{lang.sapIbp.text4}</h2>
            <div className='collapseDivs'>
              <CollapseComponent
                collapsed={collapse.col1}
                collapseName={'col1'}
                title={
                  <h1>
                    <span className='supply'></span>
                    {lang.sapIbp.services1Title}
                  </h1>
                }
                handleClick={handleClick}
                className='padding'
                content={
                  <>
                    <p>{parse(lang.sapIbp.services1Text)}</p>
                    <ul>
                      <li>{parse(lang.sapIbp.services1List.text1)}</li>
                      <li>{parse(lang.sapIbp.services1List.text2)}</li>
                      <li>{parse(lang.sapIbp.services1List.text3)}</li>
                      <li>{parse(lang.sapIbp.services1List.text4)}</li>
                      <li>{parse(lang.sapIbp.services1List.text5)}</li>
                    </ul>
                  </>
                }
              />

              <CollapseComponent
                collapsed={collapse.col2}
                collapseName={'col2'}
                title={
                  <h1>
                    <span className='ibpSales'></span>
                    {lang.sapIbp.services2Title}
                  </h1>
                }
                handleClick={handleClick}
                className='padding'
                content={
                  <>
                    <p>{parse(lang.sapIbp.services2Text)}</p>
                    <p>{parse(lang.sapIbp.services2Text2)}</p>
                    <ul>
                      <li>{parse(lang.sapIbp.services2List.text1)}</li>
                      <li>{parse(lang.sapIbp.services2List.text2)}</li>
                      <li>{parse(lang.sapIbp.services2List.text3)}</li>
                      <li>{parse(lang.sapIbp.services2List.text4)}</li>
                      <li>{parse(lang.sapIbp.services2List.text5)}</li>
                      <li>{parse(lang.sapIbp.services2List.text6)}</li>
                    </ul>
                  </>
                }
              />

              <CollapseComponent
                collapsed={collapse.col3}
                collapseName={'col3'}
                title={
                  <h1>
                    <span className='ibpDemand'></span>
                    {lang.sapIbp.services3Title}
                  </h1>
                }
                handleClick={handleClick}
                className='padding'
                content={
                  <>
                    <p>{parse(lang.sapIbp.services3Text)}</p>
                    <p>{parse(lang.sapIbp.services3Text2)}</p>
                    <ul>
                      <li>{parse(lang.sapIbp.services3List.text1)}</li>
                      <li>{parse(lang.sapIbp.services3List.text2)}</li>
                      <li>{parse(lang.sapIbp.services3List.text3)}</li>
                      <li>{parse(lang.sapIbp.services3List.text4)}</li>
                      <li>{parse(lang.sapIbp.services3List.text5)}</li>
                      <li>{parse(lang.sapIbp.services3List.text6)}</li>
                      <li>{parse(lang.sapIbp.services3List.text7)}</li>
                      <li>{parse(lang.sapIbp.services3List.text8)}</li>
                      <li>{parse(lang.sapIbp.services3List.text9)}</li>
                    </ul>
                  </>
                }
              />

              <CollapseComponent
                collapsed={collapse.col4}
                collapseName={'col4'}
                title={
                  <h1>
                    <span className='ibpInventory'></span>
                    {lang.sapIbp.services4Title}
                  </h1>
                }
                handleClick={handleClick}
                className='padding'
                content={
                  <>
                    <p>{lang.sapIbp.services4Text}</p>

                    <strong>{lang.sapIbp.services4Text2}</strong>
                    <ul>
                      <li>{parse(lang.sapIbp.services4List.text1)}</li>
                      <li>{parse(lang.sapIbp.services4List.text2)}</li>
                      <li>{parse(lang.sapIbp.services4List.text3)}</li>
                      <li>{parse(lang.sapIbp.services4List.text4)}</li>
                      <li>{parse(lang.sapIbp.services4List.text5)}</li>
                      <li>{parse(lang.sapIbp.services4List.text6)}</li>
                      <li>{parse(lang.sapIbp.services4List.text7)}</li>
                    </ul>
                  </>
                }
              />

              <CollapseComponent
                collapsed={collapse.col5}
                collapseName={'col5'}
                title={
                  <h1>
                    <span className='ibpResponse'></span>
                    {lang.sapIbp.services5Title}
                  </h1>
                }
                handleClick={handleClick}
                className='padding'
                content={
                  <>
                    <p>{lang.sapIbp.services5Text}</p>

                    <strong>{lang.sapIbp.services5Text2}</strong>
                    <ul>
                      <li>{lang.sapIbp.services5List.text1}</li>
                      <li>{lang.sapIbp.services5List.text2}</li>
                      <li>{lang.sapIbp.services5List.text3}</li>
                      <li>{lang.sapIbp.services5List.text4}</li>
                      <li>{lang.sapIbp.services5List.text5}</li>
                      <li>{lang.sapIbp.services5List.text6}</li>
                      <li>{lang.sapIbp.services5List.text7}</li>
                    </ul>
                  </>
                }
              />
            </div>
          </Row>
        </Container>
      </Section>
      <Footer lang={lang} />
    </>
  )
}
