import styled from 'styled-components'

import { Row as RowColumn } from '../../components/Layout/styles'

export const Row = styled(RowColumn)`
  &.col {
    display: flex;
    justify-content: space-between;
    &.inicio {
      h2 {
        margin-right: 170px;
      }
    }
    h2 {
      margin-right: 100px;
    }
  }

  &.partners {
    display: grid;
    grid-template-columns: 33% 1fr;
    align-items: center;

    img {
      max-width: 274px;
    }
  }

  @media screen and (max-width: 992px) {
    &.partners {
      grid-template-columns: auto;
      gap: 20px;

      img {
        max-width: 200px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    &.col {
      flex-direction: column;
      h2 {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &.partners {
      img {
        max-width: 150px;
      }
    }
  }
`

export const Section = styled.section`
  display: flex;
  margin: 90px 0 0;
  &.banner {
    height: 80vh;
    h1 {
      padding-top: 173px;
    }

    .graph {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      width: 73%;
    }

    .points {
      position: absolute;
      right: 40%;
      bottom: 41%;
    }

    .hands {
      width: 439px;
      height: 439px;
    }

    .objectApllos {
      position: absolute;
      width: 285px;
      height: 285px;
      bottom: 12px;
      z-index: 1;
      transform: rotate(-2deg);
      right: 50px;
    }
  }

  p {
    font-size: 16px;
    color: var(--textPags);
    line-height: 1.6;
  }

  @media screen and (max-width: 1024px) {
    &.banner {
      p {
        br {
          display: block;
        }
      }

      .objectApllos {
        display: none;
      }

      .hands {
        width: 339px;
        height: 339px;
      }
    }

    p {
      br {
        display: none;
      }
    }
  }

  @media screen and (max-width: 990px) {
    &.banner {
    }
  }

  @media screen and (max-width: 768px) {
    &.banner {
      flex-direction: column;
      height: auto;
      padding: 131px 0px 0;
      align-items: flex-end;

      h1 {
        font-size: 30px;
        padding-top: 0;
        &:before {
          width: 100%;
        }
      }

      .hands {
        display: none;
      }
    }
    h2 {
      font-size: 20px;
    }

    p {
      font-size: 15px;
      br {
        display: none !important;
      }
    }
  }
`

export const Col = styled.div`
  /* display: flex;
    flex-direction: column; */
  p {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &.content-image {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &.with-link {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`
